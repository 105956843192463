import React from 'react';
import { Alert, AlertTitle, cn } from 'homebox-ui';
import { MdCheck, MdClose } from 'react-icons/md';
import { toast } from 'react-hot-toast';

export function AlertWarning({ title, currentToast }) {
  return (
    <>
      <Alert
        className={cn(
          `flex max-w-sm items-center justify-between transition-all duration-100`,
          currentToast?.visible ? 'animate-enter' : 'animate-leave'
        )}
        variant='warning'
      >
        <div className='flex items-center space-x-3'>
          <div className='border-warning-400 flex h-10 w-10 items-center justify-center rounded-full border p-3'>
            <MdCheck className='h-4 w-4' />
          </div>
          <AlertTitle>{title}</AlertTitle>
        </div>
        <button
          className='cursor-pointer rounded-none border border-transparent px-6 py-3'
          onClick={() => toast.dismiss(currentToast?.id)}
        >
          <MdClose className='text-typography-warning h-4 w-4 cursor-pointer' />
        </button>
      </Alert>
    </>
  );
}
