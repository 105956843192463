import { useState, useEffect } from 'react';

export function useMediaQuery({ query }) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    setMatches(mediaQueryList.matches);

    const listener = evt => setMatches(evt.matches);
    mediaQueryList.addListener(listener);

    return () => mediaQueryList.removeListener(listener);
  }, [query]);

  return matches;
}
