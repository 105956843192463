import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from 'homebox-ui';
import { cn } from '../../utils';
import { MdCleaningServices } from 'react-icons/md';
import { calculateBillPrice } from '../../utils/functions';
import BillCardDescription from './BillCardDescription';

export default function BillCard(props) {
  const {
    data,
    isBillSelected,
    toggleBillSelection,
    isPerk,
    icon,
    isMonthly = true,
    noOfHousemates,
    noOfBillPayers,
    energyBillSelected = () => {},
  } = props;
  const { bills, name } = data || {};

  const {
    provider,
    logo_path,
    id,
    description = '',
    short_description = '',
    description_html = '',
    group_name,
    label = '',
  } = bills?.[0] || data;

  const isSelected = isBillSelected(id, isPerk);

  const logo = {
    Cleaner: { icon: true, logo: <MdCleaningServices className='h-7 w-7' /> },
  };

  const isWater = name === 'Water';
  const isEnergyProvider = provider === 'Rebel Energy';
  const isCouncilTax = name === 'Council Tax';
  const isTVLicense = name === 'TV Licence';
  const isEnergy = group_name === 'Renewable Energy';

  const BillCardAction = () => {
    return (
      <>
        <Dialog>
          <DialogTrigger asChild>
            <button
              // variant='link'
              className='text-typography-interactive text-p-sm cursor-pointer py-1 font-semibold underline-offset-2 hover:underline sm:p-0 sm:text-base'
            >
              Learn more
            </button>
          </DialogTrigger>
          <DialogContent className=''>
            <DialogHeader>
              <DialogTitle className='text-center'>{name}</DialogTitle>
            </DialogHeader>
            <DialogDescription className='max-h-[75vh] overflow-y-scroll px-2 py-3'>
              <BillCardDescription
                bill={bills?.[0] || data}
                description={description}
                description_html={description_html}
                types={{
                  isEnergyProvider,
                  isWater,
                  isCouncilTax,
                  isTVLicense,
                  isEnergy,
                }}
              />
            </DialogDescription>

            <DialogFooter>
              <DialogTrigger asChild>
                <Button variant={'secondary'} type='button'>
                  Close
                </Button>
              </DialogTrigger>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        {!isPerk && (
          <div className='flex'>
            <div className='flex space-x-3 transition-all'>
              <Button
                onClick={() => {
                  toggleBillSelection(id, bills[0]);
                  if (isEnergy) {
                    energyBillSelected(!isSelected);
                  }
                }}
                variant={isSelected ? 'secondary' : 'primary'}
              >
                {isSelected ? 'Remove' : 'Add Bill'}
              </Button>
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <div className='w-full'>
        <div
          className={cn(
            'border-border bg-background-secondary relative isolate  overflow-hidden rounded-xl border p-4',
            !isSelected && `bg-background-foundation border-border-light`
          )}
        >
          <div className='flex h-full w-full space-x-4'>
            <div className='h-16 w-16'>
              {icon || logo?.[name]?.icon ? (
                <div className='border-border-light text-typography-secondary rounded-3xl border p-3 shadow-lg'>
                  {icon || logo?.[name]?.logo}
                </div>
              ) : (
                <img
                  src={logo_path}
                  alt={`${provider} logo`}
                  className='h-full max-h-[64px] w-full cursor-pointer rounded-2xl object-contain shadow-lg'
                />
              )}
            </div>

            <div
              className={cn(
                'flex w-full flex-col justify-between space-y-4',
                isPerk && 'space-y-1'
              )}
            >
              <div className='flex w-full justify-between'>
                <div className='flex flex-col'>
                  <h4 className='text-p-strong text-typography'>
                    {label ? label : name}
                  </h4>
                  <p className='text-typography-secondary text-p-tiny sm:text-sm'>
                    {short_description
                      ? short_description
                      : `Provided by ${provider}`}
                  </p>
                </div>

                <div className='flex flex-col'>
                  <p className='text-p-sm text-typography font-medium sm:text-sm'>
                    £
                    {calculateBillPrice(
                      bills?.[0] || data,
                      noOfHousemates,
                      noOfBillPayers,
                      isMonthly
                    )}
                    <span className='text-typography-secondary text-sm font-normal'>
                      {isMonthly ? '/month' : '/week'}
                    </span>
                  </p>
                  {!isPerk && Number(noOfBillPayers) > 1 && (
                    <p className='text-p-tiny text-typography-secondary font-normal sm:text-sm'>
                      per bill payer
                    </p>
                  )}
                </div>
              </div>
              <div
                className={cn(
                  'hidden items-end justify-between sm:flex',
                  isPerk && 'hidden sm:flex'
                )}
              >
                <BillCardAction />
              </div>
            </div>
          </div>
          <div className={cn('mt-4 flex items-end justify-between sm:hidden')}>
            <BillCardAction />
          </div>
        </div>
      </div>
    </>
  );
}
