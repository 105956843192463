import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { cn } from 'src/utils/helpers';
import { MdCheck, MdHorizontalRule } from 'react-icons/md';
import { VariantProps, cva } from 'class-variance-authority';

const checkboxVariants = cva(
  'peer h-5 w-5 shrink-0 border border-border-dark hover:border-border-interactive hover:bg-primary-lighter ring-offset-white  focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-primary-lighter focus-visible:ring-offset-0 data-[state=checked]:bg-primary data-[state=checked]:text-white data-[state=checked]:border-primary disabled:bg-button-disabled disabled:border-border disabled:cursor-not-allowed data-[state=checked]:disabled:bg-border data-[state=checked]:disabled:text-typography-inactive data-[state=checked]:disabled:border-border data-[state=checked]:focus-visible:hover:ring-offset-1',
  {
    variants: {
      variant: {
        check: 'rounded-md',
        circle:
          'rounded-full border border-border data-[state=checked]:bg-primary data-[state=checked]:text-white data-[state=checked]:border-border',
      },
    },

    defaultVariants: {
      variant: 'check',
    },
  }
);

export interface CheckboxProps extends VariantProps<typeof checkboxVariants> {
  disabled?: boolean;
  className?: string;
  variant?: 'check' | 'circle';
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & CheckboxProps
>(({ checked, variant = 'check', className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(checkboxVariants({ variant }))}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn('flex items-center justify-center text-current')}
    >
      {checked === 'indeterminate' ? (
        <MdHorizontalRule className='h-4 w-4' />
      ) : (
        <MdCheck className='h-4 w-4' />
      )}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
