import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'homebox-ui/main.css';
import 'react-phone-number-input/style.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import qs from 'qs';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import { GlobalConfigProvider } from './context';
import config from './app/config';
import { initAmplitude, logger } from './utils';
import { emitter } from '@marvelapp/react-ab-test';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://676fd9d7a414a5eeac86c5f3526d4b67@o307151.ingest.sentry.io/4506265275858949',
  enabled: process.env.NODE_ENV !== 'development',
  environment: process.env.NODE_ENV,
});

/**
 * Configuration Options
 *
 * @param {Object} args
 * @param {String} args.HTMLTarget - the ID of the HTML entity to attach the react app to
 * @param {String} args.routingType - should the URL paths be updated?
 * @param {String} args.journey - tenantPackage, leadFlow
 * @param {Object} args.journeyConfig - serviceType: energy, broadband, comparisonStyle: full, quick
 * @param {String} args.brand - unbranded (no logo), {partnerId}, fromURL
 * @param {String} args.partnerId - {partnerId}
 * @param {String} args.reference - {reference}
 * @param {Boolean} args.i8n - true, false [default=false]
 * @param {Boolean} args.enableABTests - true, false [default=false]
 * @param {String} args.amplitudeId - {amplitudeId}
 * @param {Object} args.userParams - {first_name, last_name, email, address, phone_number, total_occupants, bill_payers, household_type - student, professional-tenant }
 *
 */

let HBConfig = {
  HTMLTarget: 'root',
  routingType: 'path',
  journey: 'tenantPackage',
  journeyConfig: {
    serviceType: '',
    comparisonStyle: '',
  },
  brand: 'unbranded',
  partnerId: '',
  enableABTests: false,
  amplitudeID: '',
  i8n: false,
  reference: null,
  userParams: {
    first_name: '',
    last_name: '',
    email: '',
    address: {
      postcode: '',
    },
    phone_number: '',
    total_occupants: '',
    bill_payers: '',
    household_type: 'professional-tenant',
  },
};

export const render = args => {
  let selector = 'hb-widget';

  if (args.HTMLTarget) {
    selector = `#${args.HTMLTarget}`;
  }

  const widgetMode = config.BUILD_MODE === 'widget';

  const { routingType } = args;

  const routers = {
    embedded: MemoryRouter,
    path: BrowserRouter,
  };

  const initialEntries = ['/']; // defaults as index path
  const search = window?.location?.search;
  // e.g ?userParams[first_name]=john&userParams[last_name]=smith&userParams[address][postcode]=E97TB&userParams[total_occupants]=4&userParams[bill_payers]=2&userParams[email]=johnsmithhomebox@gmail.com&userParams[household_type]=professional-tenant&journey=tenantPackage

  let urlConfig = qs.parse(search?.split('?')?.[1]);

  delete urlConfig?.HTMLTarget;
  delete urlConfig?.routingType;

  if (args?.userParams || urlConfig) {
    const bill_payers =
      Number(urlConfig?.userParams?.bill_payers) ||
      Number(args.userParams?.bill_payers);

    const total_occupants =
      Number(urlConfig?.userParams?.total_occupants) ||
      Number(args.userParams?.total_occupants);

    HBConfig = {
      ...HBConfig,
      ...args,
      ...urlConfig,
      userParams: {
        ...args.userParams,
        ...urlConfig.userParams,
        total_occupants,
        bill_payers,
        tenants:
          bill_payers > 0
            ? Array(bill_payers || total_occupants).fill({
                first_name: '',
                last_name: '',
                phone_number: '',
              })
            : [],
      },
      widgetMode,
    };
  }

  const partnerId = HBConfig?.partnerId;
  const journey = HBConfig?.journey;
  const amplitudeId = HBConfig?.amplitudeId;

  const paths = {
    tenantPackage: widgetMode ? `/${partnerId}/` : '',
    leadFlow: widgetMode ? `/${partnerId}/welcome` : 'welcome',
  };

  if (journey) {
    initialEntries.push(paths[journey]);
  }

  if (!partnerId && widgetMode) {
    throw new Error('partnerId is required');
  }

  // app analytics
  initAmplitude({ amplitudeId, partnerId });

  // Register experiments
  emitter.defineVariants('EXP_EnergyBill', ['A', 'B']);

  if (HBConfig && HBConfig.enableABTests !== true) {
    emitter.setActiveVariant('EXP_EnergyBill', 'B');
  }

  emitter.addPlayListener(function (experimentName, variantName) {
    logger.identify(null, { [experimentName]: variantName });
    console.log(
      `Displaying experiment ${experimentName} variant ${variantName}`
    );
  });

  emitter.addWinListener(function (experimentName, variantName) {
    console.log(
      `Variant ${variantName} of experiment ${experimentName} was clicked`
    );
  });

  if (HBConfig && HBConfig.i8n !== true) {
    if (typeof window !== 'undefined') {
      window.Weglot = { initialize: () => {} };
    }
  }

  // use router based on config
  let routerType = widgetMode ? 'embedded' : routingType;
  const AsRouter = routers[routerType];

  const routerProps = { initialEntries };

  // use last provided path in entries;
  const basePath = initialEntries?.[initialEntries?.length - 1];

  const container = document.querySelector(selector);
  const root = ReactDOM.createRoot(container);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <GlobalConfigProvider config={{ ...HBConfig }}>
          <AsRouter {...routerProps}>
            <App basePath={basePath} />
          </AsRouter>
        </GlobalConfigProvider>
      </Provider>
    </React.StrictMode>
  );
};

if (config.BUILD_MODE !== 'widget') {
  render({ ...HBConfig, i8n: config.i8n });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
