import React from 'react';
import Rating from '../../assets/ratings.svg';

export default function Testimonial() {
  return (
    <div className='get-started-bg flex h-screen w-full items-end justify-start bg-cover bg-center bg-no-repeat p-16'>
      <div className='flex flex-col space-y-4'>
        <div className=' flex justify-center '>
          <svg
            width='24'
            height='18'
            viewBox='0 0 24 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.320007 18L5.12001 0.208008H11.84L9.28001 18H0.320007ZM12.16 18L16.96 0.208008H23.68L21.12 18H12.16Z'
              fill='white'
            />
          </svg>
        </div>

        <span className='text-2xl font-light text-white'>
          “Exactly what we were looking for.
          <br /> Easy, reliable, quick responses.
          <br /> Have recommended to lots of friends. ”
        </span>

        <div>
          <div className='mt-6 flex justify-between pb-4'>
            <span className='text-lg font-semibold text-white'>
              - Sophie, London
            </span>

            <img src={Rating} alt='ratings' />
          </div>
        </div>
      </div>
    </div>
  );
}
