import React from 'react';

export default function UtilityBreakdownSkeleton() {
  return (
    <div className='w-full rounded-lg border-[#dbdcdd] bg-[#f4f5f6] shadow'>
      <div className='flex animate-pulse flex-col p-2 text-gray-100'>
        <BillRowSkeleton />
        <BillRowSkeleton />
        <div className='h-[70px] w-full rounded bg-gray-100 text-gray-100 sm:w-[375px]' />
      </div>
    </div>
  );
}

const BillRowSkeleton = () => {
  return (
    <div className='mb-5 flex flex-row'>
      <div className='flex w-full flex-row'>
        <div className='mr-2 h-[50px] w-[50px] items-center rounded-lg bg-gray-100 p-2 shadow-lg'></div>
        <div className='sm flex-1 rounded bg-gray-100 sm:flex sm:flex-col sm:bg-transparent'>
          <div className='mb-1 h-6 rounded bg-gray-100 sm:w-[60px]' />
          <div className='h-6 rounded bg-gray-100 sm:w-[80px]' />
        </div>
      </div>
      <div className='hidden sm:flex sm:flex-col'>
        <div className='mb-1 h-6 rounded bg-gray-100 sm:w-[80px]' />
        <div className='h-6 rounded bg-gray-100 sm:w-[80px]' />
      </div>
    </div>
  );
};
