import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';
var baseUrl = config.BASE_URL;
export var billingBetterService = createApi({
  reducerPath: 'bbAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: function prepareHeaders(headers, _ref) {
      var getState = _ref.getState;
      headers.set('Access-Control-Allow-Origin', '*');
      headers.set('Access-Control-Allow-Methods', 'GET, POST, PUT,PATCH, DELETE, OPTIONS');
      var partnerToken = getState().partnerAuth.partnerToken;
      if (partnerToken) {
        headers.set('PublicToken', partnerToken);
      }
      return headers;
    }
  }),
  endpoints: function endpoints(builder) {
    return {
      findAddressByPostcode: builder.query({
        query: function query(arg) {
          var postCode = arg.postCode;
          return {
            url: "open/address/find/" + postCode
          };
        },
        transformResponse: function transformResponse(response) {
          return response;
        }
      }),
      findAddressByTextSearch: builder.query({
        query: function query(_ref2) {
          var address = _ref2.address;
          return {
            url: "open/address/query/" + address
          };
        },
        transformResponse: function transformResponse(response) {
          return response;
        }
      })

      // getPropertyAddress: builder.mutation({
      //   query: arg => {
      //     const { address } = arg;
      //     return {
      //       url: `partner/property`,
      //       method: 'POST',
      //       body: address,
      //     };
      //   },
      //   transformResponse: response => response?.data,
      // }),
    };
  }
});

var useFindAddressByPostcodeQuery = billingBetterService.useFindAddressByPostcodeQuery,
  useLazyFindAddressByTextSearchQuery = billingBetterService.useLazyFindAddressByTextSearchQuery;
export { useFindAddressByPostcodeQuery, useLazyFindAddressByTextSearchQuery };