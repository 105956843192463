import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'homebox-ui';
import { faqs } from '../../utils';

export const YourHomeMoveFAQs = () => {
  return (
    <div className='mx-auto w-full max-w-3xl'>
      <Accordion type='single' collapsible className='w-full'>
        {faqs.map(({ question, answer }, idx) => (
          <AccordionItem
            className=' border-border'
            key={idx}
            value={`item-${idx}`}
          >
            <AccordionTrigger className=' text-start hover:no-underline'>
              {question}
            </AccordionTrigger>
            <AccordionContent>{answer}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};
