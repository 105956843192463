import * as React from 'react';
import * as SeparatorPrimitive from '@radix-ui/react-separator';
import { cn } from 'src/utils/helpers';
import { cva, VariantProps } from 'class-variance-authority';

const dividerVariants = cva('bg-neutral-300 shrink-0', {
  variants: {
    orientation: {
      horizontal: 'h-[1px] w-full',
      vertical: 'h-full w-[1px]',
    },
    variant: {
      default: 'bg-neutral-300',
      light: 'bg-neutral',
      dark: 'bg-neutral-400',
      interactive: 'bg-brick-orange-100',
    },
  },
  defaultVariants: {
    variant: 'default',
    orientation: 'horizontal',
  },
});

export interface DividerProps extends VariantProps<typeof dividerVariants> {
  variant?: 'default' | 'light' | 'dark' | 'interactive';
}

const Divider = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> & DividerProps
>(
  (
    {
      className,
      variant,
      orientation = 'horizontal',
      decorative = true,
      ...props
    },
    ref
  ) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn(dividerVariants({ variant, orientation, className }))}
      {...props}
    />
  )
);
Divider.displayName = SeparatorPrimitive.Root.displayName;

export { Divider };
