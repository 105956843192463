export const howItWorks = [
  {
    headerTitle: 'Build your quote',
    headerDescription:
      "Choose the services you'd like to include in your package using our market leading quote generator.",
  },
  {
    headerTitle: 'Sign up',
    headerDescription:
      'It’s really that easy. If you’re happy with your quote, sign up. We’ll have you set up and ready to go!',
  },
  {
    headerTitle: 'Sit back and relax',
    headerDescription:
      'You can relax knowing your bills are boxed off. Your single set payment is the only thing to manage.',
  },
];

export const internetOptions = [
  {
    title: 'ADSL Internet',
    description: 'Standard Speed',
    speed: '10-20 MBPS',
    values: ['vodafone_adsl', 'talktalk_adsl'],
    isAvailable: false,
    providers: [],
  },
  {
    title: 'FTTC Internet',
    description: 'Superfast Speed',
    speed: '40-80 MBPS',
    values: ['openreach_fttc', 'sky_fttc', 'vodafone_fttc'],
    isAvailable: false,
    providers: [],
  },
  {
    title: 'FTTP Internet',
    description: 'Ultrafast Speed',
    speed: '100-1000 MBPS',
    values: ['vodafone_fttp', 'openreach_fttp'],
    isAvailable: false,
    providers: [],
  },
  {
    title: 'Virgin Internet',
    description: 'Ultrafast Speed',
    speed: '10-1000 MBPS',
    values: ['virgin_cable'],
    isAvailable: false,
    providers: [],
  },
];

export const providerMap = {
  vodafone: 'Vodafone',
  talktalk: 'TalkTalk',
  sky: 'Sky',
  virgin: 'Virgin',
  openreach: 'Openreach',
};

export const usefulInformation = [
  '✅ The total cost of all your bills per month',
  '✅ Who your energy, water & council tax providers are',
  '✅ Broadband speed availability',
];

export const billsIncludedInPackage = [
  {
    emoji: '🔥',
    title: 'Energy',
    description: 'Renewable gas and electricity.',
  },
  {
    emoji: '💧',
    title: 'Water',
    description: 'Your clean and waste water.',
  },
  {
    emoji: '👩‍💻',
    title: 'Internet',
    description:
      'Choose from a range of internet speeds (we offer Virgin internet).',
  },
  {
    emoji: '🏡',
    title: 'Council Tax',
    description:
      'Taxes for local services like rubbish collection and social care. Not applicable for students.',
  },
  {
    emoji: '📺',
    title: 'TV Packages',
    description: 'Choose from sports, movies and box sets.',
  },
  {
    emoji: '🌳',
    title: 'Carbon offsetting',
    description:
      "Included for free - we'll plant enough trees to cover your home's carbon emissions.",
  },
];

export const faqs = [
  {
    question: 'Which bills can I include within my Homebox account?',
    answer:
      "Choose from gas, electricity, water, council tax, internet, TV and TV Licence. When building your quote, you can pick and choose the services you'd like to include within your package.",
  },
  {
    question: 'Is this service for students or professional renters?',
    answer:
      'Both! We have a large number of student sharers using our service, as well as professional sharers, couples and individuals looking to have an easier way to pay their bills.',
  },
  {
    question: "What's the best way to contact Homebox?",
    answer:
      'Head over to our Contact Us page to start chatting with our team using our Live Chat which is operated between 9-5, Monday to Friday.',
  },
  {
    question: 'How long will it take to have my internet installed?',
    answer:
      "Typically internet will take 10 working days to install subject to engineer availability. Once your account is set up with us, you'll receive regular updates from us.",
  },
  {
    question: 'How is my quote calculated?',
    answer:
      "When you create your quote, you'll enter the number of people living in the home along with your address. From this data, we estimate the amount of energy and water you'll use, whilst showing you your council tax band (if you're not a student), as well as the broadband speeds available for your home.",
  },
];

export const reviews = [
  {
    title: 'Quick, easy, professional, lovely service',
    date: '4 Oct 2023',
    rating: 5,
    link: 'https://uk.trustpilot.com/reviews/651d423f230610df3358828b',
    reviewer: 'Lianne Estrada',
    review:
      'I had a really good experience with home box considering I needed a quick change of bill providers. Georgia was a really good help, very kind, patient and professional! She also was very informative about student bills - so If you’re a student who has your own place i Would recommend Home Box :)',
  },
  {
    title: 'So easy to switch it was like magic',
    date: '28 Sept 2023',
    rating: 5,
    link: 'https://uk.trustpilot.com/reviews/6515aa711053dd17cfa850dd',
    reviewer: 'Olivia Stone',
    review:
      'So easy to switch it was like magic. Best Price I had found per month for energy.',
  },
  {
    title: 'Thank you Georgia and Homebox',
    date: '28 Sept 2023',
    rating: 5,
    link: 'https://uk.trustpilot.com/reviews/65159d8067da275d3223bef5',
    reviewer: 'Christi',
    review:
      'I am happy that I was contacted by the Homebox team, after many attempts on the phone to find the right time to talk, I succeeded and I am very satisfied. Due to the language barrier, Georgia was patient and helped me understand what Homebox does and how I can be helped to settle in the new house with all the bills set and at a very good price. I recommend with confidence.',
  },
  {
    title: "It's a good experience",
    date: '09 October 2023',
    rating: 5,
    link: 'https://uk.trustpilot.com/reviews/65241d54be8acc7ed7051cee',
    reviewer: 'Zee',
    review:
      "It's a good experience. It doesn't push too hard. I had a great time talking to Rosie Kirk.",
  },
];

export const tenancyLengths = [
  {
    value: 'rolling',
    label: 'It’s rolling monthly',
  },
  {
    value: '9',
    label: '9 months',
  },
  {
    value: '12',
    label: '1 year',
  },
  {
    value: '24',
    label: '2 years',
  },
  {
    value: 'other',
    label: 'Other',
  },
];
