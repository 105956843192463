import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import FormErrorMessage from '../../components/FormErrorMessage';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Button, Label, Input } from 'homebox-ui';
import { MdAdd } from 'react-icons/md';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export default function AddBillPayer({ payer, payerNo }) {
  const { setFieldValue, handleChange, values } = useFormikContext();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState('');
  const billPayers = values?.tenants;
  const isMobile = useMediaQuery({ query: '(max-width: 1280px)' });

  const inviteMateSchema = Yup.object().shape({
    first_name: Yup.string().required('first name is required.'),
    last_name: Yup.string().required('last name is required.'),
    phone_number: Yup.string()
      .required('phone number is required')
      .test(
        'is-valid-phone-number',
        'Please use a valid phone number',
        (value, context) => isValidPhoneNumber(value)
      ),
  });

  const submitBillPayer = async () => {
    try {
      setIsSubmitting(true);
      let isPhoneUnique = 0;
      // check number occurence of payer's phone number
      billPayers
        .filter(p => p?.phone_number)
        .forEach(p => {
          if (p?.['phone_number'] === payer?.phone_number) {
            isPhoneUnique++;
          }
        });
      await inviteMateSchema.validate(
        {
          ...payer,
        },
        { abortEarly: false }
      );

      // check if phone number exists more than once
      if (isPhoneUnique === 1) {
        setFieldValue(`tenants.${payerNo}.valid`, true);
      } else {
        setFormErrors(errors => {
          return {
            ...errors,
            unique_phone: 'Please use a unique phone number',
          };
        });
      }
      setIsSubmitting(false);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach(err => {
        if (err.path) {
          validationErrors[err.path] = err.message;
        }
      });
      setFormErrors(validationErrors);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className='flex flex-col space-y-6 pt-8'>
        <div className='flex w-full max-w-full flex-col space-y-2 lg:grid lg:grid-cols-2 lg:gap-2 lg:space-y-0 xl:flex xl:flex-row xl:space-x-2 xl:space-y-0'>
          <div className='w-full xl:max-w-[12rem]'>
            <Label htmlFor='first-name'>First Name</Label>
            <Input
              name={`tenants.${payerNo}.first_name`}
              value={payer?.first_name}
              onChange={handleChange}
              id='first-name'
              placeholder='Enter first name here'
              type='text'
            />
            <FormErrorMessage errors={formErrors} name={`first_name`} />
          </div>
          <div className='w-full xl:max-w-[12rem]'>
            <Label htmlFor='last-name'>Last Name</Label>
            <Input
              value={payer?.last_name}
              name={`tenants.${payerNo}.last_name`}
              onChange={handleChange}
              id='last-name'
              placeholder='Enter last name here'
              type='text'
            />
            <FormErrorMessage errors={formErrors} name={`last_name`} />
          </div>

          <div className='w-full'>
            <div className='flex items-center space-x-4'>
              <div className='w-full'>
                <Label htmlFor='phone-number'>Phone</Label>

                <PhoneInput
                  defaultCountry='GB'
                  placeholder='e.g. 7700 900123'
                  value={payer?.phone_number}
                  onChange={val => {
                    setFieldValue(`tenants.${payerNo}.phone_number`, val);
                    // reset form error
                    if (formErrors?.unique_phone || formErrors?.phone_number) {
                      setFormErrors(errors => {
                        return {
                          ...errors,
                          phone_number: '',
                          unique_phone: '',
                        };
                      });
                    }
                  }}
                  id='phone-number'
                />
              </div>
              {!isMobile && (
                <Button
                  variant='primary'
                  className='mt-6 hidden xl:flex'
                  id={`add-tenants-${payerNo}`}
                  isLoading={isSubmitting}
                  onClick={submitBillPayer}
                >
                  Add
                </Button>
              )}
            </div>
            <FormErrorMessage errors={formErrors} name={`phone_number`} />
            <FormErrorMessage errors={formErrors} name={`unique_phone`} />
          </div>
        </div>
        {isMobile && (
          <Button
            className='flex xl:hidden'
            variant='primary'
            id={`add-tenants-${payerNo}`}
            startIcon={<MdAdd />}
            isLoading={isSubmitting}
            onClick={submitBillPayer}
          >
            Add bill payer
          </Button>
        )}
      </div>
    </>
  );
}
