import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Testimonial from '../components/Testimonial';
import QuotePanel from '../components/QuotePanel';
import Stepper from '../components/Stepper';
import { cn, activeStep } from '../utils';
import AccountSummaryPanel from '../components/AccountSummaryPanel';
import { useGlobalConfig } from '../context';
import { useFormikContext } from 'formik';

export default function OnboardingLayout({ children, layoutData }) {
  const location = useLocation();

  const {
    rate,
    setRate,
    serviceBill,
    coupon,
    setCoupon,
    couponApplied,
    setCouponApplied,
    setHousemates,
    setAddressId,
    setPostCode,
    setFetchQuote,
    setIsStudent,
    fetchingQuote,
    setCurrentStep,
  } = layoutData;

  const activePage = activeStep(location);

  useEffect(() => {
    setCurrentStep(activePage?.step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage?.step]);

  const { config } = useGlobalConfig();
  const userParams = config?.userParams;
  const { values, setFieldValue, setValues } = useFormikContext();

  useEffect(() => {
    if (userParams?.housemates) {
      const housemates = userParams.housemates;

      setHousemates(housemates);
      setValues({
        ...values,
        total_occupants: housemates,
        bill_payers: housemates,
      });
    }

    if (userParams?.postcode) {
      const postcode = userParams?.postcode;

      setPostCode(postcode);
      setFieldValue('address.postcode', postcode);
    }

    if (userParams?.address_id) {
      const addressId = userParams?.address_id;

      setAddressId(userParams?.address_id);
      setFieldValue('address.external_id', addressId);
    }

    if (userParams?.household_type) {
      let isStudent = userParams?.household_type === 'student';

      setIsStudent(isStudent);
      setFieldValue('household_type', userParams?.household_type);
    }

    if (userParams && Object.keys(userParams).length > 0) {
      setFetchQuote(`${Math.random() * (99999 - 1) + 1}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userParams]);

  return (
    <>
      <Header />
      {/* {widgetMode && !loadPartner && <GetStartedWidgetPopup setLoadPartner={setLoadPartner} />} */}
      {/* fullPage and noLayout set to true for pages that doesn't benefit from the onboarding layout styles */}
      <div
        className={cn(
          !activePage?.noLayout &&
            `min-h-[calc(100vh - 72px)] @sm:p-6 @lg:p-8 @2xl:min-h-screen bg-neutral-50 p-0`
        )}
      >
        <div
          className={cn(
            !activePage?.noLayout &&
              `@lg:flex-row @lg:justify-between @2xl:mx-auto @2xl:max-w-[92rem] flex h-full w-full flex-col`
          )}
        >
          <div
            className={cn(!activePage?.fullPage && '@lg:w-7/12 mx-auto w-full')}
          >
            <div
              className={cn(
                !activePage?.fullPage && 'flex w-full flex-col bg-white'
              )}
            >
              {!activePage?.fullPage && <Stepper />}
              <div className={cn(!activePage?.fullPage && `p-6 sm:p-8`)}>
                {children}
              </div>
            </div>
          </div>
          <div
            className={cn('relative w-5/12', activePage?.fullPage && `hidden`)}
          >
            <div className='@lg:flex sticky bottom-2 top-2 hidden w-full'>
              {activePage?.step === 1 ? (
                <div className='flex w-full flex-col space-y-8'>
                  <Testimonial />
                </div>
              ) : (
                <div className='w-full px-8'>
                  <>
                    {activePage?.path === '/setup-mandate' ? (
                      <AccountSummaryPanel />
                    ) : (
                      <QuotePanel
                        step={activePage?.step}
                        rate={rate}
                        setRate={setRate}
                        serviceBill={serviceBill}
                        coupon={coupon}
                        setCoupon={setCoupon}
                        couponApplied={couponApplied}
                        setCouponApplied={setCouponApplied}
                        fetchingQuote={fetchingQuote}
                        setFetchQuote={setFetchQuote}
                      />
                    )}
                  </>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
