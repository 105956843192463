import * as React from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { DayPicker } from 'react-day-picker';
import { cn } from 'src/utils/helpers';
import { buttonVariants } from '../Button';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: cn(
          'text-sm font-semibold',
          props?.captionLayout === 'dropdown' && 'hidden'
        ),
        nav: 'space-x-1 flex items-center',
        nav_button: cn('h-7 w-7 p-0 fill-typography-secondary'),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell:
          'text-typography-primary rounded-md w-9 font-semibold text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          'h-9 w-9 p-0 sm:p-0 font-normal aria-selected:opacity-100 text-typography-primary'
        ),
        day_selected:
          'bg-primary text-white hover:bg-primary hover:bg-button-primary-hover rounded-[20px]',
        day_today: 'bg-button-secondary text-typography-primary rounded-[20px]',
        day_outside: 'text-typography-secondary opacity-50',
        day_disabled: 'text-typography-interactive opacity-20',
        day_range_middle:
          'aria-selected:bg-button-secondary aria-selected:text-typography-primary',
        day_hidden: 'invisible',
        caption_dropdowns: 'flex w-full items-center justify-center space-x-2',
        ...classNames,
      }}
      components={{
        Dropdown: ({ ...props }) => (
          <div className='w-full'>
            <select
              autoFocus
              {...props}
              className='focus-outline:none focus-visible:ring-primary-lighter hover:bbg-neutral-400 h-10 w-full space-x-4 rounded-lg border-none bg-neutral-100  px-1 py-2 text-sm text-neutral-900 outline-none transition-all duration-300 placeholder:text-neutral-400 focus-visible:bg-white focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:text-neutral-600'
            />
          </div>
        ),
        IconLeft: ({ ...props }) => <MdChevronLeft className='h-5 w-5' />,
        IconRight: ({ ...props }) => <MdChevronRight className='h-5 w-5' />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
