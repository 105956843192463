import React from 'react';
import Logo from '../Logo';

export default function Header() {
  return (
    <div className='@sm:flex hidden h-[72px] w-full cursor-pointer items-center border-b border-b-neutral-300 bg-white px-8 py-4'>
      <Logo />
    </div>
  );
}
