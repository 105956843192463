import React from 'react';
import { cn, steps, activeStep } from '../../utils';
import { MdArrowBack } from 'react-icons/md';
import { Button } from 'homebox-ui';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Stepper() {
  const navigate = useNavigate();
  const location = useLocation();

  const activePage = activeStep(location);

  const goBack = () => {
    navigate(-1, { state: { ...location?.state } });
  };

  return (
    <div className='bg-background-dark @sm:bg-white w-full pt-8'>
      <div className='@sm:px-8 flex h-10 items-end justify-between px-5 pb-3'>
        <div className='flex items-center space-x-3'>
          {activePage?.step > 1 && (
            <Button
              variant='secondary'
              className='rounded-lg'
              size={'icon'}
              onClick={goBack}
            >
              <MdArrowBack className='h-5 w-5 cursor-pointer' />
            </Button>
          )}

          <h5 className='text-h5 text-typography-inverted @sm:text-typography'>
            {activePage?.title}
          </h5>
        </div>

        <p className='text-typography-inverted sm:text-typography-secondary text-sm'>
          {activePage?.step} of {steps.length - 1}
        </p>
      </div>
      <div className='@sm:pb-0  mt-2 flex items-end justify-between px-5 pb-6'>
        {/* Stepper visual for mobile */}
        <div
          className={
            'bg-neutral @sm:hidden relative flex h-[2px] w-full overflow-hidden rounded-full'
          }
        >
          <div
            className={cn(
              `bg-main-linear-4 h-full w-full flex-1 transition-all`,
              activePage?.step === steps.length - 1 && 'bg-success-500'
            )}
            style={{
              transform: `translateX(-${
                100 -
                (((activePage?.step / (steps.length - 1)) * 100).toFixed(3) ||
                  0)
              }%)`,
            }}
          />
        </div>
      </div>
      {/* Stepper visual for desktop */}
      <div className='px-8 '>
        <div
          className={
            'bg-neutral @sm:flex relative hidden h-[2px] w-full overflow-hidden rounded-full'
          }
        >
          <div
            className={cn(
              `bg-main-linear relative h-full  transition-all`,
              activePage?.step === steps.length - 1 && 'bg-success-500'
            )}
            style={{
              width: `${
                ((activePage?.step / (steps.length - 1)) * 100).toFixed(0) || 0
              }%`,
            }}
          />
        </div>
      </div>
    </div>
  );
}
