import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from 'homebox-ui';
import { MdArrowForward } from 'react-icons/md';
import DirectDebit from '../../assets/direct-debit.png';

export default function DirectDebitConfirmation({
  isLoading,
  submitForm,
  open,
  setOpen,
  paymentDetails,
}) {
  const { email, account_name, account_number, sort_code } = paymentDetails;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className='top-[20px] max-h-screen w-[95vw] overflow-y-auto rounded-lg sm:top-auto'>
        <DialogTitle className='mt-5 text-center text-2xl'>
          Confirm Your Payment Details
        </DialogTitle>
        <DialogDescription className='flex flex-col space-y-2 overflow-y-auto p-2 lg:max-h-[75vh] lg:p-5'>
          <div className='w-full'>
            <p className='text-typography-secondary text-sm font-normal'>
              You won’t be charged until your tenancy start date and all bill
              payers have set up an account with Homebox. Over the next two
              weeks our team will start setting up all your bills and will be in
              touch to book a welcome call.
            </p>

            <div className='bg-background-secondary mt-5 rounded-lg p-4'>
              <div className='flex flex-wrap break-words py-2'>
                <span className='w-1/2 font-bold'> Email</span>
                {email}
              </div>
              <div className='flex flex-wrap break-words py-2'>
                <span className='w-1/2 font-bold'> Name on account</span>
                {account_name}
              </div>
              <div className='flex flex-wrap break-words py-2'>
                <span className='w-1/2 font-bold'> Sort code</span>
                {sort_code}
              </div>
              <div className='flex flex-wrap break-words py-2'>
                <span className='w-1/2 font-bold'> Account number </span>
                {account_number}
              </div>
            </div>

            <p className='text-typography-secondary mt-5'>
              An email will be sent to {email} within three business days to
              confirm the setup of this direct debit instruction.
            </p>

            <p className='text-typography-secondary mt-5'>
              Additionally, you will receive two days advance notice via email
              of any amount to be debited under this instruction. Payments will
              show as "L&Z Re Homebox UK" on your bank statement.
            </p>

            <Button
              onClick={submitForm}
              type='button'
              id='setup-mandate'
              className='mt-5 w-full font-semibold'
              size={'cta'}
              loadingText='Submitting..'
              isLoading={isLoading}
              endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
            >
              Confirm & Continue
            </Button>

            <div className='mt-5 flex items-center'>
              <p className='text-typography-secondary flex-1'>
                Your payments are protected by the
                <a
                  className='underline'
                  href='https://www.homebox.co.uk/legal/homebox-direct-debit-guarentee'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  {' '}
                  Direct Debit Guarentee
                </a>
              </p>
              <div className='flex flex-1 justify-end'>
                <img
                  src={DirectDebit}
                  className='w-[60px]'
                  alt='direct debit'
                />
              </div>
            </div>
          </div>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}
