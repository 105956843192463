import React from 'react';
import { useFormikContext } from 'formik';

export default function AccountSummaryPanel() {
  return (
    <div className='border-background-secondary w-full border-t-4 bg-white pb-8'>
      <div className='px-4 py-3'>
        <p className='text-typography text-lg  font-semibold'>Your details</p>
      </div>
      <AccountSummaryContent />
    </div>
  );
}

export function AccountSummaryContent() {
  const { values } = useFormikContext();
  const {
    account_name,
    account_number,
    billing_address,
    email,
    sort_code,
    first_name,
    last_name,
  } = values;

  const customerDetails = [
    {
      col_name: 'Name',
      col_value: `${last_name} ${first_name}`,
    },
    {
      col_name: ' Email Address',
      col_value: email,
    },
  ];

  const addressDetails = [
    {
      col_name: 'Address Line 1',
      col_value: billing_address?.line1,
    },
    {
      col_name: 'Address Line 2',
      col_value: billing_address?.line2 ? billing_address?.line2 : 'N/A',
    },
    {
      col_name: 'City',
      col_value: billing_address?.city,
    },
    {
      col_name: 'Postcode',
      col_value: billing_address?.postcode,
    },
  ];

  const accountDetails = [
    {
      col_name: 'Account Name',
      col_value: account_name,
    },
    {
      col_name: 'Account Number',
      col_value: account_number,
    },
    {
      col_name: 'Sort Code',
      col_value: sort_code,
    },
  ];
  return (
    <div className='flex  flex-col items-center'>
      <div className='flex flex-col space-y-4 px-4'>
        <div className='border-border-light flex flex-col space-y-1 border-b py-2'>
          {/* TODO: Update customer reference  */}
          {/* <div className='grid grid-cols-2 py-2 text-sm'>
              <span className='text-typography-secondary font-semibold'>
                Customer Reference:
              </span>
              <span>te-hh2001-u1822-sa</span>
            </div> */}
          {customerDetails.map(({ col_name, col_value }, idx) => (
            <div key={idx} className='grid grid-cols-2 py-2 text-sm'>
              <span className='text-typography-secondary text-left font-semibold'>
                {col_name}
              </span>
              <span className='text-typography-secondary px-0 lg:px-6'>
                {col_value}
              </span>
            </div>
          ))}
        </div>

        <div className='border-border-light flex flex-col space-y-1 border-b py-2'>
          {addressDetails.map(({ col_name, col_value }, idx) => (
            <div key={idx} className='grid grid-cols-2 py-2 text-sm'>
              <span className='text-typography-secondary text-left font-semibold'>
                {col_name}
              </span>
              <span className='text-typography-secondary px-0 lg:px-6'>
                {col_value}
              </span>
            </div>
          ))}
        </div>

        <div className='border-border-light flex flex-col space-y-1 border-b py-2'>
          {accountDetails.map(({ col_name, col_value }, idx) => (
            <div key={idx} className='grid grid-cols-2 py-2 text-sm'>
              <span className='text-typography-secondary text-left font-semibold'>
                {col_name}
              </span>
              <span className='text-typography-secondary px-0 lg:px-6'>
                {col_value}
              </span>
            </div>
          ))}

          <div className='grid grid-cols-2 py-2 text-sm'>
            <span className='text-typography-secondary text-left font-semibold'>
              First Payment Date
            </span>
            <span className='text-typography-secondary px-0 lg:px-6'>TBC</span>
          </div>
          {/* <div className='grid grid-cols-2 py-2 text-sm'>
              <span className='font-semibold'> Date: </span>
              <span></span>
            </div> */}
        </div>

        <div className='bg-background-offwhite flex flex-col space-y-1 p-4 text-sm'>
          <span>Homebox UK Limited</span>
          <span>152-160 Kemp House,</span>
          <span>City Road,</span>
          <span>EC1V 2NX</span>
          <a
            className='font-semibold hover:underline'
            href='mailto:info@homebox.co.uk'
          >
            info@homebox.co.uk
          </a>
        </div>

        <span className=' text-typography-inactive text-sm'>
          If you have any questions regarding the Direct Debit Service provider,
          please call London and Zurich Ltd on
          <a href='tel:01212347999' className='font-semibold hover:underline'>
            0121 234 7999
          </a>
          or email at
          <a
            href='mailto:info@landz.co.uk'
            className='font-semibold hover:underline'
          >
            info@landz.co.uk
          </a>
          .
        </span>
      </div>
    </div>
  );
}
