import React from 'react';
import {
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  cn,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from 'homebox-ui';
import { MdArrowDropDown } from 'react-icons/md';
import { snakeCaseToReadable } from '../../utils';
import { calculateBillPrice } from '../../utils/functions';

const FeatureCard = ({ offer, offerName }) => {
  return (
    <div className='border-neutral rounded-lg border bg-neutral-50 p-2'>
      <div className='flex flex-col space-y-2 text-sm'>
        <div>
          <p className='font-medium'>{offer}</p>
        </div>
        <div>
          <p className='text-typography-secondary'>{offerName}</p>
        </div>
      </div>
    </div>
  );
};
export default function GroupPlansCard({
  choosePlan,
  bill,
  isBillSelected,
  isPerk,
  isMonthly,
  noOfBillPayers,
  noOfHousemates,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const isSelected = isBillSelected(bill?.id, isPerk);

  const additionalBillData = Object.assign(bill?.additional || {}, {});
  // remove excess
  let excluded = ['bill_id', 'created_at', 'updated_at'];

  const additionalPerks = Object.entries(additionalBillData || {})
    .map(([offer, name]) => {
      return {
        name,
        offer: snakeCaseToReadable(offer),
        show: !excluded.includes(offer),
      };
    })
    .filter(({ show }) => show);

  return (
    <div className='border-border  w-full border-t-2 bg-white p-4'>
      <div className='flex flex-col space-y-4'>
        {/*  */}
        <div className='flex justify-between'>
          <div className='flex flex-col space-y-2'>
            <div className='h-16 w-16'>
              <img
                src={bill?.logo_path}
                alt={`${bill?.provider} logo`}
                className='h-full w-full cursor-pointer rounded-2xl object-contain shadow-lg'
              />
            </div>
            <div>
              <p className='text-typography text-left text-sm font-semibold'>
                {bill?.name}
                {bill?.group_name === 'Renewable Energy'
                  ? `(${bill?.description})`
                  : ''}
              </p>
            </div>
          </div>
          <div className='flex flex-col justify-between'>
            <div>
              <p className='text-sm font-medium'>
                <span className='text-typography font-semibold'>
                  £
                  {calculateBillPrice(
                    bill,
                    noOfHousemates,
                    noOfBillPayers,
                    isMonthly
                  )}
                </span>
                <span className='text-typography-secondary'>
                  {isMonthly ? '/month' : '/week'}
                </span>
                {Number(noOfBillPayers) > 1 && (
                  <p className='text-p-tiny text-typography-secondary font-normal sm:text-sm'>
                    per bill payer
                  </p>
                )}
              </p>
            </div>
            <div>
              <Dialog>
                <DialogTrigger asChild>
                  <button
                    // variant='link'
                    className='text-typography-interactive text-p-sm cursor-pointer p-0 font-semibold underline-offset-2 hover:underline sm:text-base'
                  >
                    Learn more
                  </button>
                </DialogTrigger>
                <DialogContent className=''>
                  <DialogHeader>
                    <DialogTitle className='text-center'>
                      {bill?.name}
                    </DialogTitle>
                  </DialogHeader>
                  <DialogDescription className='flex-col items-center justify-center text-center'>
                    <span className='text-typography-secondary my-2 text-center text-base'>
                      {bill?.description}
                    </span>
                    {bill?.description_html && (
                      <div
                        className='text-typography-secondary overflow-x-scroll py-3 text-base'
                        dangerouslySetInnerHTML={{
                          __html: bill?.description_html,
                        }}
                      />
                    )}
                  </DialogDescription>
                  <DialogFooter>
                    <DialogTrigger asChild>
                      <Button variant={'secondary'} type='button'>
                        Close
                      </Button>
                    </DialogTrigger>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>

        {/*  */}
        <Collapsible open={isOpen} onOpenChange={setIsOpen} className=''>
          <div
            className={cn(
              `grid gap-2`,
              additionalPerks?.length >= 3 ? 'grid-cols-3' : 'grid-cols-2'
            )}
          >
            {additionalPerks.slice(0, 3).map(({ name, offer }, idx) => (
              <div
                key={idx}
                className='border-neutral rounded-lg border bg-neutral-50 p-2'
              >
                <div className='flex flex-col space-y-2 text-sm'>
                  <div className='font-medium'>
                    <p className='font-medium'>{offer}</p>
                  </div>
                  <div>
                    <p className='text-typography-secondary'>{name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <CollapsibleContent>
            <div
              className={cn(
                `mt-2 grid gap-2`,
                additionalPerks?.length >= 3 ? 'grid-cols-3' : 'grid-cols-2'
              )}
            >
              {additionalPerks.length > 3 &&
                additionalPerks
                  .slice(3)
                  .map(({ name, offer }, idx) => (
                    <FeatureCard key={idx} offerName={name} offer={offer} />
                  ))}
            </div>
          </CollapsibleContent>

          <div className='mt-4 flex w-full space-x-3'>
            {additionalPerks.length > 3 ? (
              <CollapsibleTrigger asChild>
                <Button
                  className='w-1/2 lg:w-2/5'
                  variant='secondary'
                  endIcon={<MdArrowDropDown />}
                >
                  {isOpen ? 'Less info' : 'More info'}
                </Button>
              </CollapsibleTrigger>
            ) : (
              <div className='w-1/2 lg:w-2/5'></div>
            )}
            <Button
              variant={isSelected ? 'secondary' : 'primary'}
              onClick={() => choosePlan(bill)}
              className='w-1/2 lg:w-3/5'
            >
              {isSelected ? 'Remove' : 'Select Plan'}
            </Button>
          </div>
        </Collapsible>
      </div>
    </div>
  );
}
