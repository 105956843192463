import React from 'react';
import { calculateBillPrice } from '../../utils';
import UtilityBreakdownSkeleton from '../Skeletons/UtilityBreakdownSkeleton';
import { Button } from 'homebox-ui';

const UtilityBreakdown = ({ bills, housemates, redirectToSetupBills }) => {
  const getGroupLabel = groupName => {
    switch (groupName) {
      case 'Renewable Energy':
        return 'Energy';
      default:
        return groupName;
    }
  };

  return (
    <div className='rounded-lg border border-[#dbdcdd] bg-[#F3F5F6] p-5 '>
      <h4 className='mb-3 text-left text-lg font-bold sm:text-center'>
        Utility Breakdown:
      </h4>
      {bills.length === 0 ? (
        <div className='my-5'>
          <UtilityBreakdownSkeleton />
        </div>
      ) : (
        <>
          {bills.map(bill => (
            <div
              className='mb-5 flex w-full  items-center justify-between'
              key={bill.id}
            >
              <div className='flex'>
                <div className='mr-2 flex h-[50px] w-[50px] items-center rounded-lg bg-white p-2 shadow-lg'>
                  <img
                    src={bill.provider_data?.logo_path ?? bill.logo_path}
                    alt={bill.provider_data?.name ?? bill.provider?.name}
                    className=''
                  />
                </div>
                <span className='text-md'>
                  <p className='font-bold'>
                    {' '}
                    {bill?.label ? bill.label : getGroupLabel(bill.group_name)}
                  </p>
                  <p> {bill.provider_data?.name ?? bill.provider} </p>
                </span>
              </div>
              <span>
                <p className='font-bold'>
                  £
                  {(
                    calculateBillPrice(bill, housemates, housemates, true) *
                    Number(housemates)
                  ).toFixed(2)}
                </p>
                <p className='text-sm'> per month </p>
              </span>
            </div>
          ))}
          <div className=' flex flex-col items-center justify-center space-y-3'>
            <p className='py-2 text-center'>
              We offer a service where you can combine all the above household
              bills into one payment per month. Learn more below!
            </p>

            <Button
              variant={null}
              className='mx-auto w-full bg-[#FFD049] !text-base text-[#252C35] sm:w-auto'
              onClick={redirectToSetupBills}
            >
              Get instant bills package quote
            </Button>

            {/* Hidden for now */}
            <div className='hidden flex-col space-y-1 text-center'>
              <p className='text-base'>
                Want to speak with a bills specialist?
              </p>
              <a
                className='text-sm font-semibold  underline'
                href='tel:+02081061894'
              >
                Book a phone call here
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UtilityBreakdown;
