import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from 'homebox-ui';
import React from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { FaTimesCircle } from 'react-icons/fa';
import './style.css';

export default function InternetOptionsModal({
  open,
  setOpen,
  property,
  internetOptions,
  fastestInternet,
}) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className='rounded-lg sm:top-auto'>
        <DialogTitle className='mt-4 text-center text-2xl'>
          Broadband speeds
        </DialogTitle>
        <DialogDescription className='flex max-h-screen flex-col space-y-2 overflow-auto p-2 pb-20 lg:max-h-[75vh] lg:p-5'>
          <span className='py-2 text-center text-base'>
            {property.address_line1} , {property.address_city}
          </span>

          <>
            <div className='internet-options__bill-container flex flex-wrap justify-between'>
              {internetOptions.map(option => (
                <div
                  className='internet-options__option-container__option mb-4 flex justify-center'
                  key={option.title}
                >
                  <div className='internet-options__option-container__option__card flex flex-col items-center justify-center rounded-lg border-2 border-[#dbdcdd] bg-[#f4f5f6] px-1 py-5 text-center'>
                    <div className='relative mb-2'>
                      {option.isAvailable ? (
                        <MdCheckCircle className='text-5xl text-[#43a048]' />
                      ) : (
                        <FaTimesCircle className='text-5xl text-[#FF6B6B]' />
                      )}
                    </div>
                    <p className='mb-1 font-bold'> {option.title}</p>
                    <p className='pb-2'>{option.description}</p>
                    <p className='pb-2'>{option.speed}</p>
                  </div>
                </div>
              ))}
            </div>
            {fastestInternet && (
              <div className='flex flex-col items-center space-y-5 p-3'>
                <p className='text-typography-secondary flex-1'>
                  At this address, the highest available download speed is{' '}
                  {fastestInternet.speed} with the following networks available:{' '}
                  {fastestInternet.providers.join(', ')}
                </p>

                <a
                  className='text-sm font-semibold  underline'
                  href='https://comparison.homebox.co.uk/journeys/broadband/movein/'
                  rel='noreferrer noopener'
                  target='_blank'
                >
                  Compare broadband packages available for your home here{' '}
                </a>
              </div>
            )}
          </>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}
