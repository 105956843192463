import React, { useState, useEffect } from 'react';
import { Button, Label, Input, RadioGroup, RadioGroupItem } from 'homebox-ui';
import { MdArrowForward } from 'react-icons/md';
import { PostCodeInput } from '@sunrise/components';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useFormikContext, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { invalidDomains } from '../utils';
import FormErrorMessage from '../components/FormErrorMessage';
import AccountSetupSkeleton from '../components/Skeletons/AccountSetupSkeleton';
import { useSelector } from 'react-redux';

export default function AccountSetup() {
  const navigate = useNavigate();
  const context = useOutletContext();
  const { nameUrl } = useParams();

  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const {
    postCode,
    setPostCode,
    setFetchQuote,
    quoteDataIsSuccess,
    packageDataIsSuccess,
    quoteDataLoading,
    isLoading,
    setManualAddress,
    isExtractBillSuccess,
  } = context;

  const {
    values,
    handleChange,
    setFieldValue,
    setValues,
    validateForm,
    setTouched,
    touched,
    handleBlur,
  } = useFormikContext();

  const currentPartnerToken = useSelector(
    state => state.partnerAuth.partnerToken
  );

  const quoteLeadSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email address is required.')
      .email('Please enter a valid email address')
      .test('domain', 'Please use a real email address', value => {
        const emailDomain = value.split('@')[1];
        return !invalidDomains.includes(emailDomain);
      }),
  });

  const validateQuoteLead = async () => {
    setFormErrors({});

    try {
      const { email } = values;

      await quoteLeadSchema.validate({ email }, { abortEarly: false });

      setFieldValue('email', email);

      return true;
    } catch (error) {
      const validationErrors = {};

      error.inner.forEach(err => {
        if (err.path) {
          validationErrors[err.path] = err.message;
        }
      });

      setFormErrors(validationErrors);

      return false;
    }
  };

  const proceedToNextStep = async () => {
    const validatedQuoteLead = await validateQuoteLead();

    const validatedForm = await validateForm();
    setTouched({
      ...touched,
      address: {
        postcode: true,
        line1: true,
        city: true,
      },
      household_type: true,
      bill_payers: true,
      total_occupants: true,
    });

    // reset quote data on new request
    setValues(prevValues => {
      return {
        ...prevValues,
        billSelected: [],
        perks: [],
        energyBills: 0,
        energyBillSelected: false,
      };
    });
    setValidated(Object.keys(validatedForm).length === 0 && validatedQuoteLead);
  };

  useEffect(() => {
    setFetchQuote(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (validated) {
      // logger.track('fetchNewQuote', {
      //   Journey: 'Tenant Journey',
      // });
      setFetchQuote(`${Math.random() * (99999 - 1) + 1}`);
      // TODO: Uncomment when quote API is fixed
      if (packageDataIsSuccess && quoteDataIsSuccess && isExtractBillSuccess) {
        // createQuoteLead({
        //   email_address: values.email,
        // }).catch();
        navigate(`/${nameUrl}/setup-bill`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    validated,
    packageDataIsSuccess,
    quoteDataIsSuccess,
    isExtractBillSuccess,
  ]);

  return (
    <div className='flex flex-col'>
      <h4 className='text-p-strong sm:text-h4 text-typography-primary'>
        Enter your details below.
      </h4>

      {!currentPartnerToken ? (
        <AccountSetupSkeleton />
      ) : (
        <div className='flex flex-col space-y-6 pt-6'>
          <div className='w-full sm:max-w-xs'>
            <div className='w-full'>
              <PostCodeInput
                defaultPostCode={postCode}
                defaultAddress={values?.address}
                onPostCodeChange={val => {
                  setPostCode(val);
                  setFieldValue('address.postcode', val);
                }}
                onAddressSelectChange={val => {
                  setFieldValue('address', val);
                }}
                customAddress={values?.address}
                setCustomAddress={val => {
                  setManualAddress(val);
                  setFieldValue('address', val);
                }}
                handleBlur={handleBlur}
                ignoreStyle
              />
              <span className='flex flex-col space-y-2 text-xs'>
                <ErrorMessage
                  component={'span'}
                  className='text-error'
                  name='address.postcode'
                />
                <ErrorMessage
                  component={'span'}
                  className='text-error'
                  name='address.line1'
                />
                <ErrorMessage
                  component={'span'}
                  className='text-error'
                  name='address.city'
                />
              </span>
            </div>
          </div>

          <div className='w-full sm:max-w-lg'>
            <Label htmlFor='total_occupants'>
              How many people will be living in your new home?
            </Label>
            <RadioGroup
              id='total_occupants'
              name='total_occupants'
              defaultValue={`${values.total_occupants}`}
              value={`${values.total_occupants}`}
              onValueChange={value => {
                setValues({
                  ...values,
                  total_occupants: Number(value),
                  bill_payers: Number(value),
                  tenants: Array(Number(value)).fill({
                    first_name: '',
                    last_name: '',
                    phone_number: '',
                  }),
                });
              }}
              className='mt-4 flex flex-wrap'
            >
              {new Array(12).fill('').map((_, idx) => (
                <Label
                  htmlFor={`${idx + 1}-housemates`}
                  key={`${idx + 1}-housemates`}
                  className='hover:bg-button-primary-hover ring-primary-lighter border-neutral bg-background-secondary [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark  [&:has([data-state=checked])]:hover:bg-primary-dark mr-[10px] flex h-10 w-10 flex-col items-center justify-center rounded-full border p-2 font-sans text-sm font-medium text-neutral-600 hover:bg-neutral-200 focus:ring-4 focus-visible:outline-none focus-visible:ring-offset-0 peer-disabled:bg-neutral-100'
                >
                  <RadioGroupItem
                    value={`${idx + 1}`}
                    id={`${idx + 1}-housemates`}
                    className='sr-only'
                  />
                  {`${idx + 1}`}
                  {idx + 1 === 12 && '+'}
                </Label>
              ))}
            </RadioGroup>
            <ErrorMessage
              component='span'
              className='text-error text-xs'
              name='total_occupants'
            />
          </div>
          {values.total_occupants > 1 && (
            <div className='w-full sm:max-w-lg'>
              <Label htmlFor='bill_payers'>
                How many of these housemates will be paying the bills?
              </Label>
              <RadioGroup
                className='mt-4 flex flex-wrap'
                id='bill_payers'
                name='bill_payers'
                defaultValue={`${values.bill_payers}`}
                value={`${values.bill_payers}`}
                onValueChange={value => {
                  setFieldValue('bill_payers', Number(value));
                  setFieldValue(
                    'tenants',
                    Array(Number(value)).fill({
                      first_name: '',
                      last_name: '',
                      phone_number: '',
                    })
                  );
                }}
              >
                {new Array(values.total_occupants).fill('').map((_, idx) => (
                  <Label
                    htmlFor={`${idx + 1}-billpayers`}
                    key={`${idx + 1}-billpayers`}
                    className='hover:bg-button-primary-hover ring-primary-lighter border-neutral bg-background-secondary [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark [&:has([data-state=checked])]:hover:bg-primary-dark mr-[10px]  flex h-10 w-10 flex-col items-center justify-center rounded-full border p-2 font-sans text-sm font-medium text-neutral-600 hover:bg-neutral-200 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100'
                  >
                    <RadioGroupItem
                      value={`${idx + 1}`}
                      id={`${idx + 1}-billpayers`}
                      className='sr-only'
                    />
                    {`${idx + 1}`}
                    {idx + 1 === 12 && '+'}
                  </Label>
                ))}
              </RadioGroup>
              <ErrorMessage
                component='span'
                className='text-error text-xs'
                name='bill_payers'
              />
            </div>
          )}
          <div>
            <Label className='font-sans'>What type of tenant are you?</Label>
            <RadioGroup
              className='mt-2 flex w-full space-x-2 sm:max-w-md'
              defaultValue={values.household_type}
              name='household_type'
              value={values.household_type}
              onValueChange={value => {
                setFieldValue('household_type', value);
              }}
              onBlur={handleBlur}
            >
              <Label
                htmlFor='professional-tenant'
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem
                  value='professional-tenant'
                  id='professional-tenant'
                  className='sr-only'
                />
                Professional
              </Label>
              <Label
                htmlFor='student'
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem
                  value='student'
                  id='student'
                  className='sr-only'
                />
                Student
              </Label>
            </RadioGroup>
            <ErrorMessage
              component='span'
              className='text-error text-xs'
              name='household_type'
            />
          </div>
          {/* TODO: Uncomment code once API to update main tenant is flexible */}
          {/* {values.household_type && (
          <div className='w-full  sm:max-w-xs'>
            <Label htmlFor='email'>Your Email</Label>
            <Input
              id='email'
              startIcon={<MdMail />}
              placeholder='Enter email address'
              type='email'
              name='email'
              value={values.email}
              onChange={handleChange}
            />
            <ErrorMessage
              component='span'
              className='text-error text-xs'
              name='email'
            />
          </div>
        )} */}

          <div className='w-full sm:max-w-xs'>
            <Label htmlFor='email'>Email Address</Label>
            <Input
              name='email'
              id='email'
              onChange={handleChange}
              value={values.email}
              placeholder='e.g johnsmith@gmail.com'
            />
            <FormErrorMessage errors={formErrors} name='email' />
          </div>

          <div className='mx-auto mt-8 flex w-full max-w-none flex-col space-y-4 sm:max-w-sm'>
            <Button
              variant='primary'
              size={'cta'}
              id='getquote-submit'
              className='font-semibold'
              onClick={proceedToNextStep}
              disabled={!postCode}
              isLoading={quoteDataLoading || isLoading}
              endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
              type='button'
            >
              Get Quote
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
