var config = {};

// Defaults.
config.env = process.env.NODE_ENV || 'development';
config.i8n = process.env.REACT_APP_I8N === 'true' ? true : false;
try {
  config.BUILD_MODE = process.env.REACT_APP_BUILD_MODE;
} catch (error) {
  config.BUILD_MODE = 'widget';
}
if (config.env === 'production') {
  // Production mode.
  config.BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://accounts.billingbetter.co.uk/api/';
} else if (config.env === 'sandbox') {
  // Sandbox mode.
  config.BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://sandbox.billingbetter.co.uk/api/';
} else if (config.env === 'staging') {
  // Staging mode.
  config.BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://staging.billingbetter.co.uk/api/';
} else if (config.env === 'development') {
  // Development (local) mode.
  config.BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api/';
}
module.exports = config;