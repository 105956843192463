export const steps = [
  {
    step: 0,
    title: 'Getting Started',
    path: '/',
    fullPage: true,
  },
  {
    step: 1,
    // title: 'Account Setup',
    title: 'Get Started',
    path: '/setup-account',
  },
  {
    step: 2,
    title: 'Bill Setup',
    path: '/setup-bill',
  },
  // {
  //   step: 3,
  //   title: 'Energy Setup',
  //   path: '/energy-setup',
  // },
  {
    step: 3,
    title: 'Account Confirmation',
    path: '/account-setup',
  },
  {
    step: 4,
    title: 'Add housemate details',
    path: '/bill-payers',
  },
  {
    step: 5,
    title: 'Payment Settings',
    path: '/setup-payment',
  },

  {
    step: 6,
    title: 'Success',
    path: '/success',
    fullPage: true,
    noLayout: true,
  },
  // {
  //   step: 7,
  //   title: 'Setup Rent Recipient',
  //   path: '/setup-rent',
  // },
];

/**
 * @param {Location} location - The React Router location object.
 */
export const activeStep = location => {
  let active = steps.filter(step => {
    const path = location.pathname.split('/').pop();

    return step.path === '/' + path;
  });

  return active?.[0];
};
