import React, { useState } from 'react';
import TrustQuote from '../../assets/trust-quote.svg';
import TrustPilotStars from '../../assets/trustpilot-stars.svg';

export const TrustPilotReviewCard = ({
  title,
  date,
  reviewer,
  review,
  link,
}) => {
  const [showFullText, setShowFullText] = useState(false);

  const maxLength = 160;

  const truncatedText = text =>
    showFullText ? text : `${text.slice(0, maxLength)}...`;
  return (
    <div className='flex flex-col space-y-6 p-4 shadow-md'>
      <img className='h-10 w-10' src={TrustQuote} alt='review quote' />

      <div className='flex flex-col space-y-6'>
        <h5 className='min-h-[50px] text-xl font-semibold'>
          {title} <span className='text-sm font-normal'>- {reviewer}</span>
        </h5>

        <div className='flex space-x-2'>
          <img src={TrustPilotStars} className='h-5' alt='Trust Pilot Stars' />

          <span className='text-sm'>{date}</span>
        </div>

        <div className='flex flex-col justify-between'>
          <p className='text-sm'>
            {review?.length <= maxLength ? review : truncatedText(review)}
          </p>
          {
            <a
              className='cursor-pointer text-sm text-[#209653] hover:border-[#209653] hover:underline'
              href={link}
              target='_blank'
              rel='noreferrer noopener'
              onClick={() => setShowFullText(!showFullText)}
            >
              {showFullText ? 'Read less' : 'Read more'}
            </a>
          }
        </div>
      </div>
    </div>
  );
};
