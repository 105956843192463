import React, { useEffect, useState } from 'react';
import { Button, Skeleton } from 'homebox-ui';
import {
  useGetPropertyWithRealtimeProvidersQuery,
  useGetTenantPackageQuery,
} from '../../app/services';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { MdWhatsapp } from 'react-icons/md';
import { useExtractQuoteBills } from '../../hooks/useExtractQuoteBills';
import KeyInformation from '../../components/YourHomeMove/KeyInformation';
import UtilityBreakdown from '../../components/YourHomeMove/UtilityBreakdown';
import useLoadPartner from '../../hooks/useLoadPartner';
import { useCalculatePricePerHousemate } from '../../hooks/useCalculatePricePerHousemate';
import { selectDefaultBill } from '../../utils';
import Logo from '../../components/Logo';
import { useGlobalConfig } from '../../context';
import YourHomeMoveInfoSection from '../../components/YourHomeMove/YourHomeMoveInfoSection';

export default function YourHomeMove() {
  const [urlParams] = useSearchParams();
  const addressId = urlParams.get('address_id');
  const propertyId = urlParams.get('property_id');
  const housemates = Number(urlParams.get('no_of_tenants') || 1);
  const household_type = urlParams.get('household_type');
  const is_student = household_type === 'student';

  const location = useLocation();
  const [user, setUser] = useState({});
  const [lead, setLead] = useState({});

  useEffect(() => {
    if (location?.state) {
      setUser(location.state?.user);
      setLead(location.state?.lead);
    }
  }, [location?.state]);

  const navigate = useNavigate();

  const { partner, nameUrl, isLoading: loadingLogo } = useLoadPartner(true);
  const welcomePageUrl = `/${nameUrl}/welcome `;

  const shouldRedirect = (!addressId && !propertyId) || !housemates;
  useEffect(() => {
    if (shouldRedirect) {
      navigate(welcomePageUrl);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressId, housemates]);

  const { data, isLoading: loadingProviders } =
    useGetPropertyWithRealtimeProvidersQuery(
      { addressId, propertyId },
      { skip: shouldRedirect || !partner }
    );

  const [providers, setProviders] = useState({});
  const [property, setProperty] = useState({});
  const [internetAvailable, setInternetAvailable] = useState({});

  useEffect(() => {
    if (data) {
      setProperty(data.property);
      setInternetAvailable(data.internet);
      const nonNullProviders = Object.keys(data.providers).reduce(
        (result, key) => {
          if (data.providers[key] !== null) {
            result[key] = data.providers[key];
          }
          return result;
        },
        {}
      );

      setProviders(nonNullProviders);
    }
  }, [data]);

  const { data: customPackageData } = useGetTenantPackageQuery(
    {
      ...(addressId ? { address_id: addressId } : { property_id: propertyId }),
      housemates,
      bill_payers: housemates,
      is_student,
    },
    { skip: shouldRedirect || !partner }
  );

  const { essentials, extras, serviceBill } =
    useExtractQuoteBills(customPackageData);

  const [selectedBills, setSelectedBills] = useState([]);

  useEffect(() => {
    const requiredGroupNames = [
      'Renewable Energy',
      'Water',
      'Council Tax',
      'Internet',
    ];

    const allBills = [...essentials, ...extras].filter(({ name }) =>
      requiredGroupNames.includes(name)
    );

    const defaults = allBills.map(essential => {
      let bill = selectDefaultBill(essential.bills);

      // For energy, we always use the bill provider
      if (bill.name !== 'Renewable Energy') {
        bill.provider_data = getProvider(bill.group_id);
      }

      return bill;
    });

    setSelectedBills(defaults);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [essentials, providers]);

  const getProvider = group_id => {
    const obj = Object.entries(providers).find(([providerName, value]) => {
      return value.group_id === group_id && providerName !== 'gas';
    });

    return obj ? obj[1] : null;
  };

  const { pricePerHousemate } = useCalculatePricePerHousemate(
    selectedBills,
    {},
    housemates
  );

  const { updateConfig } = useGlobalConfig();

  const redirectToSetupBills = () => {
    updateConfig({
      userParams: {
        ...(addressId && { address_id: property?.external_id }),
        address: {
          line1: property?.address_line1,
          line2: property?.address_line2,
          city: property?.address_city,
          postcode: property?.address_postcode,
        },
        postcode: property?.address_postcode,
        housemates,
        bill_payers: housemates,
        tenants: Array(Number(housemates)).fill({
          first_name: '',
          last_name: '',
          phone_number: '',
        }),
        origin: 'your-home-move',
        tenancy_start: lead?.tenancy_start ? new Date(lead.tenancy_start) : '',
        tenancy_end: lead?.tenancy_end ? new Date(lead.tenancy_end) : '',
        email: user?.email_address,
        phone_number: user?.phone_number,
        property_id: property?.id,
        household_type,
        is_student,
      },
    });

    navigate(`/${nameUrl}/setup-bill`, { state: { user, lead } });
  };

  return (
    <div className='@lg:px-0 flex w-full flex-col items-center px-6 pb-16 pt-8'>
      <div className='mb-16 flex justify-center'>
        {loadingLogo ? (
          <div className='animate-pulse'>
            <div className='h-14 w-[130px] rounded-lg border-[#dbdcdd] bg-[#f4f5f6] shadow'></div>
          </div>
        ) : (
          <Logo />
        )}
      </div>

      <div className='flex w-full max-w-5xl flex-col gap-5 sm:w-4/5 lg:flex-row 2xl:w-1/2 '>
        <div className='mr-2 flex-1'>
          <h1 className='mt-5 text-center text-[28px] font-semibold lg:text-start'>
            🏡 Your Home Move
          </h1>

          <span className='flex items-center justify-center text-center text-lg lg:items-start lg:justify-start lg:text-start'>
            {loadingProviders || !property ? (
              <Skeleton className='h-10 w-full rounded-xl' />
            ) : (
              <>
                {property?.address_line1 && property?.address_city && (
                  <p>
                    {property?.address_line1}, {property?.address_city},{' '}
                    {property?.address_postcode}{' '}
                    <br className='flex lg:hidden' />
                    <a
                      className='pointer text-sm underline'
                      href={welcomePageUrl}
                    >
                      (Change address)
                    </a>
                  </p>
                )}
              </>
            )}
          </span>

          <KeyInformation
            selectedBills={selectedBills}
            providers={providers}
            pricePerHousemate={pricePerHousemate}
            housemates={housemates}
            property={property}
            internetAvailable={internetAvailable}
            is_student={is_student}
          />

          <Button
            variant='primary'
            size={'cta'}
            className='border-2 border-[#25D366] bg-white px-5 font-semibold text-[#25D366] hover:bg-[#25D366] hover:text-white'
            startIcon={<MdWhatsapp className='mr-2 h-7 w-7 ' />}
          >
            <a
              href={`https://api.whatsapp.com/send?text=Our home move quote ${encodeURIComponent(
                window.location.href
              )}`}
              data-action='share/whatsapp/share'
              className='text-base'
              target='_blank'
              rel='noreferrer noopener'
            >
              Share
            </a>
          </Button>
        </div>

        <div className='mt-[35px] flex-1 sm:mt-0'>
          <UtilityBreakdown
            bills={selectedBills}
            housemates={housemates}
            providers={providers}
            redirectToSetupBills={redirectToSetupBills}
            serviceBill={serviceBill}
          />
        </div>
      </div>
      <YourHomeMoveInfoSection
        ctaInfo={{
          redirectToSetupBills,
          property,
          selectedBills,
          providers,
        }}
      />
    </div>
  );
}
