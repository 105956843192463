import React, { useState } from 'react';
import { useLazyVerifyCouponQuery } from '../../app/services';
import { Button, Input } from 'homebox-ui';
import {
  MdClose,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from 'react-icons/md';
import { activeStep } from '../../utils';
import { useLocation } from 'react-router-dom';

export default function CouponInput({
  coupon,
  setCoupon,
  couponApplied,
  setCouponApplied,
}) {
  const location = useLocation();
  const [showCouponInput, setShowCouponInput] = useState(false);
  const [couponInput, setCouponInput] = useState('');
  const [couponError, setCouponError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  let [verifyCoupon] = useLazyVerifyCouponQuery();

  const activePage = activeStep(location);

  const canBeApplied = activePage?.path === '/setup-bill';

  const applyCoupon = async () => {
    if (couponInput.length > 0) {
      setIsSubmitting(true);

      const {
        data: couponData,
        isSuccess: couponSuccess,
        error: couponError,
      } = await verifyCoupon({ coupon: couponInput });

      setIsSubmitting(false);

      if (couponSuccess) {
        setCouponInput(prev => '');
        setShowCouponInput(prev => false);
        setCoupon(prev => couponData.data);
        setCouponApplied(prev => true);
        setCouponError(prev => '');
      }

      if (couponError?.status === 404) {
        setCouponError(
          prev => couponError?.data?.message || 'Invalid coupon code'
        );
      }
    }
  };

  const resetCouponInput = () => {
    setCouponInput(prev => '');
    setShowCouponInput(prev => true);
    setCoupon(prev => '');
    setCouponApplied(prev => false);
    setCouponError(prev => '');
  };

  const descriptionLength = coupon?.description?.length;

  return (
    <>
      {canBeApplied && !couponApplied && (
        <span
          id='show-coupon'
          className='text-typography-secondary @sm:px-0 mb-2 mt-1 flex cursor-pointer items-center p-0 text-sm font-normal underline'
          onClick={() => setShowCouponInput(!showCouponInput)}
        >
          Add a promo code
          {showCouponInput ? (
            <MdOutlineKeyboardArrowUp className='inline' />
          ) : (
            <MdOutlineKeyboardArrowDown className='inline' />
          )}
        </span>
      )}
      {showCouponInput && (
        <div className='flex'>
          <Input
            autoFocus
            className='rounded-lg  placeholder:text-sm'
            placeholder='Enter code here'
            type='text'
            name='coupon'
            value={couponInput}
            id='coupon'
            onChange={e => setCouponInput(e.target.value)}
            autoComplete='hb-coupon'
            maxLength='20'
          />
          <Button
            id='apply-coupon'
            variant='null'
            disabled={couponInput?.length < 1}
            onClick={() => applyCoupon()}
            className='rounded-lg'
            type='button'
            isLoading={isSubmitting}
          >
            Apply
          </Button>
        </div>
      )}

      <div className='@sm:w-3/4 flex w-1/2'>
        {!showCouponInput && couponApplied && (
          <div className='bg-success-100 flex rounded-md py-3 text-sm'>
            <div
              className={`${
                descriptionLength > 90 ? 'mb-1 ' : ''
              } max-h-13  flex-col `}
            >
              <p className='text-typography-success px-4 text-left font-bold tracking-wide '>
                {coupon?.code}
              </p>
              <p className='text-typography-success line-clamp-3 pl-4 pr-2 text-left'>
                {coupon?.description}
              </p>
            </div>
            {canBeApplied && (
              <button
                type='button'
                className='flex pr-2 '
                onClick={() => resetCouponInput()}
              >
                <MdClose className='text-success-800 h-4 w-4 cursor-pointer' />
              </button>
            )}
          </div>
        )}
      </div>
      <div className='py-1'>
        {couponError && (
          <p id='coupon-error' className='text-error text-xs'>
            {couponError}
          </p>
        )}
      </div>
    </>
  );
}
