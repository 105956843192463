import { configureStore } from '@reduxjs/toolkit';
import { homeboxService } from './services';
import partnerAuthReducer from './features/partners/partnerAuthSlice';

export const store = configureStore({
  reducer: {
    [homeboxService.reducerPath]: homeboxService.reducer,
    partnerAuth: partnerAuthReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(homeboxService.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});
