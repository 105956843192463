import React, { useEffect, useState } from 'react';
import { Button, Label, Input, RadioGroup, RadioGroupItem } from 'homebox-ui';
import { MdArrowForward } from 'react-icons/md';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { PostCodeInput } from '@sunrise/components';
import FormErrorMessage from '../components/FormErrorMessage';
import MobileNavControl from '../components/MobileNavControl';
import DirectDebitConfirmation from '../components/DirectDebitConfirmation';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import { useUpdateBillPayerPaymentDetailsMutation } from '../app/services';
import { toast } from 'react-hot-toast';

export default function SetupPayment() {
  const { nameUrl } = useParams();
  const [data, setData] = useState({
    account_name: '',
    account_number: '',
    sort_code: '',
    billing_address: {
      line1: '',
      line2: '',
      city: '',
      postcode: '',
    },
  });

  const [debitedName, setDebitedName] = useState('yes');
  const [allowDebit, setAllowDebit] = useState('yes');

  const navigate = useNavigate();

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const {
    rate,
    setRate,
    serviceBill,
    userId,
    orderId,
    coupon,
    setCoupon,
    couponApplied,
    setCouponApplied,
    IS_TEST_ENV,
  } = useOutletContext();

  const { values } = useFormikContext();

  const setupPaymentSchema = Yup.object().shape({
    account_name: Yup.string().required('account name is required'),
    account_number: Yup.string()
      .min(8, 'invalid account number')
      .max(8, 'invalid account number')
      .required('account number is required'),
    sort_code: Yup.string()
      .min(6, 'invalid sort code')
      .max(8, 'invalid sort code')
      .required('sort code is required'),
    billing_address: Yup.object().shape({
      line1: Yup.string().required('Address Line 1 is required'),
      line2: Yup.string(),
      city: Yup.string().required('City is required.'),
      postcode: Yup.string().required('Postcode is required.'),
    }),
  });

  const [openModal, setOpenModal] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [
    updatePaymentDetails,
    { isLoading: isUpdating, error: errorUpdating, isSuccess },
  ] = useUpdateBillPayerPaymentDetailsMutation();

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      (!orderId || !userId) &&
      !IS_TEST_ENV
    ) {
      window.location.replace(`/${nameUrl}`);
    }
  }, [orderId, userId, IS_TEST_ENV, nameUrl]);

  const proceedToNextStep = async () => {
    setIsValidating(true);
    setFormErrors({});

    await new Promise(resolve => setTimeout(resolve, 800));

    try {
      await setupPaymentSchema.validate(data, { abortEarly: false });

      setOpenModal(true);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach(err => {
        if (err.path) {
          validationErrors[err.path] = err.message;
        }
      });
      setFormErrors(validationErrors);

      let el = document.querySelector('.text-error');
      if (!el) return;

      // Scroll to first known error into view
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } finally {
      setIsValidating(false);
    }

    // submitForm();
    // logger.track('setupPayment', {
    //   Journey: 'Tenant Journey',
    // });
  };

  const handleSubmit = async () => {
    if (IS_TEST_ENV) {
      return navigate(`/${nameUrl}/success`);
    }
    await updatePaymentDetails({
      ...data,
      orderId,
      userId,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/${nameUrl}/success`);
    }

    if (errorUpdating) {
      const errors = errorUpdating.data?.errors;

      errors
        ? Object.keys(errors).forEach(key => {
            setFormErrors({ [key]: errors[key] });
          })
        : toast.error('Something went wrong. Please try again.');

      setOpenModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, errorUpdating]);

  const disableSubmit = () => {
    return debitedName !== 'yes' || allowDebit !== 'yes';
  };

  return (
    <div className='relative pb-64 lg:pb-0'>
      <div className='flex flex-col'>
        <h4 className='text-p-strong sm:text-h4 text-typography'>
          Confirm your Direct Debit details
        </h4>
        <p className='text-typography-secondary mt-1 text-sm font-normal'>
          We collect money from your account each month by direct debit, meaning
          you just need to set up your payment once and we’ll automatically
          charge you each month on the same date.
        </p>

        <p className='text-typography-secondary mt-4 text-sm font-normal'>
          You won’t be charged until all bill payers have set up an account with
          Homebox. Your first payment will then be triggered within two weeks.
          Over the next two weeks our team will start setting up all your bills
          and will be in touch to book a welcome call.
        </p>

        <p className='text-typography-secondary mt-4 text-sm font-normal'>
          If you are going to miss your payment or do not have enough funds in
          your account, please contact our customer services team in advance to
          avoid any late payment fees.
        </p>

        <div className='flex flex-col space-y-6 pt-8'>
          <div className='w-full sm:max-w-xs'>
            <Label htmlFor='account_name'>Account Holder's Name</Label>
            <Input
              name='account_name'
              id='account_name'
              value={data.account_name}
              onChange={handleChange}
              placeholder='Enter name here'
              type='text'
            />
            <FormErrorMessage errors={formErrors} name='account_name' />
          </div>

          <div className='w-full sm:max-w-xs'>
            <Label htmlFor='account_number'>Bank Account Number</Label>
            <Input
              name='account_number'
              value={data.account_number}
              onChange={handleChange}
              id='account_number'
              placeholder='Enter account number here'
              type='text'
            />
            <FormErrorMessage errors={formErrors} name='account_number' />
          </div>

          <div className='w-full sm:max-w-xs'>
            <Label htmlFor='sort_code'>Sort code</Label>
            <Input
              name='sort_code'
              value={data.sort_code}
              onChange={handleChange}
              id='sort_code'
              placeholder='Enter code here'
              type='text'
            />
            <FormErrorMessage errors={formErrors} name='sort_code' />
          </div>

          <div className='w-full sm:max-w-xs'>
            {/* <Label htmlFor='bank-address'>Bank registered address</Label>
          <Input
            id='bank-address'
            placeholder='Enter address here'
            type='text'
          /> */}
            <PostCodeInput
              defaultPostCode={data?.billing_address?.postcode}
              defaultAddress={data?.billing_address}
              onPostCodeChange={val => {
                setData(prevData => ({
                  ...prevData,
                  billing_address: {
                    ...prevData.billing_address,
                    postcode: val,
                  },
                }));
              }}
              onAddressSelectChange={val => {
                setData(prevData => ({
                  ...prevData,
                  billing_address: val,
                }));
              }}
              customAddress={data?.billing_address}
              setCustomAddress={val => {
                setData(prevData => ({
                  ...prevData,
                  billing_address: val,
                }));
              }}
              labelPrefix={'Billing'}
              ignoreStyle
            />
            <span className='flex flex-col space-y-2 text-xs'>
              <FormErrorMessage
                errors={formErrors}
                name='billing_address.postcode'
              />
              <FormErrorMessage
                errors={formErrors}
                name='billing_address.line1'
              />
              <FormErrorMessage
                errors={formErrors}
                name='billing_address.city'
              />
            </span>
          </div>

          <fieldset>
            <legend className='text-typography text-sm'>
              The account to be debited is in my name
            </legend>
            <RadioGroup
              defaultValue={debitedName}
              onValueChange={val => setDebitedName(val)}
              value={debitedName}
              name='debited_name'
              className='mt-2 flex w-full space-x-2 sm:max-w-sm'
            >
              <Label
                htmlFor='debited-name-yes'
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem
                  value='yes'
                  id='debited-name-yes'
                  className='sr-only'
                />
                Yes
              </Label>

              <Label
                htmlFor='debited-name-no'
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem
                  value='no'
                  id='debited-name-no'
                  className='sr-only'
                />
                No
              </Label>
            </RadioGroup>
            <div className='h-4'>
              {debitedName === 'no' && (
                <span className='text-error text-xs'>
                  Unfortunately, you cannot setup a DD mandate on this account.
                  Please select another payment option
                </span>
              )}
            </div>
          </fieldset>

          <fieldset>
            <legend className='text-typography text-sm'>
              I am the only person required to allow debits on this account
            </legend>
            <RadioGroup
              defaultValue={allowDebit}
              onValueChange={val => setAllowDebit(val)}
              value={allowDebit}
              // orientation='horizontal'
              className='mt-2 flex w-full space-x-2 sm:max-w-sm'
              name='allow_debit'
            >
              <Label
                htmlFor='allow-debits-yes'
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem
                  value='yes'
                  id='allow-debits-yes'
                  className='sr-only'
                />
                Yes
              </Label>

              <Label
                htmlFor='allow-debits-no'
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem
                  value='no'
                  id='allow-debits-no'
                  className='sr-only'
                />
                No
              </Label>
            </RadioGroup>
            <div className='h-4'>
              {allowDebit === 'no' && (
                <span className='text-error text-xs'>
                  Unfortunately, you cannot setup a DD mandate on this account.
                  Please select another payment option
                </span>
              )}
            </div>
          </fieldset>
        </div>

        <DirectDebitConfirmation
          open={openModal}
          setOpen={setOpenModal}
          submitForm={handleSubmit}
          isLoading={isUpdating}
          paymentDetails={{ ...data, email: values?.email }}
        />

        <div className='mx-auto mt-8 hidden w-full max-w-sm flex-col space-y-4 lg:flex'>
          <Button
            variant='primary'
            size={'cta'}
            className='font-semibold'
            id='setup-payment'
            endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
            isLoading={isValidating}
            onClick={proceedToNextStep}
            disabled={disableSubmit()}
          >
            Continue
          </Button>
        </div>
      </div>

      <MobileNavControl
        serviceBill={serviceBill}
        rate={rate}
        setRate={setRate}
        coupon={coupon}
        setCoupon={setCoupon}
        couponApplied={couponApplied}
        setCouponApplied={setCouponApplied}
      >
        <div className='mx-auto flex w-full max-w-sm flex-col'>
          <Button
            variant='primary'
            size={'cta'}
            className='font-semibold'
            endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
            isLoading={isValidating}
            onClick={proceedToNextStep}
            disabled={disableSubmit()}
          >
            Continue
          </Button>
        </div>
      </MobileNavControl>
    </div>
  );
}
