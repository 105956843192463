import React from 'react';
import { useGetPartnerPublicTokenQuery } from '../../app/services';
import { useParams } from 'react-router-dom';
import HomeboxLogo from '../../assets/homebox.svg';

export default function Logo(props) {
  const { nameUrl } = useParams();

  const { data: partner } = useGetPartnerPublicTokenQuery(
    {
      partnerNameUrl: nameUrl,
    },
    { skip: !nameUrl }
  );

  let logos = [HomeboxLogo];

  if (partner?.branding === 'co-branded') {
    logos.push(partner?.image_path);
  }

  if (partner?.branding === 'white-label') {
    logos = [partner?.image_path];
  }

  return (
    <>
      {partner &&
        logos.map((logo, index) => {
          return (
            <img
              key={index}
              src={logo}
              className='h-10 w-60 object-contain'
              {...props}
              alt={`${partner?.name ?? 'Homebox'} logo`}
            />
          );
        })}
    </>
  );
}
