import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPartnerPublicTokenQuery } from '../app/services';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../app/features/partners/partnerAuthSlice';

const useLoadPartner = (loadPartner = true) => {
  const { nameUrl } = useParams();
  const {
    data: partner,
    isLoading,
    isError: partnerError,
    isSuccess: partnerSuccess,
  } = useGetPartnerPublicTokenQuery(
    {
      partnerNameUrl: nameUrl || 'homebox',
    },
    { skip: !(nameUrl && loadPartner) }
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (partnerSuccess && partner?.public_token) {
      dispatch(
        setCredentials({ partner, partnerPublicToken: partner?.public_token })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameUrl, partner, partnerSuccess, loadPartner]);

  return {
    partner,
    nameUrl,
    isLoading,
    partnerError,
  };
};

export default useLoadPartner;
