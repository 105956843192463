import * as React from 'react';
import { LogoProps } from './Logo';

export const HomeboxDefault = (
  props: React.JSX.IntrinsicAttributes &
    React.SVGProps<SVGSVGElement> &
    LogoProps
) => {
  return (
    <>
      {props.dark ? (
        <svg
          width='174'
          height='52'
          viewBox='0 0 174 52'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          {...props}
        >
          <path
            d='M14.8674 42C11.0749 42 8 38.9347 8 35.1464V10H14.7083C18.5891 10 21.7347 13.1393 21.7347 17.0123V35.1499C21.7347 38.9347 18.6598 42 14.8674 42Z'
            fill='#E56054'
          />
          <path
            d='M38.0447 42H31.0891C27.3465 42 24.31 38.9968 24.31 35.2953V23.8378H31.1774C34.9694 23.8378 38.0447 26.8794 38.0447 30.6299V42Z'
            fill='#E56054'
          />
          <path
            d='M45.0447 23.3931H49.9074V29.9785C50.3414 29.4126 50.8884 28.9092 51.5412 28.4753C52.194 28.0414 53.0092 27.8227 53.9796 27.8227C55.4652 27.8227 56.6191 28.267 57.4484 29.1488C58.2741 30.034 58.6905 31.2525 58.6905 32.8042V41.6704H53.8279V34.3386C53.8279 33.5888 53.6514 33.0195 53.2986 32.6341C52.9457 32.2488 52.4869 32.0579 51.9153 32.0579C51.3295 32.0579 50.846 32.2488 50.472 32.6341C50.0944 33.0195 49.9074 33.5853 49.9074 34.3386V41.6773H45.0447V23.3931Z'
            fill='white'
          />
          <path
            d='M68.2111 42C67.1066 42 66.0938 41.816 65.1693 41.448C64.2447 41.0801 63.4437 40.5767 62.7626 39.9345C62.0851 39.2923 61.5558 38.5459 61.1853 37.6919C60.8112 36.8414 60.6242 35.9319 60.6242 34.9633V34.9147C60.6242 33.9462 60.8148 33.0332 61.1959 32.1723C61.577 31.3113 62.1063 30.5615 62.7873 29.9193C63.4649 29.2771 64.273 28.7668 65.2046 28.3918C66.1362 28.0169 67.156 27.8295 68.2605 27.8295C69.365 27.8295 70.3778 28.0169 71.3023 28.3918C72.2268 28.7668 73.0279 29.2736 73.7089 29.9054C74.3865 30.5407 74.9122 31.2836 75.2863 32.1341C75.6604 32.9846 75.8474 33.8941 75.8474 34.8627V34.9113C75.8474 35.8798 75.6568 36.7928 75.2757 37.6537C74.8946 38.5146 74.3618 39.2645 73.6842 39.9067C73.0067 40.5489 72.1986 41.0592 71.267 41.4342C70.3319 41.8125 69.3156 42 68.2111 42ZM68.2605 37.9419C68.7016 37.9419 69.1003 37.862 69.4568 37.7058C69.8132 37.5496 70.1131 37.3343 70.3601 37.0601C70.6071 36.7859 70.7977 36.4665 70.9318 36.1054C71.0659 35.7409 71.1365 35.3591 71.1365 34.9633V34.9147C71.1365 34.5016 71.0623 34.1128 70.9212 33.7483C70.7765 33.3838 70.5718 33.0644 70.3107 32.7937C70.0461 32.5194 69.7391 32.3007 69.3826 32.1341C69.0262 31.9675 68.6345 31.8841 68.2111 31.8841C67.77 31.8841 67.3712 31.964 67.0148 32.1202C66.6584 32.2764 66.3585 32.4916 66.1115 32.7659C65.8644 33.0401 65.6739 33.3595 65.5398 33.724C65.4022 34.0885 65.3351 34.4704 65.3351 34.8661V34.9147C65.3351 35.3278 65.4057 35.7166 65.5504 36.0811C65.6951 36.4457 65.8997 36.765 66.1609 37.0358C66.4255 37.31 66.7325 37.5287 67.0889 37.6954C67.4489 37.862 67.837 37.9419 68.2605 37.9419Z'
            fill='white'
          />
          <path
            d='M77.8836 28.1281H82.7463V29.9819C83.1874 29.416 83.7378 28.9127 84.4013 28.4787C85.0611 28.0448 85.8763 27.8261 86.8432 27.8261C87.7607 27.8261 88.5582 28.017 89.2357 28.4023C89.9132 28.7877 90.439 29.3292 90.8131 30.0305C91.4059 29.3466 92.0693 28.8085 92.7998 28.4162C93.5302 28.024 94.4054 27.8261 95.4217 27.8261C96.9002 27.8261 98.0541 28.2565 98.8834 29.1175C99.7162 29.9784 100.133 31.2003 100.133 32.7868V41.6772H95.2699V34.3108C95.2699 33.5609 95.1005 32.9951 94.7653 32.6202C94.4265 32.2453 93.9713 32.0578 93.3997 32.0578C92.8245 32.0578 92.3551 32.2453 91.9846 32.6202C91.6141 32.9951 91.4271 33.5609 91.4271 34.3108V41.6738H86.5644V34.3108C86.5644 33.5609 86.395 32.9951 86.0598 32.6202C85.7246 32.2453 85.2658 32.0578 84.6941 32.0578C84.1225 32.0578 83.6532 32.2453 83.2897 32.6202C82.9262 32.9951 82.7463 33.5609 82.7463 34.3108V41.6738H77.8836V28.1281Z'
            fill='white'
          />
          <path
            d='M109.424 42C108.337 42 107.345 41.8299 106.446 41.4862C105.546 41.1425 104.769 40.667 104.117 40.0594C103.464 39.4485 102.956 38.7125 102.589 37.8447C102.225 36.9768 102.042 36.0152 102.042 34.9633V34.9147C102.042 33.9288 102.211 33.0089 102.55 32.148C102.889 31.287 103.369 30.5372 103.99 29.895C104.611 29.2528 105.341 28.7459 106.192 28.3814C107.038 28.0135 107.974 27.8295 108.993 27.8295C110.183 27.8295 111.213 28.0308 112.088 28.43C112.963 28.8293 113.683 29.3708 114.251 30.0443C114.819 30.7212 115.243 31.5058 115.525 32.3979C115.804 33.2901 115.945 34.2378 115.945 35.2411C115.945 35.3903 115.945 35.55 115.945 35.7166C115.945 35.8833 115.938 36.0499 115.92 36.2165H106.707C106.894 36.9664 107.247 37.5287 107.762 37.8933C108.281 38.2612 108.919 38.4452 109.685 38.4452C110.26 38.4452 110.793 38.3376 111.277 38.1189C111.76 37.9037 112.247 37.5531 112.741 37.067L115.412 39.2471C114.749 40.0837 113.93 40.7503 112.956 41.2502C111.975 41.7501 110.8 42 109.424 42ZM111.411 33.7865C111.326 33.0367 111.072 32.4396 110.648 31.9952C110.225 31.5544 109.681 31.3322 109.018 31.3322C108.372 31.3322 107.847 31.5544 107.441 31.9952C107.035 32.4361 106.763 33.0332 106.626 33.7865H111.411Z'
            fill='white'
          />
          <path
            d='M127.057 41.9759C126.531 41.9759 126.062 41.9204 125.649 41.8127C125.233 41.7051 124.859 41.5628 124.52 41.3857C124.181 41.2122 123.878 41.0004 123.606 40.7609C123.334 40.5179 123.091 40.2714 122.869 40.0215V41.6739H118.006V23.3931H122.869L122.844 29.9299C123.08 29.6452 123.334 29.3779 123.606 29.128C123.878 28.878 124.178 28.6559 124.509 28.4649C124.841 28.274 125.215 28.1178 125.628 28.0032C126.045 27.8852 126.507 27.8297 127.015 27.8297C127.83 27.8297 128.606 27.9928 129.344 28.3191C130.081 28.6455 130.731 29.1072 131.292 29.7077C131.853 30.3083 132.298 31.0442 132.629 31.9121C132.961 32.78 133.127 33.7555 133.127 34.842V34.8906C133.127 35.9911 132.961 36.9805 132.633 37.8587C132.301 38.7335 131.863 39.4799 131.313 40.0874C130.762 40.6984 130.12 41.1636 129.383 41.4899C128.642 41.8127 127.869 41.9759 127.057 41.9759ZM125.55 37.9941C126.344 37.9941 127.011 37.7095 127.551 37.1436C128.091 36.5778 128.363 35.8314 128.363 34.9149V34.8663C128.363 33.9637 128.091 33.2313 127.551 32.6619C127.011 32.0961 126.344 31.8114 125.55 31.8114C125.162 31.8114 124.799 31.8878 124.46 32.0371C124.121 32.1864 123.825 32.4016 123.574 32.6758C123.32 32.9501 123.123 33.2729 122.978 33.6409C122.833 34.0089 122.763 34.4185 122.763 34.8663V34.9149C122.763 35.3662 122.833 35.7793 122.978 36.1542C123.123 36.5292 123.32 36.8555 123.574 37.1297C123.828 37.404 124.125 37.6192 124.46 37.7685C124.799 37.9178 125.162 37.9941 125.55 37.9941Z'
            fill='white'
          />
          <path
            d='M142.238 42C141.133 42 140.121 41.816 139.196 41.448C138.272 41.0801 137.47 40.5767 136.789 39.9345C136.112 39.2923 135.583 38.5459 135.212 37.6919C134.838 36.8414 134.651 35.9319 134.651 34.9633V34.9147C134.651 33.9462 134.842 33.0332 135.223 32.1723C135.604 31.3113 136.133 30.5615 136.814 29.9193C137.492 29.2771 138.3 28.7668 139.231 28.3918C140.163 28.0169 141.183 27.8295 142.287 27.8295C143.392 27.8295 144.405 28.0169 145.329 28.3918C146.254 28.7668 147.055 29.2736 147.736 29.9054C148.413 30.5407 148.939 31.2836 149.313 32.1341C149.687 32.9846 149.874 33.8941 149.874 34.8627V34.9113C149.874 35.8798 149.684 36.7928 149.302 37.6537C148.921 38.5146 148.389 39.2645 147.711 39.9067C147.033 40.5489 146.225 41.0592 145.294 41.4342C144.359 41.8125 143.342 42 142.238 42ZM142.287 37.9419C142.728 37.9419 143.127 37.862 143.484 37.7058C143.84 37.5496 144.14 37.3343 144.387 37.0601C144.634 36.7859 144.824 36.4665 144.959 36.1054C145.093 35.7409 145.163 35.3591 145.163 34.9633V34.9147C145.163 34.5016 145.089 34.1128 144.948 33.7483C144.803 33.3838 144.599 33.0644 144.337 32.7937C144.073 32.5194 143.766 32.3007 143.409 32.1341C143.053 31.9675 142.661 31.8841 142.238 31.8841C141.797 31.8841 141.398 31.964 141.042 32.1202C140.685 32.2764 140.385 32.4916 140.138 32.7659C139.891 33.0401 139.701 33.3595 139.567 33.724C139.429 34.0885 139.362 34.4704 139.362 34.8661V34.9147C139.362 35.3278 139.432 35.7166 139.577 36.0811C139.722 36.4457 139.927 36.765 140.188 37.0358C140.452 37.31 140.759 37.5287 141.116 37.6954C141.476 37.862 141.864 37.9419 142.287 37.9419Z'
            fill='white'
          />
          <path
            d='M155.45 34.8386L150.894 28.1282H156.113L158.149 31.4574L160.185 28.1282H165.302L160.771 34.8143L165.404 41.6774H160.21L158.072 38.1955L155.908 41.6774H150.816L155.45 34.8386Z'
            fill='white'
          />
        </svg>
      ) : (
        <svg
          width='174'
          height='52'
          viewBox='0 0 174 52'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          {...props}
        >
          <path
            d='M14.8663 42C11.0745 42 8 38.9347 8 35.1464V10H14.7073C18.5874 10 21.7326 13.1393 21.7326 17.0123V35.1499C21.7326 38.9347 18.6581 42 14.8663 42Z'
            fill='#E56054'
          />
          <path
            d='M38.04 42H31.0855C27.3434 42 24.3074 38.9968 24.3074 35.2953V23.8378H31.1737C34.9652 23.8378 38.04 26.8794 38.04 30.6299V42Z'
            fill='#E56054'
          />
          <path
            d='M45.04 23.3931H49.9027V29.9785C50.3367 29.4126 50.8837 28.9092 51.5365 28.4753C52.1893 28.0414 53.0045 27.8227 53.9749 27.8227C55.4605 27.8227 56.6144 28.267 57.4437 29.1488C58.2694 30.034 58.6858 31.2525 58.6858 32.8042V41.6704H53.8232V34.3386C53.8232 33.5888 53.6467 33.0195 53.2938 32.6341C52.941 32.2488 52.4822 32.0579 51.9106 32.0579C51.3248 32.0579 50.8413 32.2488 50.4673 32.6341C50.0897 33.0195 49.9027 33.5853 49.9027 34.3386V41.6773H45.04V23.3931Z'
            fill='#202B4C'
          />
          <path
            d='M68.2064 42C67.1019 42 66.0891 41.816 65.1646 41.448C64.24 41.0801 63.439 40.5767 62.7579 39.9345C62.0804 39.2923 61.5511 38.5459 61.1806 37.6919C60.8065 36.8414 60.6195 35.9319 60.6195 34.9633V34.9147C60.6195 33.9462 60.81 33.0332 61.1912 32.1723C61.5723 31.3113 62.1016 30.5615 62.7826 29.9193C63.4602 29.2771 64.2683 28.7668 65.1999 28.3918C66.1315 28.0169 67.1513 27.8295 68.2558 27.8295C69.3603 27.8295 70.373 28.0169 71.2976 28.3918C72.2221 28.7668 73.0232 29.2736 73.7042 29.9054C74.3818 30.5407 74.9075 31.2836 75.2816 32.1341C75.6556 32.9846 75.8427 33.8941 75.8427 34.8627V34.9113C75.8427 35.8798 75.6521 36.7928 75.271 37.6537C74.8899 38.5146 74.357 39.2645 73.6795 39.9067C73.002 40.5489 72.1939 41.0592 71.2623 41.4342C70.3272 41.8125 69.3109 42 68.2064 42ZM68.2558 37.9419C68.6969 37.9419 69.0956 37.862 69.452 37.7058C69.8084 37.5496 70.1084 37.3343 70.3554 37.0601C70.6024 36.7859 70.793 36.4665 70.9271 36.1054C71.0612 35.7409 71.1317 35.3591 71.1317 34.9633V34.9147C71.1317 34.5016 71.0576 34.1128 70.9165 33.7483C70.7718 33.3838 70.5671 33.0644 70.306 32.7937C70.0413 32.5194 69.7343 32.3007 69.3779 32.1341C69.0215 31.9675 68.6298 31.8841 68.2064 31.8841C67.7653 31.8841 67.3665 31.964 67.0101 32.1202C66.6537 32.2764 66.3538 32.4916 66.1067 32.7659C65.8597 33.0401 65.6692 33.3595 65.5351 33.724C65.3975 34.0885 65.3304 34.4704 65.3304 34.8661V34.9147C65.3304 35.3278 65.401 35.7166 65.5457 36.0811C65.6904 36.4457 65.895 36.765 66.1561 37.0358C66.4208 37.31 66.7278 37.5287 67.0842 37.6954C67.4442 37.862 67.8323 37.9419 68.2558 37.9419Z'
            fill='#202B4C'
          />
          <path
            d='M77.8789 28.1281H82.7415V29.9819C83.1826 29.416 83.7331 28.9127 84.3965 28.4787C85.0564 28.0448 85.8716 27.8261 86.8385 27.8261C87.7559 27.8261 88.5535 28.017 89.231 28.4023C89.9085 28.7877 90.4343 29.3292 90.8083 30.0305C91.4012 29.3466 92.0646 28.8085 92.7951 28.4162C93.5255 28.024 94.4007 27.8261 95.4169 27.8261C96.8955 27.8261 98.0494 28.2565 98.8787 29.1175C99.7115 29.9784 100.128 31.2003 100.128 32.7868V41.6772H95.2652V34.3108C95.2652 33.5609 95.0958 32.9951 94.7606 32.6202C94.4218 32.2453 93.9666 32.0578 93.3949 32.0578C92.8198 32.0578 92.3504 32.2453 91.9799 32.6202C91.6094 32.9951 91.4224 33.5609 91.4224 34.3108V41.6738H86.5597V34.3108C86.5597 33.5609 86.3903 32.9951 86.0551 32.6202C85.7198 32.2453 85.2611 32.0578 84.6894 32.0578C84.1178 32.0578 83.6484 32.2453 83.285 32.6202C82.9215 32.9951 82.7415 33.5609 82.7415 34.3108V41.6738H77.8789V28.1281Z'
            fill='#202B4C'
          />
          <path
            d='M109.419 42C108.332 42 107.341 41.8299 106.441 41.4862C105.541 41.1425 104.765 40.667 104.112 40.0594C103.459 39.4485 102.951 38.7125 102.584 37.8447C102.22 36.9768 102.037 36.0152 102.037 34.9633V34.9147C102.037 33.9288 102.206 33.0089 102.545 32.148C102.884 31.287 103.364 30.5372 103.985 29.895C104.606 29.2528 105.336 28.7459 106.187 28.3814C107.034 28.0135 107.969 27.8295 108.989 27.8295C110.178 27.8295 111.208 28.0308 112.083 28.43C112.959 28.8293 113.678 29.3708 114.247 30.0443C114.815 30.7212 115.238 31.5058 115.52 32.3979C115.799 33.2901 115.94 34.2378 115.94 35.2411C115.94 35.3903 115.94 35.55 115.94 35.7166C115.94 35.8833 115.933 36.0499 115.916 36.2165H106.702C106.889 36.9664 107.242 37.5287 107.757 37.8933C108.276 38.2612 108.915 38.4452 109.68 38.4452C110.255 38.4452 110.788 38.3376 111.272 38.1189C111.755 37.9037 112.242 37.5531 112.736 37.067L115.408 39.2471C114.744 40.0837 113.925 40.7503 112.951 41.2502C111.97 41.7501 110.795 42 109.419 42ZM111.406 33.7865C111.321 33.0367 111.067 32.4396 110.644 31.9952C110.22 31.5544 109.677 31.3322 109.013 31.3322C108.368 31.3322 107.842 31.5544 107.436 31.9952C107.03 32.4361 106.758 33.0332 106.621 33.7865H111.406Z'
            fill='#202B4C'
          />
          <path
            d='M127.053 41.9759C126.527 41.9759 126.057 41.9204 125.645 41.8127C125.228 41.7051 124.854 41.5628 124.515 41.3857C124.177 41.2122 123.873 41.0004 123.601 40.7609C123.33 40.5179 123.086 40.2714 122.864 40.0215V41.6739H118.001V23.3931H122.864L122.839 29.9299C123.076 29.6452 123.33 29.3779 123.601 29.128C123.873 28.878 124.173 28.6559 124.505 28.4649C124.836 28.274 125.211 28.1178 125.623 28.0032C126.04 27.8852 126.502 27.8297 127.01 27.8297C127.825 27.8297 128.602 27.9928 129.339 28.3191C130.077 28.6455 130.726 29.1072 131.287 29.7077C131.848 30.3083 132.293 31.0442 132.625 31.9121C132.956 32.78 133.122 33.7555 133.122 34.842V34.8906C133.122 35.9911 132.956 36.9805 132.628 37.8587C132.296 38.7335 131.859 39.4799 131.308 40.0874C130.758 40.6984 130.116 41.1636 129.378 41.4899C128.637 41.8127 127.864 41.9759 127.053 41.9759ZM125.546 37.9941C126.34 37.9941 127.007 37.7095 127.547 37.1436C128.086 36.5778 128.358 35.8314 128.358 34.9149V34.8663C128.358 33.9637 128.086 33.2313 127.547 32.6619C127.007 32.0961 126.34 31.8114 125.546 31.8114C125.158 31.8114 124.794 31.8878 124.455 32.0371C124.117 32.1864 123.82 32.4016 123.57 32.6758C123.316 32.9501 123.118 33.2729 122.973 33.6409C122.829 34.0089 122.758 34.4185 122.758 34.8663V34.9149C122.758 35.3662 122.829 35.7793 122.973 36.1542C123.118 36.5292 123.316 36.8555 123.57 37.1297C123.824 37.404 124.12 37.6192 124.455 37.7685C124.794 37.9178 125.158 37.9941 125.546 37.9941Z'
            fill='#202B4C'
          />
          <path
            d='M142.233 42C141.129 42 140.116 41.816 139.191 41.448C138.267 41.0801 137.466 40.5767 136.785 39.9345C136.107 39.2923 135.578 38.5459 135.207 37.6919C134.833 36.8414 134.646 35.9319 134.646 34.9633V34.9147C134.646 33.9462 134.837 33.0332 135.218 32.1723C135.599 31.3113 136.128 30.5615 136.809 29.9193C137.487 29.2771 138.295 28.7668 139.227 28.3918C140.158 28.0169 141.178 27.8295 142.283 27.8295C143.387 27.8295 144.4 28.0169 145.324 28.3918C146.249 28.7668 147.05 29.2736 147.731 29.9054C148.409 30.5407 148.934 31.2836 149.308 32.1341C149.682 32.9846 149.869 33.8941 149.869 34.8627V34.9113C149.869 35.8798 149.679 36.7928 149.298 37.6537C148.917 38.5146 148.384 39.2645 147.706 39.9067C147.029 40.5489 146.221 41.0592 145.289 41.4342C144.354 41.8125 143.338 42 142.233 42ZM142.283 37.9419C142.724 37.9419 143.122 37.862 143.479 37.7058C143.835 37.5496 144.135 37.3343 144.382 37.0601C144.629 36.7859 144.82 36.4665 144.954 36.1054C145.088 35.7409 145.159 35.3591 145.159 34.9633V34.9147C145.159 34.5016 145.084 34.1128 144.943 33.7483C144.799 33.3838 144.594 33.0644 144.333 32.7937C144.068 32.5194 143.761 32.3007 143.405 32.1341C143.048 31.9675 142.657 31.8841 142.233 31.8841C141.792 31.8841 141.393 31.964 141.037 32.1202C140.68 32.2764 140.381 32.4916 140.134 32.7659C139.887 33.0401 139.696 33.3595 139.562 33.724C139.424 34.0885 139.357 34.4704 139.357 34.8661V34.9147C139.357 35.3278 139.428 35.7166 139.572 36.0811C139.717 36.4457 139.922 36.765 140.183 37.0358C140.448 37.31 140.755 37.5287 141.111 37.6954C141.471 37.862 141.859 37.9419 142.283 37.9419Z'
            fill='#202B4C'
          />
          <path
            d='M155.445 34.8386L150.889 28.1282H156.108L158.144 31.4574L160.181 28.1282H165.297L160.766 34.8143L165.4 41.6774H160.205L158.067 38.1955L155.904 41.6774H150.812L155.445 34.8386Z'
            fill='#202B4C'
          />
        </svg>
      )}
    </>
  );
};

export const HomeboxPay = (
  props: React.JSX.IntrinsicAttributes &
    React.SVGProps<SVGSVGElement> &
    LogoProps
) => {
  return (
    <>
      {props.dark ? (
        <svg
          width='191'
          height='41'
          viewBox='0 0 191 41'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          {...props}
        >
          <path
            d='M14.235 34.3868C10.7918 34.3868 8 31.6677 8 28.3071V6H14.0906C17.6141 6 20.47 8.78486 20.47 12.2206V28.3102C20.47 31.6677 17.6782 34.3868 14.235 34.3868Z'
            fill='#E56054'
          />
          <path
            d='M35.278 34.3868H28.9628C25.5648 34.3868 22.808 31.7227 22.808 28.4391V18.2753H29.043C32.4859 18.2753 35.278 20.9735 35.278 24.3005V34.3868Z'
            fill='#E56054'
          />
          <path
            d='M42.278 18.3868H46.6929V24.2287C47.087 23.7267 47.5836 23.2802 48.1763 22.8952C48.769 22.5103 49.5091 22.3163 50.3902 22.3163C51.739 22.3163 52.7867 22.7105 53.5396 23.4927C54.2893 24.2779 54.6673 25.3588 54.6673 26.7354V34.6004H50.2524V28.0965C50.2524 27.4313 50.0922 26.9263 49.7718 26.5845C49.4514 26.2426 49.0349 26.0733 48.5159 26.0733C47.9841 26.0733 47.5451 26.2426 47.2055 26.5845C46.8627 26.9263 46.6929 27.4283 46.6929 28.0965V34.6066H42.278V18.3868Z'
            fill='white'
          />
          <path
            d='M63.311 34.8928C62.3082 34.8928 61.3887 34.7296 60.5493 34.4031C59.7098 34.0767 58.9826 33.6302 58.3642 33.0605C57.7491 32.4908 57.2685 31.8287 56.9321 31.0711C56.5925 30.3167 56.4227 29.5098 56.4227 28.6506V28.6075C56.4227 27.7483 56.5957 26.9384 56.9417 26.1747C57.2877 25.411 57.7683 24.7458 58.3867 24.1761C59.0018 23.6064 59.7355 23.1537 60.5813 22.8211C61.4271 22.4886 62.353 22.3223 63.3558 22.3223C64.3586 22.3223 65.2781 22.4886 66.1176 22.8211C66.957 23.1537 67.6842 23.6033 68.3026 24.1638C68.9177 24.7274 69.3951 25.3864 69.7347 26.1408C70.0743 26.8953 70.2441 27.7022 70.2441 28.5613V28.6045C70.2441 29.4636 70.0711 30.2735 69.7251 31.0373C69.3791 31.801 68.8953 32.4661 68.2802 33.0359C67.665 33.6056 66.9313 34.0582 66.0855 34.3908C65.2365 34.7265 64.3138 34.8928 63.311 34.8928ZM63.3558 31.2929C63.7563 31.2929 64.1183 31.222 64.4419 31.0834C64.7655 30.9449 65.0379 30.7539 65.2621 30.5107C65.4864 30.2674 65.6594 29.9841 65.7811 29.6638C65.9029 29.3404 65.967 29.0017 65.967 28.6506V28.6075C65.967 28.2411 65.8997 27.8962 65.7715 27.5728C65.6402 27.2495 65.4544 26.9662 65.2173 26.726C64.977 26.4827 64.6982 26.2887 64.3747 26.1408C64.0511 25.993 63.6954 25.9191 63.311 25.9191C62.9105 25.9191 62.5485 25.99 62.2249 26.1285C61.9013 26.2671 61.629 26.458 61.4047 26.7013C61.1804 26.9446 61.0074 27.2279 60.8857 27.5513C60.7607 27.8746 60.6998 28.2134 60.6998 28.5644V28.6075C60.6998 28.974 60.7639 29.3189 60.8953 29.6422C61.0266 29.9656 61.2125 30.2489 61.4495 30.4891C61.6898 30.7324 61.9686 30.9264 62.2921 31.0742C62.6189 31.222 62.9714 31.2929 63.3558 31.2929Z'
            fill='white'
          />
          <path
            d='M72.0934 22.5871H76.5083V24.2316C76.9088 23.7296 77.4086 23.2831 78.0109 22.8982C78.6101 22.5132 79.3501 22.3192 80.228 22.3192C81.061 22.3192 81.7851 22.4886 82.4002 22.8304C83.0154 23.1722 83.4927 23.6527 83.8323 24.2747C84.3706 23.668 84.9729 23.1907 85.6361 22.8427C86.2993 22.4948 87.0939 22.3192 88.0166 22.3192C89.359 22.3192 90.4066 22.7011 91.1596 23.4648C91.9157 24.2285 92.2937 25.3125 92.2937 26.7198V34.6064H87.8788V28.0717C87.8788 27.4066 87.725 26.9046 87.4207 26.572C87.1131 26.2394 86.6998 26.0731 86.1808 26.0731C85.6585 26.0731 85.2324 26.2394 84.896 26.572C84.5596 26.9046 84.3898 27.4066 84.3898 28.0717V34.6034H79.9749V28.0717C79.9749 27.4066 79.8211 26.9046 79.5167 26.572C79.2124 26.2394 78.7959 26.0731 78.2768 26.0731C77.7578 26.0731 77.3317 26.2394 77.0017 26.572C76.6717 26.9046 76.5083 27.4066 76.5083 28.0717V34.6034H72.0934V22.5871Z'
            fill='white'
          />
          <path
            d='M100.729 34.8928C99.7421 34.8928 98.8419 34.7419 98.0249 34.437C97.2079 34.1322 96.503 33.7103 95.9103 33.1713C95.3176 32.6294 94.8563 31.9765 94.5231 31.2066C94.1931 30.4368 94.0265 29.5837 94.0265 28.6506V28.6075C94.0265 27.733 94.1802 26.9169 94.4878 26.1532C94.7954 25.3894 95.2311 24.7243 95.795 24.1546C96.3589 23.5849 97.0221 23.1353 97.7942 22.8119C98.5631 22.4855 99.4121 22.3223 100.338 22.3223C101.418 22.3223 102.353 22.5009 103.148 22.855C103.942 23.2092 104.596 23.6896 105.112 24.287C105.628 24.8875 106.012 25.5835 106.268 26.3749C106.521 27.1663 106.65 28.007 106.65 28.897C106.65 29.0294 106.65 29.1711 106.65 29.3189C106.65 29.4667 106.643 29.6145 106.627 29.7623H98.262C98.4318 30.4275 98.7521 30.9264 99.2199 31.2497C99.6909 31.5762 100.271 31.7394 100.966 31.7394C101.488 31.7394 101.972 31.6439 102.411 31.4499C102.85 31.259 103.292 30.948 103.741 30.5168L106.166 32.4507C105.564 33.1929 104.82 33.7842 103.936 34.2276C103.045 34.6711 101.978 34.8928 100.729 34.8928ZM102.533 27.6067C102.456 26.9415 102.225 26.4118 101.841 26.0177C101.456 25.6266 100.963 25.4295 100.36 25.4295C99.7742 25.4295 99.2968 25.6266 98.9284 26.0177C98.5599 26.4088 98.3132 26.9384 98.1883 27.6067H102.533Z'
            fill='white'
          />
          <path
            d='M116.739 34.8714C116.261 34.8714 115.835 34.8221 115.46 34.7267C115.082 34.6312 114.743 34.5049 114.435 34.3479C114.127 34.1939 113.852 34.0061 113.605 33.7936C113.359 33.578 113.137 33.3594 112.936 33.1377V34.6035H108.521V18.3868H112.936L112.913 24.1855C113.128 23.933 113.359 23.6959 113.605 23.4742C113.852 23.2525 114.124 23.0554 114.425 22.886C114.727 22.7166 115.066 22.578 115.441 22.4764C115.819 22.3717 116.239 22.3224 116.7 22.3224C117.44 22.3224 118.145 22.4672 118.815 22.7567C119.484 23.0461 120.074 23.4557 120.583 23.9885C121.093 24.5212 121.496 25.1741 121.797 25.9439C122.099 26.7138 122.249 27.5791 122.249 28.543V28.5861C122.249 29.5623 122.099 30.44 121.801 31.2191C121.5 31.9952 121.102 32.6572 120.602 33.1962C120.103 33.7382 119.52 34.1508 118.85 34.4403C118.177 34.7267 117.475 34.8714 116.739 34.8714ZM115.371 31.3392C116.091 31.3392 116.697 31.0867 117.187 30.5847C117.677 30.0828 117.924 29.4207 117.924 28.6077V28.5646C117.924 27.7639 117.677 27.1141 117.187 26.6091C116.697 26.1071 116.091 25.8546 115.371 25.8546C115.018 25.8546 114.688 25.9224 114.381 26.0548C114.073 26.1872 113.804 26.3781 113.576 26.6214C113.346 26.8647 113.166 27.1511 113.035 27.4775C112.904 27.804 112.839 28.1673 112.839 28.5646V28.6077C112.839 29.008 112.904 29.3745 113.035 29.7071C113.166 30.0397 113.346 30.3291 113.576 30.5724C113.807 30.8157 114.076 31.0066 114.381 31.1391C114.688 31.2715 115.018 31.3392 115.371 31.3392Z'
            fill='white'
          />
          <path
            d='M130.522 34.8928C129.519 34.8928 128.6 34.7296 127.76 34.4031C126.921 34.0767 126.193 33.6302 125.575 33.0605C124.96 32.4908 124.479 31.8287 124.143 31.0711C123.803 30.3167 123.634 29.5098 123.634 28.6506V28.6075C123.634 27.7483 123.807 26.9384 124.153 26.1747C124.499 25.411 124.979 24.7458 125.598 24.1761C126.213 23.6064 126.946 23.1537 127.792 22.8211C128.638 22.4886 129.564 22.3223 130.567 22.3223C131.57 22.3223 132.489 22.4886 133.328 22.8211C134.168 23.1537 134.895 23.6033 135.514 24.1638C136.129 24.7274 136.606 25.3864 136.946 26.1408C137.285 26.8953 137.455 27.7022 137.455 28.5613V28.6045C137.455 29.4636 137.282 30.2735 136.936 31.0373C136.59 31.801 136.106 32.4661 135.491 33.0359C134.876 33.6056 134.142 34.0582 133.296 34.3908C132.447 34.7265 131.525 34.8928 130.522 34.8928ZM130.567 31.2929C130.967 31.2929 131.329 31.222 131.653 31.0834C131.976 30.9449 132.249 30.7539 132.473 30.5107C132.697 30.2674 132.87 29.9841 132.992 29.6638C133.114 29.3404 133.178 29.0017 133.178 28.6506V28.6075C133.178 28.2411 133.111 27.8962 132.982 27.5728C132.851 27.2495 132.665 26.9662 132.428 26.726C132.188 26.4827 131.909 26.2887 131.586 26.1408C131.262 25.993 130.906 25.9191 130.522 25.9191C130.121 25.9191 129.759 25.99 129.436 26.1285C129.112 26.2671 128.84 26.458 128.616 26.7013C128.391 26.9446 128.218 27.2279 128.097 27.5513C127.972 27.8746 127.911 28.2134 127.911 28.5644V28.6075C127.911 28.974 127.975 29.3189 128.106 29.6422C128.238 29.9656 128.423 30.2489 128.66 30.4891C128.901 30.7324 129.179 30.9264 129.503 31.0742C129.83 31.222 130.182 31.2929 130.567 31.2929Z'
            fill='white'
          />
          <path
            d='M142.517 28.54L138.381 22.5873H143.119L144.968 25.5406L146.817 22.5873H151.462L147.348 28.5184L151.555 34.6066H146.839L144.898 31.5179L142.934 34.6066H138.31L142.517 28.54Z'
            fill='white'
          />
          <path
            d='M158.851 33.5846C159.656 33.5846 160.315 33.2703 160.831 32.6418C161.346 32.0006 161.604 31.0955 161.604 29.9264C161.604 28.883 161.353 28.0407 160.85 27.3996C160.347 26.7585 159.712 26.4379 158.945 26.4379C158.178 26.4379 157.512 26.7207 156.946 27.2864C156.393 27.8396 156.117 28.751 156.117 30.0207C156.117 31.2401 156.4 32.1389 156.965 32.7172C157.531 33.2955 158.16 33.5846 158.851 33.5846ZM156.117 25.2311V26.683C156.783 25.6145 157.77 25.0802 159.077 25.0802C160.347 25.0802 161.34 25.5453 162.057 26.4756C162.786 27.3933 163.15 28.5436 163.15 29.9264C163.15 31.3972 162.767 32.6041 162 33.5469C161.246 34.4772 160.259 34.9423 159.04 34.9423C157.782 34.9423 156.808 34.408 156.117 33.3395V38.5062H154.646V25.2311H156.117Z'
            fill='white'
          />
          <path
            d='M170.493 30.379H168.833C168.029 30.379 167.425 30.5361 167.023 30.8504C166.621 31.1647 166.42 31.5607 166.42 32.0384C166.42 32.5161 166.577 32.9058 166.891 33.2075C167.205 33.4966 167.664 33.6412 168.268 33.6412C168.947 33.6412 169.487 33.4526 169.889 33.0755C170.292 32.6858 170.493 32.1955 170.493 31.6047V30.379ZM165.552 27.2299V25.7402C166.269 25.3002 167.212 25.0802 168.381 25.0802C170.782 25.0802 171.983 26.2996 171.983 28.7384V34.7915H170.512V33.66C170.021 34.4772 169.129 34.8857 167.834 34.8857C166.941 34.8857 166.219 34.6217 165.665 34.0938C165.125 33.5658 164.855 32.8995 164.855 32.0949C164.855 31.1772 165.2 30.467 165.892 29.9641C166.596 29.4487 167.551 29.191 168.758 29.191H170.493V28.7007C170.493 27.9464 170.323 27.3807 169.984 27.0036C169.644 26.6139 169.072 26.419 168.268 26.419C167.249 26.419 166.344 26.6893 165.552 27.2299Z'
            fill='white'
          />
          <path
            d='M177.266 34.5463L173.4 25.2311H175.079L176.323 28.3801L178.001 32.7172C178.102 32.3904 178.605 30.9447 179.51 28.3801L180.622 25.2311H182.225L178.774 34.5463C178.284 35.8412 177.7 36.7526 177.021 37.2806C176.355 37.8085 175.588 38.0725 174.72 38.0725C174.393 38.0725 174.129 38.0411 173.928 37.9783V36.6394C174.117 36.6897 174.331 36.7148 174.569 36.7148C175.839 36.7148 176.738 35.992 177.266 34.5463Z'
            fill='white'
          />
        </svg>
      ) : (
        <svg
          width='191'
          height='41'
          viewBox='0 0 191 41'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          {...props}
        >
          <path
            d='M14.235 34.3868C10.7918 34.3868 8 31.6677 8 28.3071V6H14.0906C17.6141 6 20.47 8.78486 20.47 12.2206V28.3102C20.47 31.6677 17.6782 34.3868 14.235 34.3868Z'
            fill='#E56054'
          />
          <path
            d='M35.278 34.3868H28.9628C25.5648 34.3868 22.808 31.7227 22.808 28.4391V18.2753H29.043C32.4859 18.2753 35.278 20.9735 35.278 24.3005V34.3868Z'
            fill='#E56054'
          />
          <path
            d='M42.278 18.3868H46.6929V24.2287C47.087 23.7267 47.5836 23.2802 48.1763 22.8952C48.769 22.5103 49.5091 22.3163 50.3902 22.3163C51.739 22.3163 52.7867 22.7105 53.5396 23.4927C54.2893 24.2779 54.6673 25.3588 54.6673 26.7354V34.6004H50.2524V28.0965C50.2524 27.4313 50.0922 26.9263 49.7718 26.5845C49.4514 26.2426 49.0349 26.0733 48.5159 26.0733C47.9841 26.0733 47.5452 26.2426 47.2055 26.5845C46.8627 26.9263 46.6929 27.4283 46.6929 28.0965V34.6066H42.278V18.3868Z'
            fill='#202B4C'
          />
          <path
            d='M63.311 34.8928C62.3082 34.8928 61.3887 34.7296 60.5493 34.4031C59.7098 34.0767 58.9826 33.6302 58.3642 33.0605C57.7491 32.4908 57.2685 31.8287 56.9321 31.0711C56.5925 30.3167 56.4227 29.5098 56.4227 28.6506V28.6075C56.4227 27.7483 56.5957 26.9384 56.9417 26.1747C57.2877 25.411 57.7683 24.7458 58.3867 24.1761C59.0018 23.6064 59.7355 23.1537 60.5813 22.8211C61.4271 22.4886 62.353 22.3223 63.3558 22.3223C64.3586 22.3223 65.2782 22.4886 66.1176 22.8211C66.957 23.1537 67.6842 23.6033 68.3026 24.1638C68.9177 24.7274 69.3951 25.3864 69.7347 26.1408C70.0743 26.8953 70.2441 27.7022 70.2441 28.5613V28.6045C70.2441 29.4636 70.0711 30.2735 69.7251 31.0373C69.3791 31.801 68.8953 32.4661 68.2802 33.0359C67.665 33.6056 66.9313 34.0582 66.0855 34.3908C65.2365 34.7265 64.3138 34.8928 63.311 34.8928ZM63.3558 31.2929C63.7563 31.2929 64.1184 31.222 64.4419 31.0834C64.7655 30.9449 65.0379 30.7539 65.2621 30.5107C65.4864 30.2674 65.6594 29.9841 65.7812 29.6638C65.9029 29.3404 65.967 29.0017 65.967 28.6506V28.6075C65.967 28.2411 65.8997 27.8962 65.7715 27.5728C65.6402 27.2495 65.4544 26.9662 65.2173 26.726C64.977 26.4827 64.6983 26.2887 64.3747 26.1408C64.0511 25.993 63.6954 25.9191 63.311 25.9191C62.9105 25.9191 62.5485 25.99 62.2249 26.1285C61.9013 26.2671 61.629 26.458 61.4047 26.7013C61.1804 26.9446 61.0074 27.2279 60.8857 27.5513C60.7607 27.8746 60.6998 28.2134 60.6998 28.5644V28.6075C60.6998 28.974 60.7639 29.3189 60.8953 29.6422C61.0266 29.9656 61.2125 30.2489 61.4495 30.4891C61.6898 30.7324 61.9686 30.9264 62.2922 31.0742C62.6189 31.222 62.9714 31.2929 63.3558 31.2929Z'
            fill='#202B4C'
          />
          <path
            d='M72.0934 22.5871H76.5083V24.2316C76.9088 23.7296 77.4086 23.2831 78.0109 22.8982C78.6101 22.5132 79.3501 22.3192 80.228 22.3192C81.061 22.3192 81.7851 22.4886 82.4002 22.8304C83.0154 23.1722 83.4927 23.6527 83.8323 24.2747C84.3706 23.668 84.9729 23.1907 85.6361 22.8427C86.2993 22.4948 87.0939 22.3192 88.0166 22.3192C89.359 22.3192 90.4067 22.7011 91.1596 23.4648C91.9157 24.2285 92.2937 25.3125 92.2937 26.7198V34.6064H87.8788V28.0717C87.8788 27.4066 87.725 26.9046 87.4207 26.572C87.1131 26.2394 86.6998 26.0731 86.1808 26.0731C85.6585 26.0731 85.2324 26.2394 84.896 26.572C84.5596 26.9046 84.3898 27.4066 84.3898 28.0717V34.6034H79.9749V28.0717C79.9749 27.4066 79.8211 26.9046 79.5167 26.572C79.2124 26.2394 78.7959 26.0731 78.2769 26.0731C77.7578 26.0731 77.3317 26.2394 77.0017 26.572C76.6717 26.9046 76.5083 27.4066 76.5083 28.0717V34.6034H72.0934V22.5871Z'
            fill='#202B4C'
          />
          <path
            d='M100.729 34.8928C99.7421 34.8928 98.8419 34.7419 98.0249 34.437C97.2079 34.1322 96.503 33.7103 95.9103 33.1713C95.3176 32.6294 94.8563 31.9765 94.5231 31.2066C94.1931 30.4368 94.0265 29.5837 94.0265 28.6506V28.6075C94.0265 27.733 94.1803 26.9169 94.4878 26.1532C94.7954 25.3894 95.2311 24.7243 95.795 24.1546C96.3589 23.5849 97.0221 23.1353 97.7942 22.8119C98.5631 22.4855 99.4122 22.3223 100.338 22.3223C101.418 22.3223 102.353 22.5009 103.148 22.855C103.942 23.2092 104.596 23.6896 105.112 24.287C105.628 24.8875 106.012 25.5835 106.268 26.3749C106.522 27.1663 106.65 28.007 106.65 28.897C106.65 29.0294 106.65 29.1711 106.65 29.3189C106.65 29.4667 106.643 29.6145 106.627 29.7623H98.262C98.4318 30.4275 98.7522 30.9264 99.2199 31.2497C99.6909 31.5762 100.271 31.7394 100.966 31.7394C101.488 31.7394 101.972 31.6439 102.411 31.4499C102.85 31.259 103.292 30.948 103.741 30.5168L106.166 32.4507C105.564 33.1929 104.82 33.7842 103.936 34.2276C103.045 34.6711 101.978 34.8928 100.729 34.8928ZM102.533 27.6067C102.456 26.9415 102.225 26.4118 101.841 26.0177C101.456 25.6266 100.963 25.4295 100.36 25.4295C99.7742 25.4295 99.2968 25.6266 98.9284 26.0177C98.5599 26.4088 98.3132 26.9384 98.1883 27.6067H102.533Z'
            fill='#202B4C'
          />
          <path
            d='M116.739 34.8714C116.261 34.8714 115.835 34.8221 115.46 34.7267C115.082 34.6312 114.743 34.5049 114.435 34.3479C114.127 34.1939 113.852 34.0061 113.605 33.7936C113.359 33.578 113.137 33.3594 112.936 33.1377V34.6035H108.521V18.3868H112.936L112.913 24.1855C113.128 23.933 113.359 23.6959 113.605 23.4742C113.852 23.2525 114.124 23.0554 114.425 22.886C114.727 22.7166 115.066 22.578 115.441 22.4764C115.819 22.3717 116.239 22.3224 116.7 22.3224C117.44 22.3224 118.145 22.4672 118.815 22.7567C119.484 23.0461 120.074 23.4557 120.583 23.9885C121.093 24.5212 121.496 25.1741 121.797 25.9439C122.099 26.7138 122.249 27.5791 122.249 28.543V28.5861C122.249 29.5623 122.099 30.44 121.801 31.2191C121.5 31.9952 121.102 32.6572 120.602 33.1962C120.103 33.7382 119.52 34.1508 118.85 34.4403C118.177 34.7267 117.475 34.8714 116.739 34.8714ZM115.371 31.3392C116.091 31.3392 116.697 31.0867 117.187 30.5847C117.677 30.0828 117.924 29.4207 117.924 28.6077V28.5646C117.924 27.7639 117.677 27.1141 117.187 26.6091C116.697 26.1071 116.091 25.8546 115.371 25.8546C115.018 25.8546 114.688 25.9224 114.381 26.0548C114.073 26.1872 113.804 26.3781 113.576 26.6214C113.346 26.8647 113.166 27.1511 113.035 27.4775C112.904 27.804 112.84 28.1673 112.84 28.5646V28.6077C112.84 29.008 112.904 29.3745 113.035 29.7071C113.166 30.0397 113.346 30.3291 113.576 30.5724C113.807 30.8157 114.076 31.0066 114.381 31.1391C114.688 31.2715 115.018 31.3392 115.371 31.3392Z'
            fill='#202B4C'
          />
          <path
            d='M130.522 34.8928C129.519 34.8928 128.6 34.7296 127.76 34.4031C126.921 34.0767 126.193 33.6302 125.575 33.0605C124.96 32.4908 124.479 31.8287 124.143 31.0711C123.803 30.3167 123.634 29.5098 123.634 28.6506V28.6075C123.634 27.7483 123.807 26.9384 124.153 26.1747C124.499 25.411 124.979 24.7458 125.598 24.1761C126.213 23.6064 126.946 23.1537 127.792 22.8211C128.638 22.4886 129.564 22.3223 130.567 22.3223C131.57 22.3223 132.489 22.4886 133.328 22.8211C134.168 23.1537 134.895 23.6033 135.514 24.1638C136.129 24.7274 136.606 25.3864 136.946 26.1408C137.285 26.8953 137.455 27.7022 137.455 28.5613V28.6045C137.455 29.4636 137.282 30.2735 136.936 31.0373C136.59 31.801 136.106 32.4661 135.491 33.0359C134.876 33.6056 134.142 34.0582 133.296 34.3908C132.447 34.7265 131.525 34.8928 130.522 34.8928ZM130.567 31.2929C130.967 31.2929 131.329 31.222 131.653 31.0834C131.976 30.9449 132.249 30.7539 132.473 30.5107C132.697 30.2674 132.87 29.9841 132.992 29.6638C133.114 29.3404 133.178 29.0017 133.178 28.6506V28.6075C133.178 28.2411 133.111 27.8962 132.982 27.5728C132.851 27.2495 132.665 26.9662 132.428 26.726C132.188 26.4827 131.909 26.2887 131.586 26.1408C131.262 25.993 130.906 25.9191 130.522 25.9191C130.121 25.9191 129.759 25.99 129.436 26.1285C129.112 26.2671 128.84 26.458 128.616 26.7013C128.391 26.9446 128.218 27.2279 128.097 27.5513C127.972 27.8746 127.911 28.2134 127.911 28.5644V28.6075C127.911 28.974 127.975 29.3189 128.106 29.6422C128.238 29.9656 128.423 30.2489 128.66 30.4891C128.901 30.7324 129.179 30.9264 129.503 31.0742C129.83 31.222 130.182 31.2929 130.567 31.2929Z'
            fill='#202B4C'
          />
          <path
            d='M142.517 28.54L138.381 22.5873H143.119L144.968 25.5406L146.817 22.5873H151.462L147.349 28.5184L151.555 34.6066H146.839L144.898 31.5179L142.934 34.6066H138.31L142.517 28.54Z'
            fill='#202B4C'
          />
          <path
            d='M158.851 33.5846C159.656 33.5846 160.316 33.2703 160.831 32.6418C161.346 32.0006 161.604 31.0955 161.604 29.9264C161.604 28.883 161.353 28.0407 160.85 27.3996C160.347 26.7585 159.712 26.4379 158.945 26.4379C158.178 26.4379 157.512 26.7207 156.946 27.2864C156.393 27.8396 156.117 28.751 156.117 30.0207C156.117 31.2401 156.4 32.1389 156.965 32.7172C157.531 33.2955 158.16 33.5846 158.851 33.5846ZM156.117 25.2311V26.683C156.783 25.6145 157.77 25.0802 159.077 25.0802C160.347 25.0802 161.34 25.5453 162.057 26.4756C162.786 27.3933 163.15 28.5436 163.15 29.9264C163.15 31.3972 162.767 32.6041 162 33.5469C161.246 34.4772 160.259 34.9423 159.04 34.9423C157.782 34.9423 156.808 34.408 156.117 33.3395V38.5062H154.646V25.2311H156.117Z'
            fill='#E56054'
          />
          <path
            d='M170.493 30.379H168.833C168.029 30.379 167.425 30.5361 167.023 30.8504C166.621 31.1647 166.42 31.5607 166.42 32.0384C166.42 32.5161 166.577 32.9058 166.891 33.2075C167.205 33.4966 167.664 33.6412 168.268 33.6412C168.947 33.6412 169.487 33.4526 169.889 33.0755C170.292 32.6858 170.493 32.1955 170.493 31.6047V30.379ZM165.552 27.2299V25.7402C166.269 25.3002 167.212 25.0802 168.381 25.0802C170.782 25.0802 171.983 26.2996 171.983 28.7384V34.7915H170.512V33.66C170.021 34.4772 169.129 34.8857 167.834 34.8857C166.941 34.8857 166.219 34.6217 165.665 34.0938C165.125 33.5658 164.855 32.8995 164.855 32.0949C164.855 31.1772 165.2 30.467 165.892 29.9641C166.596 29.4487 167.551 29.191 168.758 29.191H170.493V28.7007C170.493 27.9464 170.323 27.3807 169.984 27.0036C169.644 26.6139 169.072 26.419 168.268 26.419C167.249 26.419 166.344 26.6893 165.552 27.2299Z'
            fill='#E56054'
          />
          <path
            d='M177.266 34.5463L173.4 25.2311H175.079L176.323 28.3801L178.001 32.7172C178.102 32.3904 178.605 30.9447 179.51 28.3801L180.622 25.2311H182.225L178.775 34.5463C178.284 35.8412 177.7 36.7526 177.021 37.2806C176.355 37.8085 175.588 38.0725 174.72 38.0725C174.393 38.0725 174.129 38.0411 173.928 37.9783V36.6394C174.117 36.6897 174.331 36.7148 174.569 36.7148C175.839 36.7148 176.738 35.992 177.266 34.5463Z'
            fill='#E56054'
          />
        </svg>
      )}
    </>
  );
};

export const HomeboxPro = (
  props: React.JSX.IntrinsicAttributes &
    React.SVGProps<SVGSVGElement> &
    LogoProps
) => {
  return (
    <>
      {props.dark ? (
        <svg
          width='189'
          height='41'
          viewBox='0 0 189 41'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          {...props}
        >
          <path
            d='M14.235 34.3868C10.7918 34.3868 8 31.6677 8 28.3071V6H14.0906C17.6141 6 20.47 8.78486 20.47 12.2206V28.3102C20.47 31.6677 17.6782 34.3868 14.235 34.3868Z'
            fill='#E56054'
          />
          <path
            d='M35.278 34.3868H28.9628C25.5648 34.3868 22.808 31.7227 22.808 28.4391V18.2753H29.043C32.4859 18.2753 35.278 20.9735 35.278 24.3005V34.3868Z'
            fill='#E56054'
          />
          <path
            d='M42.3493 18.3806H46.7642V24.2224C47.1583 23.7205 47.6549 23.2739 48.2476 22.889C48.8403 22.5041 49.5804 22.31 50.4615 22.31C51.8103 22.31 52.8579 22.7042 53.6108 23.4864C54.3605 24.2717 54.7386 25.3526 54.7386 26.7291V34.5942H50.3237V28.0903C50.3237 27.4251 50.1635 26.9201 49.8431 26.5782C49.5227 26.2364 49.1062 26.067 48.5872 26.067C48.0554 26.067 47.6164 26.2364 47.2768 26.5782C46.934 26.9201 46.7642 27.422 46.7642 28.0903V34.6003H42.3493V18.3806Z'
            fill='white'
          />
          <path
            d='M63.3823 34.8866C62.3795 34.8866 61.46 34.7233 60.6205 34.3969C59.7811 34.0705 59.0539 33.624 58.4355 33.0542C57.8204 32.4845 57.3398 31.8224 57.0034 31.0649C56.6638 30.3104 56.494 29.5036 56.494 28.6444V28.6013C56.494 27.7421 56.667 26.9322 57.013 26.1685C57.359 25.4048 57.8396 24.7396 58.4579 24.1699C59.0731 23.6002 59.8068 23.1475 60.6526 22.8149C61.4984 22.4823 62.4243 22.316 63.4271 22.316C64.4299 22.316 65.3494 22.4823 66.1888 22.8149C67.0283 23.1475 67.7555 23.5971 68.3739 24.1576C68.989 24.7211 69.4664 25.3801 69.806 26.1346C70.1456 26.8891 70.3154 27.6959 70.3154 28.5551V28.5982C70.3154 29.4574 70.1424 30.2673 69.7964 31.031C69.4504 31.7947 68.9666 32.4599 68.3514 33.0296C67.7363 33.5993 67.0026 34.052 66.1568 34.3846C65.3078 34.7203 64.3851 34.8866 63.3823 34.8866ZM63.4271 31.2866C63.8276 31.2866 64.1896 31.2158 64.5132 31.0772C64.8368 30.9386 65.1091 30.7477 65.3334 30.5044C65.5577 30.2611 65.7307 29.9778 65.8524 29.6576C65.9742 29.3342 66.0383 28.9955 66.0383 28.6444V28.6013C66.0383 28.2348 65.971 27.8899 65.8428 27.5666C65.7115 27.2432 65.5256 26.9599 65.2886 26.7197C65.0483 26.4764 64.7695 26.2824 64.4459 26.1346C64.1224 25.9868 63.7667 25.9129 63.3823 25.9129C62.9818 25.9129 62.6198 25.9837 62.2962 26.1223C61.9726 26.2609 61.7002 26.4518 61.476 26.6951C61.2517 26.9384 61.0787 27.2217 60.9569 27.545C60.832 27.8684 60.7711 28.2071 60.7711 28.5582V28.6013C60.7711 28.9677 60.8352 29.3127 60.9666 29.636C61.0979 29.9593 61.2837 30.2427 61.5208 30.4829C61.7611 30.7261 62.0398 30.9202 62.3634 31.068C62.6902 31.2158 63.0427 31.2866 63.4271 31.2866Z'
            fill='white'
          />
          <path
            d='M72.1647 22.5809H76.5796V24.2254C76.9801 23.7234 77.4799 23.2769 78.0822 22.8919C78.6813 22.507 79.4214 22.313 80.2993 22.313C81.1323 22.313 81.8564 22.4824 82.4715 22.8242C83.0866 23.166 83.564 23.6464 83.9036 24.2685C84.4419 23.6618 85.0442 23.1845 85.7074 22.8365C86.3706 22.4885 87.1651 22.313 88.0879 22.313C89.4303 22.313 90.4779 22.6948 91.2308 23.4586C91.987 24.2223 92.365 25.3063 92.365 26.7136V34.6002H87.9501V28.0655C87.9501 27.4003 87.7963 26.8984 87.4919 26.5658C87.1844 26.2332 86.7711 26.0669 86.2521 26.0669C85.7298 26.0669 85.3037 26.2332 84.9673 26.5658C84.6309 26.8984 84.4611 27.4003 84.4611 28.0655V34.5971H80.0462V28.0655C80.0462 27.4003 79.8924 26.8984 79.588 26.5658C79.2837 26.2332 78.8672 26.0669 78.3481 26.0669C77.8291 26.0669 77.403 26.2332 77.073 26.5658C76.743 26.8984 76.5796 27.4003 76.5796 28.0655V34.5971H72.1647V22.5809Z'
            fill='white'
          />
          <path
            d='M100.8 34.8866C99.8134 34.8866 98.9131 34.7357 98.0962 34.4308C97.2792 34.1259 96.5743 33.704 95.9816 33.1651C95.3889 32.6231 94.9275 31.9703 94.5943 31.2004C94.2643 30.4305 94.0977 29.5775 94.0977 28.6444V28.6013C94.0977 27.7267 94.2515 26.9106 94.5591 26.1469C94.8667 25.3832 95.3024 24.718 95.8663 24.1483C96.4302 23.5786 97.0934 23.129 97.8655 22.8057C98.6344 22.4792 99.4834 22.316 100.409 22.316C101.489 22.316 102.425 22.4946 103.219 22.8488C104.014 23.2029 104.667 23.6833 105.183 24.2807C105.699 24.8813 106.083 25.5772 106.34 26.3686C106.593 27.1601 106.721 28.0008 106.721 28.8908C106.721 29.0232 106.721 29.1648 106.721 29.3127C106.721 29.4605 106.715 29.6083 106.699 29.7561H98.3332C98.503 30.4213 98.8234 30.9202 99.2912 31.2435C99.7622 31.5699 100.342 31.7331 101.037 31.7331C101.56 31.7331 102.043 31.6377 102.482 31.4437C102.921 31.2527 103.363 30.9417 103.812 30.5106L106.237 32.4445C105.635 33.1867 104.892 33.7779 104.007 34.2214C103.117 34.6648 102.05 34.8866 100.8 34.8866ZM102.604 27.6004C102.527 26.9353 102.296 26.4056 101.912 26.0114C101.527 25.6203 101.034 25.4232 100.432 25.4232C99.8455 25.4232 99.3681 25.6203 98.9996 26.0114C98.6312 26.4025 98.3845 26.9322 98.2596 27.6004H102.604Z'
            fill='white'
          />
          <path
            d='M116.81 34.8652C116.333 34.8652 115.906 34.8159 115.532 34.7204C115.153 34.625 114.814 34.4987 114.506 34.3417C114.199 34.1877 113.923 33.9998 113.677 33.7873C113.43 33.5718 113.209 33.3531 113.007 33.1314V34.5973H108.592V18.3806H113.007L112.984 24.1793C113.199 23.9268 113.43 23.6897 113.677 23.4679C113.923 23.2462 114.196 23.0491 114.497 22.8797C114.798 22.7104 115.137 22.5718 115.512 22.4702C115.89 22.3655 116.31 22.3162 116.771 22.3162C117.512 22.3162 118.216 22.4609 118.886 22.7504C119.556 23.0399 120.145 23.4495 120.655 23.9822C121.164 24.515 121.568 25.1678 121.869 25.9377C122.17 26.7076 122.321 27.5729 122.321 28.5368V28.5799C122.321 29.5561 122.17 30.4338 121.872 31.2129C121.571 31.9889 121.174 32.651 120.674 33.1899C120.174 33.7319 119.591 34.1446 118.921 34.434C118.248 34.7204 117.547 34.8652 116.81 34.8652ZM115.442 31.333C116.163 31.333 116.768 31.0805 117.258 30.5785C117.749 30.0765 117.995 29.4145 117.995 28.6015V28.5583C117.995 27.7577 117.749 27.1079 117.258 26.6029C116.768 26.1009 116.163 25.8484 115.442 25.8484C115.089 25.8484 114.759 25.9161 114.452 26.0486C114.144 26.181 113.875 26.3719 113.648 26.6152C113.417 26.8585 113.238 27.1449 113.106 27.4713C112.975 27.7977 112.911 28.1611 112.911 28.5583V28.6015C112.911 29.0018 112.975 29.3683 113.106 29.7008C113.238 30.0334 113.417 30.3229 113.648 30.5662C113.878 30.8095 114.147 31.0004 114.452 31.1328C114.759 31.2652 115.089 31.333 115.442 31.333Z'
            fill='white'
          />
          <path
            d='M130.593 34.8866C129.59 34.8866 128.671 34.7233 127.831 34.3969C126.992 34.0705 126.265 33.624 125.646 33.0542C125.031 32.4845 124.551 31.8224 124.214 31.0649C123.875 30.3104 123.705 29.5036 123.705 28.6444V28.6013C123.705 27.7421 123.878 26.9322 124.224 26.1685C124.57 25.4048 125.051 24.7396 125.669 24.1699C126.284 23.6002 127.018 23.1475 127.864 22.8149C128.709 22.4823 129.635 22.316 130.638 22.316C131.641 22.316 132.56 22.4823 133.4 22.8149C134.239 23.1475 134.966 23.5971 135.585 24.1576C136.2 24.7211 136.677 25.3801 137.017 26.1346C137.357 26.8891 137.526 27.6959 137.526 28.5551V28.5982C137.526 29.4574 137.353 30.2673 137.007 31.031C136.661 31.7947 136.178 32.4599 135.562 33.0296C134.947 33.5993 134.214 34.052 133.368 34.3846C132.519 34.7203 131.596 34.8866 130.593 34.8866ZM130.638 31.2866C131.039 31.2866 131.401 31.2158 131.724 31.0772C132.048 30.9386 132.32 30.7477 132.544 30.5044C132.769 30.2611 132.942 29.9778 133.063 29.6576C133.185 29.3342 133.249 28.9955 133.249 28.6444V28.6013C133.249 28.2348 133.182 27.8899 133.054 27.5666C132.922 27.2432 132.737 26.9599 132.499 26.7197C132.259 26.4764 131.98 26.2824 131.657 26.1346C131.333 25.9868 130.978 25.9129 130.593 25.9129C130.193 25.9129 129.831 25.9837 129.507 26.1223C129.183 26.2609 128.911 26.4518 128.687 26.6951C128.463 26.9384 128.29 27.2217 128.168 27.545C128.043 27.8684 127.982 28.2071 127.982 28.5582V28.6013C127.982 28.9677 128.046 29.3127 128.177 29.636C128.309 29.9593 128.495 30.2427 128.732 30.4829C128.972 30.7261 129.251 30.9202 129.574 31.068C129.901 31.2158 130.254 31.2866 130.638 31.2866Z'
            fill='white'
          />
          <path
            d='M142.588 28.5337L138.452 22.5811H143.191L145.039 25.5343L146.888 22.5811H151.534L147.42 28.5122L151.626 34.6004H146.91L144.969 31.5116L143.005 34.6004H138.382L142.588 28.5337Z'
            fill='white'
          />
          <path
            d='M158.922 33.5784C159.727 33.5784 160.387 33.2641 160.902 32.6355C161.418 31.9944 161.675 31.0893 161.675 29.9202C161.675 28.8767 161.424 28.0345 160.921 27.3933C160.418 26.7522 159.783 26.4316 159.017 26.4316C158.25 26.4316 157.583 26.7145 157.018 27.2802C156.465 27.8333 156.188 28.7447 156.188 30.0144C156.188 31.2338 156.471 32.1327 157.037 32.711C157.602 33.2892 158.231 33.5784 158.922 33.5784ZM156.188 25.2248V26.6768C156.854 25.6082 157.841 25.074 159.149 25.074C160.418 25.074 161.411 25.5391 162.128 26.4694C162.857 27.3871 163.222 28.5373 163.222 29.9202C163.222 31.391 162.838 32.5978 162.071 33.5407C161.317 34.4709 160.33 34.9361 159.111 34.9361C157.854 34.9361 156.879 34.4018 156.188 33.3332V38.5H154.717V25.2248H156.188Z'
            fill='white'
          />
          <path
            d='M166.887 25.2248V26.9031C167.39 25.7214 168.257 25.1305 169.489 25.1305C169.716 25.1305 169.892 25.1431 170.017 25.1682V26.6391C169.753 26.5511 169.483 26.5071 169.206 26.5071C168.54 26.5071 167.987 26.8151 167.547 27.4311C167.107 28.047 166.887 28.7573 166.887 29.5619V34.7852H165.397L165.416 25.2248H166.887Z'
            fill='white'
          />
          <path
            d='M175.451 34.9549C174.081 34.9549 172.994 34.4961 172.189 33.5784C171.397 32.6481 171.001 31.479 171.001 30.071C171.001 28.6505 171.41 27.4688 172.227 26.5259C173.056 25.5705 174.131 25.0928 175.451 25.0928C176.897 25.0928 178.01 25.5579 178.789 26.4882C179.581 27.4185 179.977 28.6002 179.977 30.0333C179.977 31.4538 179.575 32.6293 178.77 33.5595C177.966 34.4898 176.859 34.9549 175.451 34.9549ZM175.451 26.4128C174.546 26.4128 173.836 26.7648 173.32 27.4688C172.805 28.1728 172.547 29.0339 172.547 30.0522C172.547 31.0453 172.811 31.8938 173.339 32.5978C173.867 33.2892 174.571 33.6349 175.451 33.6349C176.407 33.6349 177.142 33.2955 177.658 32.6167C178.173 31.9253 178.431 31.0704 178.431 30.0522C178.431 28.9962 178.185 28.1288 177.695 27.4499C177.205 26.7585 176.457 26.4128 175.451 26.4128Z'
            fill='white'
          />
        </svg>
      ) : (
        <svg
          width='189'
          height='41'
          viewBox='0 0 189 41'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          {...props}
        >
          <path
            d='M14.235 34.3868C10.7918 34.3868 8 31.6677 8 28.3071V6H14.0906C17.6141 6 20.47 8.78486 20.47 12.2206V28.3102C20.47 31.6677 17.6782 34.3868 14.235 34.3868Z'
            fill='#E56054'
          />
          <path
            d='M35.278 34.3868H28.9628C25.5648 34.3868 22.808 31.7227 22.808 28.4391V18.2753H29.043C32.4859 18.2753 35.278 20.9735 35.278 24.3005V34.3868Z'
            fill='#E56054'
          />
          <path
            d='M42.3493 18.3806H46.7642V24.2224C47.1583 23.7205 47.6549 23.2739 48.2476 22.889C48.8403 22.5041 49.5804 22.31 50.4615 22.31C51.8103 22.31 52.8579 22.7042 53.6109 23.4864C54.3606 24.2717 54.7386 25.3526 54.7386 26.7291V34.5942H50.3237V28.0903C50.3237 27.4251 50.1635 26.9201 49.8431 26.5782C49.5227 26.2364 49.1062 26.067 48.5872 26.067C48.0554 26.067 47.6164 26.2364 47.2768 26.5782C46.934 26.9201 46.7642 27.422 46.7642 28.0903V34.6003H42.3493V18.3806Z'
            fill='#202B4C'
          />
          <path
            d='M63.3823 34.8866C62.3795 34.8866 61.46 34.7233 60.6205 34.3969C59.7811 34.0705 59.0539 33.624 58.4355 33.0542C57.8204 32.4845 57.3398 31.8224 57.0034 31.0649C56.6638 30.3104 56.494 29.5036 56.494 28.6444V28.6013C56.494 27.7421 56.667 26.9322 57.013 26.1685C57.359 25.4048 57.8396 24.7396 58.4579 24.1699C59.0731 23.6002 59.8068 23.1475 60.6526 22.8149C61.4984 22.4823 62.4243 22.316 63.4271 22.316C64.4299 22.316 65.3494 22.4823 66.1889 22.8149C67.0283 23.1475 67.7555 23.5971 68.3739 24.1576C68.989 24.7211 69.4664 25.3801 69.806 26.1346C70.1456 26.8891 70.3154 27.6959 70.3154 28.5551V28.5982C70.3154 29.4574 70.1424 30.2673 69.7964 31.031C69.4504 31.7947 68.9666 32.4599 68.3514 33.0296C67.7363 33.5993 67.0026 34.052 66.1568 34.3846C65.3078 34.7203 64.3851 34.8866 63.3823 34.8866ZM63.4271 31.2866C63.8276 31.2866 64.1896 31.2158 64.5132 31.0772C64.8368 30.9386 65.1092 30.7477 65.3334 30.5044C65.5577 30.2611 65.7307 29.9778 65.8524 29.6576C65.9742 29.3342 66.0383 28.9955 66.0383 28.6444V28.6013C66.0383 28.2348 65.971 27.8899 65.8428 27.5666C65.7115 27.2432 65.5257 26.9599 65.2886 26.7197C65.0483 26.4764 64.7695 26.2824 64.446 26.1346C64.1224 25.9868 63.7667 25.9129 63.3823 25.9129C62.9818 25.9129 62.6198 25.9837 62.2962 26.1223C61.9726 26.2609 61.7002 26.4518 61.476 26.6951C61.2517 26.9384 61.0787 27.2217 60.957 27.545C60.832 27.8684 60.7711 28.2071 60.7711 28.5582V28.6013C60.7711 28.9677 60.8352 29.3127 60.9666 29.636C61.0979 29.9593 61.2837 30.2427 61.5208 30.4829C61.7611 30.7261 62.0399 30.9202 62.3634 31.068C62.6902 31.2158 63.0427 31.2866 63.4271 31.2866Z'
            fill='#202B4C'
          />
          <path
            d='M72.1647 22.5809H76.5796V24.2254C76.9801 23.7234 77.4799 23.2769 78.0822 22.8919C78.6814 22.507 79.4214 22.313 80.2993 22.313C81.1323 22.313 81.8564 22.4824 82.4715 22.8242C83.0867 23.166 83.564 23.6464 83.9036 24.2685C84.4419 23.6618 85.0442 23.1845 85.7074 22.8365C86.3706 22.4885 87.1652 22.313 88.0879 22.313C89.4303 22.313 90.4779 22.6948 91.2309 23.4586C91.987 24.2223 92.365 25.3063 92.365 26.7136V34.6002H87.9501V28.0655C87.9501 27.4003 87.7963 26.8984 87.492 26.5658C87.1844 26.2332 86.7711 26.0669 86.2521 26.0669C85.7298 26.0669 85.3037 26.2332 84.9673 26.5658C84.6309 26.8984 84.4611 27.4003 84.4611 28.0655V34.5971H80.0462V28.0655C80.0462 27.4003 79.8924 26.8984 79.588 26.5658C79.2837 26.2332 78.8672 26.0669 78.3481 26.0669C77.8291 26.0669 77.403 26.2332 77.073 26.5658C76.743 26.8984 76.5796 27.4003 76.5796 28.0655V34.5971H72.1647V22.5809Z'
            fill='#202B4C'
          />
          <path
            d='M100.8 34.8866C99.8134 34.8866 98.9132 34.7357 98.0962 34.4308C97.2792 34.1259 96.5743 33.704 95.9816 33.1651C95.3889 32.6231 94.9276 31.9703 94.5944 31.2004C94.2644 30.4305 94.0977 29.5775 94.0977 28.6444V28.6013C94.0977 27.7267 94.2515 26.9106 94.5591 26.1469C94.8667 25.3832 95.3024 24.718 95.8663 24.1483C96.4302 23.5786 97.0934 23.129 97.8655 22.8057C98.6344 22.4792 99.4834 22.316 100.409 22.316C101.489 22.316 102.425 22.4946 103.219 22.8488C104.014 23.2029 104.667 23.6833 105.183 24.2807C105.699 24.8813 106.083 25.5772 106.34 26.3686C106.593 27.1601 106.721 28.0008 106.721 28.8908C106.721 29.0232 106.721 29.1648 106.721 29.3127C106.721 29.4605 106.715 29.6083 106.699 29.7561H98.3333C98.5031 30.4213 98.8234 30.9202 99.2912 31.2435C99.7622 31.5699 100.342 31.7331 101.037 31.7331C101.56 31.7331 102.043 31.6377 102.482 31.4437C102.921 31.2527 103.363 30.9417 103.812 30.5106L106.237 32.4445C105.635 33.1867 104.892 33.7779 104.007 34.2214C103.117 34.6648 102.05 34.8866 100.8 34.8866ZM102.604 27.6004C102.527 26.9353 102.296 26.4056 101.912 26.0114C101.528 25.6203 101.034 25.4232 100.432 25.4232C99.8455 25.4232 99.3681 25.6203 98.9997 26.0114C98.6312 26.4025 98.3845 26.9322 98.2596 27.6004H102.604Z'
            fill='#202B4C'
          />
          <path
            d='M116.81 34.8652C116.333 34.8652 115.906 34.8159 115.532 34.7204C115.153 34.625 114.814 34.4987 114.506 34.3417C114.199 34.1877 113.923 33.9998 113.677 33.7873C113.43 33.5718 113.209 33.3531 113.007 33.1314V34.5973H108.592V18.3806H113.007L112.984 24.1793C113.199 23.9268 113.43 23.6897 113.677 23.4679C113.923 23.2462 114.196 23.0491 114.497 22.8797C114.798 22.7104 115.137 22.5718 115.512 22.4702C115.89 22.3655 116.31 22.3162 116.771 22.3162C117.512 22.3162 118.216 22.4609 118.886 22.7504C119.556 23.0399 120.145 23.4495 120.655 23.9822C121.164 24.515 121.568 25.1678 121.869 25.9377C122.17 26.7076 122.321 27.5729 122.321 28.5368V28.5799C122.321 29.5561 122.17 30.4338 121.872 31.2129C121.571 31.9889 121.174 32.651 120.674 33.1899C120.174 33.7319 119.591 34.1446 118.921 34.434C118.248 34.7204 117.547 34.8652 116.81 34.8652ZM115.442 31.333C116.163 31.333 116.768 31.0805 117.258 30.5785C117.749 30.0765 117.995 29.4145 117.995 28.6015V28.5583C117.995 27.7577 117.749 27.1079 117.258 26.6029C116.768 26.1009 116.163 25.8484 115.442 25.8484C115.089 25.8484 114.759 25.9161 114.452 26.0486C114.144 26.181 113.875 26.3719 113.648 26.6152C113.417 26.8585 113.238 27.1449 113.106 27.4713C112.975 27.7977 112.911 28.1611 112.911 28.5583V28.6015C112.911 29.0018 112.975 29.3683 113.106 29.7008C113.238 30.0334 113.417 30.3229 113.648 30.5662C113.878 30.8095 114.147 31.0004 114.452 31.1328C114.759 31.2652 115.089 31.333 115.442 31.333Z'
            fill='#202B4C'
          />
          <path
            d='M130.593 34.8866C129.59 34.8866 128.671 34.7233 127.831 34.3969C126.992 34.0705 126.265 33.624 125.646 33.0542C125.031 32.4845 124.551 31.8224 124.214 31.0649C123.875 30.3104 123.705 29.5036 123.705 28.6444V28.6013C123.705 27.7421 123.878 26.9322 124.224 26.1685C124.57 25.4048 125.051 24.7396 125.669 24.1699C126.284 23.6002 127.018 23.1475 127.864 22.8149C128.709 22.4823 129.635 22.316 130.638 22.316C131.641 22.316 132.56 22.4823 133.4 22.8149C134.239 23.1475 134.966 23.5971 135.585 24.1576C136.2 24.7211 136.677 25.3801 137.017 26.1346C137.357 26.8891 137.526 27.6959 137.526 28.5551V28.5982C137.526 29.4574 137.353 30.2673 137.007 31.031C136.661 31.7947 136.178 32.4599 135.562 33.0296C134.947 33.5993 134.214 34.052 133.368 34.3846C132.519 34.7203 131.596 34.8866 130.593 34.8866ZM130.638 31.2866C131.039 31.2866 131.401 31.2158 131.724 31.0772C132.048 30.9386 132.32 30.7477 132.544 30.5044C132.769 30.2611 132.942 29.9778 133.063 29.6576C133.185 29.3342 133.249 28.9955 133.249 28.6444V28.6013C133.249 28.2348 133.182 27.8899 133.054 27.5666C132.922 27.2432 132.737 26.9599 132.499 26.7197C132.259 26.4764 131.98 26.2824 131.657 26.1346C131.333 25.9868 130.978 25.9129 130.593 25.9129C130.193 25.9129 129.831 25.9837 129.507 26.1223C129.183 26.2609 128.911 26.4518 128.687 26.6951C128.463 26.9384 128.29 27.2217 128.168 27.545C128.043 27.8684 127.982 28.2071 127.982 28.5582V28.6013C127.982 28.9677 128.046 29.3127 128.177 29.636C128.309 29.9593 128.495 30.2427 128.732 30.4829C128.972 30.7261 129.251 30.9202 129.574 31.068C129.901 31.2158 130.254 31.2866 130.638 31.2866Z'
            fill='#202B4C'
          />
          <path
            d='M142.588 28.5337L138.452 22.5811H143.191L145.039 25.5343L146.888 22.5811H151.534L147.42 28.5122L151.626 34.6004H146.91L144.969 31.5116L143.005 34.6004H138.382L142.588 28.5337Z'
            fill='#202B4C'
          />
          <path
            d='M158.922 33.5784C159.727 33.5784 160.387 33.2641 160.902 32.6355C161.418 31.9944 161.675 31.0893 161.675 29.9202C161.675 28.8767 161.424 28.0345 160.921 27.3933C160.418 26.7522 159.783 26.4316 159.017 26.4316C158.25 26.4316 157.583 26.7145 157.018 27.2802C156.465 27.8333 156.188 28.7447 156.188 30.0144C156.188 31.2338 156.471 32.1327 157.037 32.711C157.602 33.2892 158.231 33.5784 158.922 33.5784ZM156.188 25.2248V26.6768C156.854 25.6082 157.841 25.074 159.149 25.074C160.418 25.074 161.411 25.5391 162.128 26.4694C162.857 27.3871 163.222 28.5373 163.222 29.9202C163.222 31.391 162.838 32.5978 162.071 33.5407C161.317 34.4709 160.33 34.9361 159.111 34.9361C157.854 34.9361 156.879 34.4018 156.188 33.3332V38.5H154.717V25.2248H156.188Z'
            fill='#E56054'
          />
          <path
            d='M166.887 25.2248V26.9031C167.39 25.7214 168.257 25.1305 169.489 25.1305C169.716 25.1305 169.892 25.1431 170.017 25.1682V26.6391C169.753 26.5511 169.483 26.5071 169.206 26.5071C168.54 26.5071 167.987 26.8151 167.547 27.4311C167.107 28.047 166.887 28.7573 166.887 29.5619V34.7852H165.397L165.416 25.2248H166.887Z'
            fill='#E56054'
          />
          <path
            d='M175.451 34.9549C174.081 34.9549 172.994 34.4961 172.189 33.5784C171.397 32.6481 171.001 31.479 171.001 30.071C171.001 28.6505 171.41 27.4688 172.227 26.5259C173.056 25.5705 174.131 25.0928 175.451 25.0928C176.897 25.0928 178.01 25.5579 178.789 26.4882C179.581 27.4185 179.977 28.6002 179.977 30.0333C179.977 31.4538 179.575 32.6293 178.77 33.5595C177.966 34.4898 176.859 34.9549 175.451 34.9549ZM175.451 26.4128C174.546 26.4128 173.836 26.7648 173.32 27.4688C172.805 28.1728 172.547 29.0339 172.547 30.0522C172.547 31.0453 172.811 31.8938 173.339 32.5978C173.867 33.2892 174.571 33.6349 175.451 33.6349C176.407 33.6349 177.142 33.2955 177.658 32.6167C178.173 31.9253 178.431 31.0704 178.431 30.0522C178.431 28.9962 178.186 28.1288 177.695 27.4499C177.205 26.7585 176.457 26.4128 175.451 26.4128Z'
            fill='#E56054'
          />
        </svg>
      )}
    </>
  );
};

export const HomeboxListings = (
  props: React.JSX.IntrinsicAttributes &
    React.SVGProps<SVGSVGElement> &
    LogoProps
) => {
  return (
    <>
      {props.dark ? (
        <svg
          width='219'
          height='41'
          viewBox='0 0 219 41'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          {...props}
        >
          <path
            d='M14.235 34.3868C10.7918 34.3868 8 31.6677 8 28.3071V6H14.0906C17.6141 6 20.47 8.78486 20.47 12.2206V28.3102C20.47 31.6677 17.6782 34.3868 14.235 34.3868Z'
            fill='#E56054'
          />
          <path
            d='M35.278 34.3868H28.9628C25.5648 34.3868 22.808 31.7227 22.808 28.4391V18.2753H29.043C32.4859 18.2753 35.278 20.9735 35.278 24.3005V34.3868Z'
            fill='#E56054'
          />
          <path
            d='M42.4478 18.4157H46.8758V24.2748C47.271 23.7713 47.7691 23.3235 48.3635 22.9374C48.958 22.5513 49.7003 22.3568 50.5839 22.3568C51.9368 22.3568 52.9875 22.7521 53.7426 23.5366C54.4946 24.3242 54.8737 25.4083 54.8737 26.7889V34.6772H50.4458V28.1541C50.4458 27.4869 50.2851 26.9804 49.9638 26.6376C49.6424 26.2947 49.2247 26.1249 48.7041 26.1249C48.1707 26.1249 47.7305 26.2947 47.3899 26.6376C47.0461 26.9804 46.8758 27.4838 46.8758 28.1541V34.6834H42.4478V18.4157Z'
            fill='white'
          />
          <path
            d='M63.5429 34.9704C62.5372 34.9704 61.6149 34.8067 60.773 34.4793C59.9312 34.152 59.2017 33.7041 58.5816 33.1327C57.9646 32.5613 57.4826 31.8973 57.1452 31.1375C56.8046 30.3808 56.6343 29.5716 56.6343 28.7098V28.6666C56.6343 27.8049 56.8078 26.9926 57.1548 26.2266C57.5019 25.4606 57.9839 24.7935 58.6041 24.2221C59.221 23.6507 59.9569 23.1967 60.8052 22.8631C61.6535 22.5296 62.5822 22.3628 63.5879 22.3628C64.5937 22.3628 65.5159 22.5296 66.3578 22.8631C67.1997 23.1967 67.9291 23.6476 68.5493 24.2098C69.1662 24.775 69.645 25.4359 69.9856 26.1926C70.3263 26.9493 70.4966 27.7586 70.4966 28.6203V28.6635C70.4966 29.5252 70.323 30.3375 69.976 31.1035C69.629 31.8695 69.1438 32.5366 68.5268 33.108C67.9098 33.6794 67.174 34.1334 66.3257 34.467C65.4741 34.8037 64.5487 34.9704 63.5429 34.9704ZM63.5879 31.3599C63.9896 31.3599 64.3527 31.2888 64.6772 31.1498C65.0018 31.0108 65.2749 30.8194 65.4998 30.5754C65.7248 30.3314 65.8983 30.0472 66.0204 29.726C66.1425 29.4017 66.2068 29.0619 66.2068 28.7098V28.6666C66.2068 28.2991 66.1393 27.9531 66.0108 27.6288C65.879 27.3045 65.6926 27.0204 65.4549 26.7795C65.2139 26.5355 64.9343 26.3409 64.6098 26.1926C64.2852 26.0444 63.9285 25.9703 63.5429 25.9703C63.1413 25.9703 62.7782 26.0413 62.4536 26.1803C62.1291 26.3193 61.8559 26.5108 61.631 26.7548C61.4061 26.9988 61.2325 27.2829 61.1104 27.6072C60.9851 27.9315 60.9241 28.2713 60.9241 28.6234V28.6666C60.9241 29.0341 60.9883 29.3801 61.1201 29.7044C61.2518 30.0287 61.4382 30.3128 61.676 30.5537C61.917 30.7977 62.1966 30.9923 62.5211 31.1406C62.8489 31.2888 63.2023 31.3599 63.5879 31.3599Z'
            fill='white'
          />
          <path
            d='M72.3513 22.6284H76.7793V24.2777C77.1809 23.7743 77.6822 23.3264 78.2863 22.9404C78.8872 22.5543 79.6295 22.3597 80.5099 22.3597C81.3454 22.3597 82.0716 22.5296 82.6886 22.8724C83.3055 23.2153 83.7843 23.6971 84.1249 24.321C84.6648 23.7125 85.2689 23.2338 85.934 22.8848C86.5992 22.5358 87.3961 22.3597 88.3215 22.3597C89.6679 22.3597 90.7187 22.7427 91.4738 23.5087C92.2321 24.2746 92.6113 25.3618 92.6113 26.7733V34.6832H88.1834V28.1292C88.1834 27.4621 88.0291 26.9586 87.7239 26.6251C87.4154 26.2915 87.0009 26.1247 86.4803 26.1247C85.9565 26.1247 85.5292 26.2915 85.1918 26.6251C84.8544 26.9586 84.6841 27.4621 84.6841 28.1292V34.6801H80.2561V28.1292C80.2561 27.4621 80.1019 26.9586 79.7966 26.6251C79.4913 26.2915 79.0736 26.1247 78.553 26.1247C78.0325 26.1247 77.6051 26.2915 77.2741 26.6251C76.9432 26.9586 76.7793 27.4621 76.7793 28.1292V34.6801H72.3513V22.6284Z'
            fill='white'
          />
          <path
            d='M101.071 34.9704C100.082 34.9704 99.1788 34.8191 98.3594 34.5133C97.54 34.2076 96.8331 33.7844 96.2386 33.2439C95.6442 32.7003 95.1814 32.0455 94.8473 31.2734C94.5163 30.5012 94.3492 29.6457 94.3492 28.7098V28.6666C94.3492 27.7894 94.5034 26.971 94.8119 26.205C95.1204 25.439 95.5574 24.7719 96.1229 24.2005C96.6885 23.6291 97.3536 23.1782 98.1281 22.8539C98.8993 22.5265 99.7508 22.3628 100.679 22.3628C101.762 22.3628 102.701 22.5419 103.498 22.8971C104.294 23.2523 104.95 23.7341 105.467 24.3333C105.985 24.9356 106.37 25.6336 106.627 26.4274C106.881 27.2211 107.01 28.0643 107.01 28.9569C107.01 29.0897 107.01 29.2318 107.01 29.3801C107.01 29.5283 107.003 29.6766 106.987 29.8248H98.5972C98.7675 30.492 99.0888 30.9923 99.558 31.3166C100.03 31.644 100.612 31.8077 101.309 31.8077C101.833 31.8077 102.318 31.712 102.758 31.5174C103.199 31.3259 103.642 31.0139 104.092 30.5815L106.524 32.5212C105.92 33.2655 105.175 33.8585 104.288 34.3033C103.395 34.7481 102.325 34.9704 101.071 34.9704ZM102.881 27.6628C102.803 26.9957 102.572 26.4644 102.186 26.0691C101.801 25.6768 101.306 25.4792 100.702 25.4792C100.114 25.4792 99.6351 25.6768 99.2656 26.0691C98.896 26.4613 98.6486 26.9926 98.5233 27.6628H102.881Z'
            fill='white'
          />
          <path
            d='M117.128 34.949C116.65 34.949 116.222 34.8996 115.846 34.8038C115.467 34.7081 115.127 34.5814 114.818 34.4239C114.51 34.2695 114.233 34.0811 113.986 33.868C113.738 33.6518 113.517 33.4325 113.314 33.2101V34.6803H108.886V18.4157H113.314L113.292 24.2315C113.507 23.9783 113.738 23.7405 113.986 23.5181C114.233 23.2957 114.506 23.098 114.808 22.9282C115.11 22.7583 115.451 22.6193 115.827 22.5174C116.206 22.4124 116.627 22.3629 117.09 22.3629C117.832 22.3629 118.539 22.5081 119.211 22.7984C119.882 23.0888 120.474 23.4995 120.984 24.0339C121.495 24.5682 121.9 25.223 122.202 25.9951C122.504 26.7673 122.655 27.6352 122.655 28.6019V28.6452C122.655 29.6242 122.504 30.5045 122.205 31.2859C121.903 32.0642 121.505 32.7283 121.004 33.2688C120.502 33.8124 119.918 34.2263 119.246 34.5166C118.571 34.8038 117.867 34.949 117.128 34.949ZM115.756 31.4064C116.479 31.4064 117.087 31.1531 117.578 30.6497C118.07 30.1462 118.317 29.4822 118.317 28.6668V28.6235C118.317 27.8205 118.07 27.1688 117.578 26.6623C117.087 26.1588 116.479 25.9056 115.756 25.9056C115.403 25.9056 115.072 25.9735 114.763 26.1063C114.455 26.2391 114.185 26.4306 113.957 26.6746C113.726 26.9186 113.546 27.2059 113.414 27.5333C113.282 27.8607 113.218 28.2251 113.218 28.6235V28.6668C113.218 29.0683 113.282 29.4358 113.414 29.7694C113.546 30.103 113.726 30.3933 113.957 30.6373C114.188 30.8813 114.458 31.0728 114.763 31.2056C115.072 31.3384 115.403 31.4064 115.756 31.4064Z'
            fill='white'
          />
          <path
            d='M130.952 34.9704C129.947 34.9704 129.024 34.8067 128.183 34.4793C127.341 34.152 126.611 33.7041 125.991 33.1327C125.374 32.5613 124.892 31.8973 124.555 31.1375C124.214 30.3808 124.044 29.5716 124.044 28.7098V28.6666C124.044 27.8049 124.217 26.9926 124.564 26.2266C124.911 25.4606 125.393 24.7935 126.014 24.2221C126.631 23.6507 127.366 23.1967 128.215 22.8631C129.063 22.5296 129.992 22.3628 130.997 22.3628C132.003 22.3628 132.925 22.5296 133.767 22.8631C134.609 23.1967 135.339 23.6476 135.959 24.2098C136.576 24.775 137.055 25.4359 137.395 26.1926C137.736 26.9493 137.906 27.7586 137.906 28.6203V28.6635C137.906 29.5252 137.733 30.3375 137.386 31.1035C137.039 31.8695 136.553 32.5366 135.936 33.108C135.319 33.6794 134.584 34.1334 133.735 34.467C132.884 34.8037 131.958 34.9704 130.952 34.9704ZM130.997 31.3599C131.399 31.3599 131.762 31.2888 132.087 31.1498C132.411 31.0108 132.684 30.8194 132.909 30.5754C133.134 30.3314 133.308 30.0472 133.43 29.726C133.552 29.4017 133.616 29.0619 133.616 28.7098V28.6666C133.616 28.2991 133.549 27.9531 133.42 27.6288C133.289 27.3045 133.102 27.0204 132.864 26.7795C132.623 26.5355 132.344 26.3409 132.019 26.1926C131.695 26.0444 131.338 25.9703 130.952 25.9703C130.551 25.9703 130.188 26.0413 129.863 26.1803C129.539 26.3193 129.265 26.5108 129.041 26.7548C128.816 26.9988 128.642 27.2829 128.52 27.6072C128.395 27.9315 128.334 28.2713 128.334 28.6234V28.6666C128.334 29.0341 128.398 29.3801 128.53 29.7044C128.661 30.0287 128.848 30.3128 129.086 30.5537C129.327 30.7977 129.606 30.9923 129.931 31.1406C130.258 31.2888 130.612 31.3599 130.997 31.3599Z'
            fill='white'
          />
          <path
            d='M142.983 28.5989L138.835 22.6286H143.587L145.441 25.5906L147.295 22.6286H151.955L147.829 28.5772L152.048 34.6834H147.318L145.371 31.5855L143.401 34.6834H138.764L142.983 28.5989Z'
            fill='white'
          />
          <path
            d='M155.223 34.8688V21.4976H156.718V34.8688H155.223Z'
            fill='white'
          />
          <path
            d='M159.638 34.8688V25.2801H161.132V34.8688H159.638ZM159.675 22.9728C159.499 22.7837 159.411 22.5504 159.411 22.2731C159.411 21.9957 159.499 21.7624 159.675 21.5733C159.865 21.3842 160.098 21.2896 160.375 21.2896C160.653 21.2896 160.886 21.3842 161.075 21.5733C161.264 21.7624 161.359 21.9957 161.359 22.2731C161.359 22.5504 161.264 22.7837 161.075 22.9728C160.886 23.1493 160.653 23.2376 160.375 23.2376C160.098 23.2376 159.865 23.1493 159.675 22.9728Z'
            fill='white'
          />
          <path
            d='M169.196 25.6017V27.0201C168.616 26.6671 167.822 26.4905 166.813 26.4905C166.309 26.4905 165.905 26.6103 165.603 26.8499C165.313 27.0768 165.168 27.3731 165.168 27.7388C165.168 27.9657 165.212 28.1675 165.3 28.344C165.388 28.5079 165.558 28.6718 165.811 28.8357C166.075 28.9996 166.264 29.1131 166.378 29.1761C166.504 29.2266 166.769 29.34 167.172 29.5166C167.979 29.8444 168.61 30.2163 169.064 30.6324C169.517 31.0485 169.744 31.6095 169.744 32.3156C169.744 33.1352 169.454 33.7845 168.874 34.2636C168.307 34.7427 167.525 34.9823 166.529 34.9823C165.369 34.9823 164.43 34.7868 163.711 34.396V32.9397C164.556 33.4441 165.464 33.6962 166.435 33.6962C167.015 33.6962 167.456 33.5827 167.759 33.3558C168.061 33.1288 168.212 32.8199 168.212 32.4291C168.212 31.9752 168.042 31.6221 167.702 31.37C167.374 31.1178 166.819 30.8341 166.038 30.5189C165.319 30.2415 164.739 29.8948 164.298 29.4787C163.869 29.0627 163.655 28.5016 163.655 27.7955C163.655 27.0012 163.951 26.3708 164.543 25.9043C165.149 25.4251 165.899 25.1856 166.794 25.1856C167.79 25.1856 168.591 25.3243 169.196 25.6017Z'
            fill='white'
          />
          <path
            d='M173.832 26.4905V32.1076C173.832 33.1288 174.311 33.6395 175.269 33.6395C175.761 33.6395 176.196 33.4945 176.574 33.2045V34.6229C176.158 34.8499 175.691 34.9634 175.174 34.9634C173.283 34.9634 172.337 33.9988 172.337 32.0697V26.4905H170.806V25.2801H172.337V22.8972H173.832V25.2801H176.479V26.4905H173.832Z'
            fill='white'
          />
          <path
            d='M178.827 34.8688V25.2801H180.321V34.8688H178.827ZM178.865 22.9728C178.688 22.7837 178.6 22.5504 178.6 22.2731C178.6 21.9957 178.688 21.7624 178.865 21.5733C179.054 21.3842 179.287 21.2896 179.565 21.2896C179.842 21.2896 180.075 21.3842 180.264 21.5733C180.454 21.7624 180.548 21.9957 180.548 22.2731C180.548 22.5504 180.454 22.7837 180.264 22.9728C180.075 23.1493 179.842 23.2376 179.565 23.2376C179.287 23.2376 179.054 23.1493 178.865 22.9728Z'
            fill='white'
          />
          <path
            d='M184.641 25.2801V26.9445C185.208 25.7467 186.217 25.1478 187.667 25.1478C188.6 25.1478 189.344 25.4314 189.899 25.9988C190.453 26.5662 190.731 27.3227 190.731 28.2683V34.8688H189.237V28.6655C189.237 27.9594 189.054 27.4236 188.688 27.0579C188.335 26.6797 187.869 26.4905 187.289 26.4905C186.57 26.4905 185.946 26.7679 185.416 27.3227C184.899 27.8775 184.641 28.6466 184.641 29.63V34.8688H183.166V25.2801H184.641Z'
            fill='white'
          />
          <path
            d='M196.973 33.2991C197.666 33.2991 198.277 33.0343 198.807 32.5047C199.349 31.9626 199.62 31.1619 199.62 30.1028C199.62 28.8798 199.362 27.972 198.845 27.3794C198.328 26.7868 197.729 26.4905 197.048 26.4905C196.229 26.4905 195.579 26.8184 195.1 27.474C194.621 28.117 194.382 28.9618 194.382 30.0083C194.382 31.08 194.646 31.8995 195.176 32.4669C195.705 33.0217 196.304 33.2991 196.973 33.2991ZM199.62 25.2801H201.114V34.0934C201.114 35.556 200.711 36.6529 199.904 37.3842C199.097 38.1281 198.057 38.5 196.783 38.5C195.623 38.5 194.634 38.3046 193.814 37.9137V36.4196C194.621 36.8861 195.567 37.1194 196.651 37.1194C197.571 37.1194 198.296 36.8735 198.826 36.3818C199.356 35.8901 199.62 35.121 199.62 34.0745V32.9965C198.99 34.0808 198.051 34.6229 196.802 34.6229C195.705 34.6229 194.766 34.2195 193.984 33.4125C193.215 32.6056 192.831 31.4835 192.831 30.0461C192.831 28.6214 193.19 27.4488 193.909 26.5284C194.627 25.608 195.567 25.1478 196.727 25.1478C198.038 25.1478 199.002 25.7214 199.62 26.8688V25.2801Z'
            fill='white'
          />
          <path
            d='M208.979 25.6017V27.0201C208.399 26.6671 207.604 26.4905 206.596 26.4905C206.091 26.4905 205.688 26.6103 205.385 26.8499C205.095 27.0768 204.95 27.3731 204.95 27.7388C204.95 27.9657 204.994 28.1675 205.083 28.344C205.171 28.5079 205.341 28.6718 205.593 28.8357C205.858 28.9996 206.047 29.1131 206.161 29.1761C206.287 29.2266 206.552 29.34 206.955 29.5166C207.762 29.8444 208.392 30.2163 208.846 30.6324C209.3 31.0485 209.527 31.6095 209.527 32.3156C209.527 33.1352 209.237 33.7845 208.657 34.2636C208.09 34.7427 207.308 34.9823 206.312 34.9823C205.152 34.9823 204.213 34.7868 203.494 34.396V32.9397C204.339 33.4441 205.247 33.6962 206.218 33.6962C206.797 33.6962 207.239 33.5827 207.541 33.3558C207.844 33.1288 207.995 32.8199 207.995 32.4291C207.995 31.9752 207.825 31.6221 207.485 31.37C207.157 31.1178 206.602 30.8341 205.82 30.5189C205.102 30.2415 204.522 29.8948 204.08 29.4787C203.652 29.0627 203.437 28.5016 203.437 27.7955C203.437 27.0012 203.734 26.3708 204.326 25.9043C204.931 25.4251 205.682 25.1856 206.577 25.1856C207.573 25.1856 208.374 25.3243 208.979 25.6017Z'
            fill='white'
          />
        </svg>
      ) : (
        <svg
          width='219'
          height='41'
          viewBox='0 0 219 41'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          {...props}
        >
          <path
            d='M14.235 34.3868C10.7918 34.3868 8 31.6677 8 28.3071V6H14.0906C17.6141 6 20.47 8.78486 20.47 12.2206V28.3102C20.47 31.6677 17.6782 34.3868 14.235 34.3868Z'
            fill='#E56054'
          />
          <path
            d='M35.278 34.3868H28.9628C25.5648 34.3868 22.808 31.7227 22.808 28.4391V18.2753H29.043C32.4859 18.2753 35.278 20.9735 35.278 24.3005V34.3868Z'
            fill='#E56054'
          />
          <path
            d='M42.4478 18.4157H46.8758V24.2748C47.271 23.7713 47.7691 23.3235 48.3635 22.9374C48.958 22.5513 49.7003 22.3568 50.584 22.3568C51.9368 22.3568 52.9875 22.7521 53.7426 23.5366C54.4946 24.3242 54.8737 25.4083 54.8737 26.7889V34.6772H50.4458V28.1541C50.4458 27.4869 50.2851 26.9804 49.9638 26.6376C49.6424 26.2947 49.2247 26.1249 48.7042 26.1249C48.1707 26.1249 47.7305 26.2947 47.3899 26.6376C47.0461 26.9804 46.8758 27.4838 46.8758 28.1541V34.6834H42.4478V18.4157Z'
            fill='#202B4C'
          />
          <path
            d='M63.5429 34.9704C62.5372 34.9704 61.6149 34.8067 60.7731 34.4793C59.9312 34.152 59.2017 33.7041 58.5816 33.1327C57.9646 32.5613 57.4826 31.8973 57.1452 31.1375C56.8046 30.3808 56.6343 29.5716 56.6343 28.7098V28.6666C56.6343 27.8049 56.8078 26.9926 57.1549 26.2266C57.5019 25.4606 57.9839 24.7935 58.6041 24.2221C59.221 23.6507 59.9569 23.1967 60.8052 22.8631C61.6535 22.5296 62.5822 22.3628 63.5879 22.3628C64.5937 22.3628 65.5159 22.5296 66.3578 22.8631C67.1997 23.1967 67.9291 23.6476 68.5493 24.2098C69.1663 24.775 69.645 25.4359 69.9856 26.1926C70.3263 26.9493 70.4966 27.7586 70.4966 28.6203V28.6635C70.4966 29.5252 70.323 30.3375 69.976 31.1035C69.629 31.8695 69.1438 32.5366 68.5268 33.108C67.9098 33.6794 67.174 34.1334 66.3257 34.467C65.4741 34.8037 64.5487 34.9704 63.5429 34.9704ZM63.5879 31.3599C63.9896 31.3599 64.3527 31.2888 64.6772 31.1498C65.0018 31.0108 65.2749 30.8194 65.4999 30.5754C65.7248 30.3314 65.8983 30.0472 66.0204 29.726C66.1425 29.4017 66.2068 29.0619 66.2068 28.7098V28.6666C66.2068 28.2991 66.1393 27.9531 66.0108 27.6288C65.879 27.3045 65.6927 27.0204 65.4549 26.7795C65.2139 26.5355 64.9343 26.3409 64.6098 26.1926C64.2852 26.0444 63.9285 25.9703 63.5429 25.9703C63.1413 25.9703 62.7782 26.0413 62.4536 26.1803C62.1291 26.3193 61.8559 26.5108 61.631 26.7548C61.4061 26.9988 61.2326 27.2829 61.1105 27.6072C60.9851 27.9315 60.9241 28.2713 60.9241 28.6234V28.6666C60.9241 29.0341 60.9883 29.3801 61.1201 29.7044C61.2518 30.0287 61.4382 30.3128 61.676 30.5537C61.917 30.7977 62.1966 30.9923 62.5211 31.1406C62.8489 31.2888 63.2023 31.3599 63.5879 31.3599Z'
            fill='#202B4C'
          />
          <path
            d='M72.3513 22.6284H76.7793V24.2777C77.181 23.7743 77.6822 23.3264 78.2863 22.9404C78.8872 22.5543 79.6295 22.3597 80.51 22.3597C81.3454 22.3597 82.0716 22.5296 82.6886 22.8724C83.3056 23.2153 83.7843 23.6971 84.1249 24.321C84.6648 23.7125 85.2689 23.2338 85.934 22.8848C86.5992 22.5358 87.3961 22.3597 88.3215 22.3597C89.6679 22.3597 90.7187 22.7427 91.4738 23.5087C92.2322 24.2746 92.6113 25.3618 92.6113 26.7733V34.6832H88.1834V28.1292C88.1834 27.4621 88.0291 26.9586 87.7239 26.6251C87.4154 26.2915 87.0009 26.1247 86.4803 26.1247C85.9565 26.1247 85.5292 26.2915 85.1918 26.6251C84.8544 26.9586 84.6841 27.4621 84.6841 28.1292V34.6801H80.2561V28.1292C80.2561 27.4621 80.1019 26.9586 79.7966 26.6251C79.4913 26.2915 79.0736 26.1247 78.553 26.1247C78.0325 26.1247 77.6051 26.2915 77.2741 26.6251C76.9432 26.9586 76.7793 27.4621 76.7793 28.1292V34.6801H72.3513V22.6284Z'
            fill='#202B4C'
          />
          <path
            d='M101.071 34.9704C100.082 34.9704 99.1788 34.8191 98.3594 34.5133C97.54 34.2076 96.8331 33.7844 96.2386 33.2439C95.6442 32.7003 95.1814 32.0455 94.8473 31.2734C94.5163 30.5012 94.3492 29.6457 94.3492 28.7098V28.6666C94.3492 27.7894 94.5034 26.971 94.8119 26.205C95.1204 25.439 95.5574 24.7719 96.123 24.2005C96.6885 23.6291 97.3537 23.1782 98.1281 22.8539C98.8993 22.5265 99.7508 22.3628 100.679 22.3628C101.762 22.3628 102.701 22.5419 103.498 22.8971C104.294 23.2523 104.95 23.7341 105.467 24.3333C105.985 24.9356 106.37 25.6336 106.627 26.4274C106.881 27.2211 107.01 28.0643 107.01 28.9569C107.01 29.0897 107.01 29.2318 107.01 29.3801C107.01 29.5283 107.003 29.6766 106.987 29.8248H98.5972C98.7675 30.492 99.0888 30.9923 99.558 31.3166C100.03 31.644 100.612 31.8077 101.309 31.8077C101.833 31.8077 102.318 31.712 102.758 31.5174C103.199 31.3259 103.642 31.0139 104.092 30.5815L106.524 32.5212C105.92 33.2655 105.175 33.8585 104.288 34.3033C103.395 34.7481 102.325 34.9704 101.071 34.9704ZM102.881 27.6628C102.803 26.9957 102.572 26.4644 102.186 26.0691C101.801 25.6768 101.306 25.4792 100.702 25.4792C100.114 25.4792 99.6351 25.6768 99.2656 26.0691C98.896 26.4613 98.6486 26.9926 98.5233 27.6628H102.881Z'
            fill='#202B4C'
          />
          <path
            d='M117.128 34.949C116.65 34.949 116.222 34.8996 115.846 34.8038C115.467 34.7081 115.127 34.5814 114.818 34.4239C114.51 34.2695 114.233 34.0811 113.986 33.868C113.738 33.6518 113.517 33.4325 113.314 33.2101V34.6803H108.886V18.4157H113.314L113.292 24.2315C113.507 23.9783 113.738 23.7405 113.986 23.5181C114.233 23.2957 114.506 23.098 114.808 22.9282C115.11 22.7583 115.451 22.6193 115.827 22.5174C116.206 22.4124 116.627 22.3629 117.09 22.3629C117.832 22.3629 118.539 22.5081 119.211 22.7984C119.882 23.0888 120.474 23.4995 120.984 24.0339C121.495 24.5682 121.9 25.223 122.202 25.9951C122.504 26.7673 122.655 27.6352 122.655 28.6019V28.6452C122.655 29.6242 122.504 30.5045 122.205 31.2859C121.903 32.0642 121.505 32.7283 121.004 33.2688C120.502 33.8124 119.918 34.2263 119.246 34.5166C118.571 34.8038 117.868 34.949 117.128 34.949ZM115.756 31.4064C116.479 31.4064 117.087 31.1531 117.578 30.6497C118.07 30.1462 118.317 29.4822 118.317 28.6668V28.6235C118.317 27.8205 118.07 27.1688 117.578 26.6623C117.087 26.1588 116.479 25.9056 115.756 25.9056C115.403 25.9056 115.072 25.9735 114.763 26.1063C114.455 26.2391 114.185 26.4306 113.957 26.6746C113.726 26.9186 113.546 27.2059 113.414 27.5333C113.282 27.8607 113.218 28.2251 113.218 28.6235V28.6668C113.218 29.0683 113.282 29.4358 113.414 29.7694C113.546 30.103 113.726 30.3933 113.957 30.6373C114.188 30.8813 114.458 31.0728 114.763 31.2056C115.072 31.3384 115.403 31.4064 115.756 31.4064Z'
            fill='#202B4C'
          />
          <path
            d='M130.952 34.9704C129.947 34.9704 129.024 34.8067 128.183 34.4793C127.341 34.152 126.611 33.7041 125.991 33.1327C125.374 32.5613 124.892 31.8973 124.555 31.1375C124.214 30.3808 124.044 29.5716 124.044 28.7098V28.6666C124.044 27.8049 124.217 26.9926 124.564 26.2266C124.911 25.4606 125.393 24.7935 126.014 24.2221C126.631 23.6507 127.366 23.1967 128.215 22.8631C129.063 22.5296 129.992 22.3628 130.997 22.3628C132.003 22.3628 132.925 22.5296 133.767 22.8631C134.609 23.1967 135.339 23.6476 135.959 24.2098C136.576 24.775 137.055 25.4359 137.395 26.1926C137.736 26.9493 137.906 27.7586 137.906 28.6203V28.6635C137.906 29.5252 137.733 30.3375 137.386 31.1035C137.039 31.8695 136.553 32.5366 135.936 33.108C135.319 33.6794 134.584 34.1334 133.735 34.467C132.884 34.8037 131.958 34.9704 130.952 34.9704ZM130.997 31.3599C131.399 31.3599 131.762 31.2888 132.087 31.1498C132.411 31.0108 132.684 30.8194 132.909 30.5754C133.134 30.3314 133.308 30.0472 133.43 29.726C133.552 29.4017 133.616 29.0619 133.616 28.7098V28.6666C133.616 28.2991 133.549 27.9531 133.42 27.6288C133.289 27.3045 133.102 27.0204 132.864 26.7795C132.623 26.5355 132.344 26.3409 132.019 26.1926C131.695 26.0444 131.338 25.9703 130.952 25.9703C130.551 25.9703 130.188 26.0413 129.863 26.1803C129.539 26.3193 129.265 26.5108 129.041 26.7548C128.816 26.9988 128.642 27.2829 128.52 27.6072C128.395 27.9315 128.334 28.2713 128.334 28.6234V28.6666C128.334 29.0341 128.398 29.3801 128.53 29.7044C128.661 30.0287 128.848 30.3128 129.086 30.5537C129.327 30.7977 129.606 30.9923 129.931 31.1406C130.258 31.2888 130.612 31.3599 130.997 31.3599Z'
            fill='#202B4C'
          />
          <path
            d='M142.983 28.5989L138.835 22.6286H143.587L145.441 25.5906L147.295 22.6286H151.955L147.829 28.5772L152.048 34.6834H147.318L145.371 31.5855L143.401 34.6834H138.764L142.983 28.5989Z'
            fill='#202B4C'
          />
          <path
            d='M155.223 34.8688V21.4976H156.718V34.8688H155.223Z'
            fill='#E56054'
          />
          <path
            d='M159.638 34.8688V25.2801H161.132V34.8688H159.638ZM159.675 22.9728C159.499 22.7837 159.411 22.5504 159.411 22.2731C159.411 21.9957 159.499 21.7624 159.675 21.5733C159.865 21.3842 160.098 21.2896 160.375 21.2896C160.653 21.2896 160.886 21.3842 161.075 21.5733C161.264 21.7624 161.359 21.9957 161.359 22.2731C161.359 22.5504 161.264 22.7837 161.075 22.9728C160.886 23.1493 160.653 23.2376 160.375 23.2376C160.098 23.2376 159.865 23.1493 159.675 22.9728Z'
            fill='#E56054'
          />
          <path
            d='M169.196 25.6017V27.0201C168.616 26.6671 167.822 26.4905 166.813 26.4905C166.309 26.4905 165.905 26.6103 165.603 26.8499C165.313 27.0768 165.168 27.3731 165.168 27.7388C165.168 27.9657 165.212 28.1675 165.3 28.344C165.388 28.5079 165.558 28.6718 165.811 28.8357C166.075 28.9996 166.264 29.1131 166.378 29.1761C166.504 29.2266 166.769 29.34 167.172 29.5166C167.979 29.8444 168.61 30.2163 169.064 30.6324C169.517 31.0485 169.744 31.6095 169.744 32.3156C169.744 33.1352 169.454 33.7845 168.874 34.2636C168.307 34.7427 167.525 34.9823 166.529 34.9823C165.369 34.9823 164.43 34.7868 163.711 34.396V32.9397C164.556 33.4441 165.464 33.6962 166.435 33.6962C167.015 33.6962 167.456 33.5827 167.759 33.3558C168.061 33.1288 168.212 32.8199 168.212 32.4291C168.212 31.9752 168.042 31.6221 167.702 31.37C167.374 31.1178 166.819 30.8341 166.038 30.5189C165.319 30.2415 164.739 29.8948 164.298 29.4787C163.869 29.0627 163.655 28.5016 163.655 27.7955C163.655 27.0012 163.951 26.3708 164.543 25.9043C165.149 25.4251 165.899 25.1856 166.794 25.1856C167.79 25.1856 168.591 25.3243 169.196 25.6017Z'
            fill='#E56054'
          />
          <path
            d='M173.832 26.4905V32.1076C173.832 33.1288 174.311 33.6395 175.269 33.6395C175.761 33.6395 176.196 33.4945 176.574 33.2045V34.6229C176.158 34.8499 175.691 34.9634 175.174 34.9634C173.283 34.9634 172.337 33.9988 172.337 32.0697V26.4905H170.806V25.2801H172.337V22.8972H173.832V25.2801H176.479V26.4905H173.832Z'
            fill='#E56054'
          />
          <path
            d='M178.827 34.8688V25.2801H180.321V34.8688H178.827ZM178.865 22.9728C178.688 22.7837 178.6 22.5504 178.6 22.2731C178.6 21.9957 178.688 21.7624 178.865 21.5733C179.054 21.3842 179.287 21.2896 179.565 21.2896C179.842 21.2896 180.075 21.3842 180.264 21.5733C180.454 21.7624 180.548 21.9957 180.548 22.2731C180.548 22.5504 180.454 22.7837 180.264 22.9728C180.075 23.1493 179.842 23.2376 179.565 23.2376C179.287 23.2376 179.054 23.1493 178.865 22.9728Z'
            fill='#E56054'
          />
          <path
            d='M184.641 25.2801V26.9445C185.208 25.7467 186.217 25.1478 187.667 25.1478C188.6 25.1478 189.344 25.4314 189.899 25.9988C190.453 26.5662 190.731 27.3227 190.731 28.2683V34.8688H189.237V28.6655C189.237 27.9594 189.054 27.4236 188.688 27.0579C188.335 26.6797 187.869 26.4905 187.289 26.4905C186.57 26.4905 185.946 26.7679 185.416 27.3227C184.899 27.8775 184.641 28.6466 184.641 29.63V34.8688H183.166V25.2801H184.641Z'
            fill='#E56054'
          />
          <path
            d='M196.973 33.2991C197.666 33.2991 198.277 33.0343 198.807 32.5047C199.349 31.9626 199.62 31.1619 199.62 30.1028C199.62 28.8798 199.362 27.972 198.845 27.3794C198.328 26.7868 197.729 26.4905 197.048 26.4905C196.229 26.4905 195.579 26.8184 195.1 27.474C194.621 28.117 194.382 28.9618 194.382 30.0083C194.382 31.08 194.646 31.8995 195.176 32.4669C195.705 33.0217 196.304 33.2991 196.973 33.2991ZM199.62 25.2801H201.114V34.0934C201.114 35.556 200.711 36.6529 199.904 37.3842C199.097 38.1281 198.057 38.5 196.783 38.5C195.623 38.5 194.634 38.3046 193.814 37.9137V36.4196C194.621 36.8861 195.567 37.1194 196.651 37.1194C197.571 37.1194 198.296 36.8735 198.826 36.3818C199.356 35.8901 199.62 35.121 199.62 34.0745V32.9965C198.99 34.0808 198.051 34.6229 196.802 34.6229C195.705 34.6229 194.766 34.2195 193.984 33.4125C193.215 32.6056 192.831 31.4835 192.831 30.0461C192.831 28.6214 193.19 27.4488 193.909 26.5284C194.627 25.608 195.567 25.1478 196.727 25.1478C198.038 25.1478 199.002 25.7214 199.62 26.8688V25.2801Z'
            fill='#E56054'
          />
          <path
            d='M208.979 25.6017V27.0201C208.399 26.6671 207.604 26.4905 206.596 26.4905C206.091 26.4905 205.688 26.6103 205.385 26.8499C205.095 27.0768 204.95 27.3731 204.95 27.7388C204.95 27.9657 204.994 28.1675 205.083 28.344C205.171 28.5079 205.341 28.6718 205.593 28.8357C205.858 28.9996 206.047 29.1131 206.161 29.1761C206.287 29.2266 206.552 29.34 206.955 29.5166C207.762 29.8444 208.392 30.2163 208.846 30.6324C209.3 31.0485 209.527 31.6095 209.527 32.3156C209.527 33.1352 209.237 33.7845 208.657 34.2636C208.09 34.7427 207.308 34.9823 206.312 34.9823C205.152 34.9823 204.213 34.7868 203.494 34.396V32.9397C204.339 33.4441 205.247 33.6962 206.218 33.6962C206.798 33.6962 207.239 33.5827 207.541 33.3558C207.844 33.1288 207.995 32.8199 207.995 32.4291C207.995 31.9752 207.825 31.6221 207.485 31.37C207.157 31.1178 206.602 30.8341 205.82 30.5189C205.102 30.2415 204.522 29.8948 204.08 29.4787C203.652 29.0627 203.437 28.5016 203.437 27.7955C203.437 27.0012 203.734 26.3708 204.326 25.9043C204.931 25.4251 205.682 25.1856 206.577 25.1856C207.573 25.1856 208.374 25.3243 208.979 25.6017Z'
            fill='#E56054'
          />
        </svg>
      )}
    </>
  );
};
