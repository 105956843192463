import React, { useEffect, useState } from 'react';
import { Button } from 'homebox-ui';
import { MdArrowForward } from 'react-icons/md';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import BillPayerCard from '../components/BillPayerCard';
import AddBillPayer from '../components/BillPayerCard/AddBillPayer';
import MobileNavControl from '../components/MobileNavControl';
import { useInviteBillPayerToTenantOrderMutation } from '../app/services';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

export default function BillPayers() {
  const { nameUrl } = useParams();
  const {
    orderId,
    setRate,
    rate,
    serviceBill,
    coupon,
    setCoupon,
    couponApplied,
    setCouponApplied,
    IS_TEST_ENV,
    fetchingQuote,
    setFetchQuote,
  } = useOutletContext();
  const { values, setFieldValue } = useFormikContext();
  const billPayers = values?.tenants;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const tenantsSchema = Yup.object().shape({
    tenants: Yup.array(
      Yup.object().shape({
        first_name: Yup.string().required('first name is required.'),
        last_name: Yup.string().required('last name is required.'),
        phone_number: Yup.string().required('phone number is required'),
      })
    ),
  });

  const [
    inviteBillPayer,
    // eslint-disable-next-line no-unused-vars
    { data: billPayerData, error: billPayerError },
  ] = useInviteBillPayerToTenantOrderMutation();

  const deleteBillPayer = idx => {
    const updatedBillPayers = [...billPayers];
    updatedBillPayers.splice(idx, 1, {
      first_name: '',
      last_name: '',
      phone_number: '',
    }); // Remove the user at the specified index and insert newBillpayer

    setFieldValue('tenants', updatedBillPayers);
  };

  const addNewBillPayerToOrder = async data => {
    return await inviteBillPayer({
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
      order_id: orderId,
      orderId,
    });
  };

  const submitAllBillPayers = async () => {
    try {
      if (orderId) {
        await tenantsSchema.validate(
          {
            tenants: billPayers,
          },
          { abortEarly: false }
        );
        setIsSubmitting(true);

        let responses = [];
        let errors = [];

        for (let i = 1; i < billPayers.length; i++) {
          const payer = billPayers[i];
          const res = await addNewBillPayerToOrder(payer);
          if (res?.error?.data?.message) {
            errors.push(res?.error?.data?.message);
          } else {
            responses.push(res);
          }
        }

        setIsSubmitting(false);

        if (errors.length > 0) {
          errors.map(error => toast.error(`${error}`));
        } else {
          navigate(`/${nameUrl}/setup-payment`);
          toast.success('Added Bill Payers Successfully');
        }
      } else {
        if (IS_TEST_ENV) {
          return navigate(`/${nameUrl}/setup-payment`);
        }
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error('Enter all required fields');
    }
  };

  const billPayersAreValid =
    billPayers?.length > 1
      ? billPayers
          ?.slice(1)
          .every(obj => obj.hasOwnProperty('valid') && obj?.valid === true)
      : true;

  useEffect(() => {
    if (typeof window !== 'undefined' && !orderId && !IS_TEST_ENV) {
      window.location.replace(`/${nameUrl}`);
    }
  }, [orderId, IS_TEST_ENV, nameUrl]);

  useEffect(() => {
    if (billPayerError) {
      //   handleErrorDisplay(billPayerError);
      setIsSubmitting(false);
    }
  }, [billPayerError]);
  return (
    <div className='relative pb-64 lg:pb-0'>
      <div className='flex flex-col'>
        <h4 className='text-p-strong sm:text-h4 text-typography'>
          Set up bill payers
        </h4>
        <p className='mt-1 text-sm font-normal text-neutral-600'>
          Next, add your bill payer’s names and mobile phone numbers. We’ll then
          send them a text to ask them to create their accounts
        </p>

        {/* Show AddNewBillPayer component if all bill payers have not been added */}
        <div className='text-typography-secondary mt-8 rounded-lg bg-neutral-100 px-6 py-2 text-lg uppercase'>
          Bill Payers
        </div>
        {billPayers.length > 0 && (
          <BillPayerCard data={billPayers[0]} payerNo={0} mainPayer />
        )}

        {billPayers?.length > 1 &&
          billPayers?.slice(1).map((payer, idx) => (
            <div key={idx}>
              {payer?.valid && (
                <div className='col-span-6 lg:col-span-5'>
                  <BillPayerCard
                    deleteBillPayer={deleteBillPayer}
                    data={payer}
                    payerNo={idx + 1}
                  />
                </div>
              )}
            </div>
          ))}

        {billPayers?.length > 1 &&
          billPayers
            ?.slice(1) // remove main tenant
            ?.map((payer, idx) => (
              <div key={idx}>
                {!payer?.valid && (
                  <AddBillPayer
                    key={idx}
                    payer={payer}
                    payerNo={idx + 1}
                    billPayers={billPayers}
                  />
                )}
              </div>
            ))}

        <div className='mx-auto mt-16 hidden w-full max-w-sm flex-col space-y-4 lg:flex'>
          {/* {noOfBillPayers > 1 && billPayers.length !== noOfBillPayers ? (
            <Button
              variant='tertiary'
              size={'cta'}
              className='mt-4 font-semibold'
              id='submit-billpayers'
              endIcon={<MdSkipNext className='ml-3 h-5 w-5' />}
              isLoading={isSubmitting}
              onClick={proceedNextStep}
            >
              I’ll finish this later
            </Button>
          ) : ( */}
          <Button
            variant='primary'
            size={'cta'}
            id='submit-billpayers'
            className='font-semibold'
            endIcon={<MdArrowForward className='ml-3 h-5 w-5' />}
            isLoading={isSubmitting}
            disabled={!billPayersAreValid}
            onClick={submitAllBillPayers}
          >
            Continue
          </Button>
          {/* )} */}
        </div>
      </div>
      <MobileNavControl
        serviceBill={serviceBill}
        rate={rate}
        setRate={setRate}
        coupon={coupon}
        setCoupon={setCoupon}
        couponApplied={couponApplied}
        setCouponApplied={setCouponApplied}
        setFetchQuote={setFetchQuote}
        fetchingQuote={fetchingQuote}
      >
        <div className='mx-auto flex w-full max-w-sm flex-col'>
          {/* {noOfBillPayers > 1 && billPayers.length !== noOfBillPayers ? (
            <Button
              variant='secondary'
              size={'cta'}
              className='mt-4 font-semibold'
              endIcon={<MdSkipNext className='ml-3 h-5 w-5' />}
              isLoading={isSubmitting}
              onClick={proceedNextStep}
            >
              I’ll finish this later
            </Button>
          ) : ( */}
          <Button
            variant='primary'
            size={'cta'}
            disabled={!billPayersAreValid}
            className='font-semibold'
            endIcon={<MdArrowForward className='ml-3 h-5 w-5' />}
            isLoading={isSubmitting}
            onClick={submitAllBillPayers}
          >
            Continue
          </Button>
          {/* )} */}
        </div>
      </MobileNavControl>
    </div>
  );
}
