import { Button, Skeleton } from 'homebox-ui';
import React from 'react';
import { TrustPilotRatingCard } from '../TrustPilot';

export default function YourHomeMoveCTA({ ctaInfo, showTrustPilot = false }) {
  const { redirectToSetupBills, property, selectedBills, providers } = ctaInfo;
  return (
    <>
      <div className='mx-auto mt-24 flex max-w-xl  flex-col items-center justify-center space-y-3 sm:mt-32 sm:w-4/5'>
        <h1 className='text-center text-2xl font-semibold lg:text-start'>
          Combine your bills into one monthly payment
        </h1>
        {!property && !(selectedBills.length > 0) && !providers ? (
          <Skeleton className='h-10 w-full rounded-xl' />
        ) : (
          <div className='flex flex-col items-center justify-center  space-y-4'>
            <p className='text-center text-base'>
              Homebox can set up and organise all household bills into one
              simple monthly payment for you, specifically for{' '}
              {property?.address_line1 && property.address_city ? (
                <span className='font-semibold'>
                  {property?.address_line1}, {property.address_city},{' '}
                  {property?.address_postcode}.
                </span>
              ) : (
                <span className='font-semibold'>your property</span>
              )}
            </p>

            {showTrustPilot ? (
              <div className='flex justify-center'>
                <TrustPilotRatingCard />
              </div>
            ) : (
              <div>
                <Button
                  variant={null}
                  className='mx-auto w-full bg-[#FFD049] !text-base text-[#252C35] sm:w-auto'
                  onClick={redirectToSetupBills}
                  disabled={selectedBills.length === 0}
                >
                  Get instant bills package quote
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
