import React from 'react';

export default function KeyInformationSkeleton() {
  return (
    <div className='w-full rounded-lg border-[#dbdcdd] bg-[#f4f5f6] p-1 shadow'>
      <div className='flex animate-pulse flex-col p-3 text-gray-100'>
        <div className='flex'>
          <div className='mb-2 h-[25px] w-[150px] rounded bg-gray-100' />
        </div>

        <div className='mt-2 flex h-5 flex-row'>
          <div className='mr-2 h-full w-5 rounded bg-gray-100' />
          <div className='h-full flex-1 rounded bg-gray-100' />
        </div>

        <div className='mt-2 flex h-5 flex-row'>
          <div className='mr-2 h-full w-5 rounded bg-gray-100' />
          <div className='h-full w-2/3 rounded bg-gray-100' />
        </div>

        <div className='mt-2 flex h-5 flex-row'>
          <div className='mr-2 h-full w-5 rounded bg-gray-100' />
          <div className='h-full w-1/2 rounded bg-gray-100' />
        </div>

        <div className='mt-2 flex h-5 flex-row'>
          <div className='mr-2 h-full w-5 rounded bg-gray-100' />
          <div className='h-full flex-1 rounded bg-gray-100' />
        </div>
      </div>
    </div>
  );
}
