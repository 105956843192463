import { createSlice } from '@reduxjs/toolkit';

export const partnerAuthSlice = createSlice({
  name: 'partnerAuth',
  initialState: { partner: null, partnerToken: null },
  reducers: {
    setCredentials: (state, action) => {
      const { partner, partnerPublicToken } = action.payload;
      state.partner = partner;
      state.partnerToken = partnerPublicToken;
    },
  },
});

export const { setCredentials } = partnerAuthSlice.actions;
export default partnerAuthSlice.reducer;

export const selectCurrentPartner = state => state.partnerAuth.partner;
export const selectCurrentPartnerToken = state =>
  state.partnerAuth.partnerToken;
