import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';

export default function Conflict() {
  const navigate = useNavigate();
  const location = useLocation();
  const { nameUrl } = useParams();

  const conflictData = location.state?.conflictData;

  const [conflictInfo, setConflictInfo] = useState({
    setup_users: [],
    full_address: null,
    invited_users: [],
    lead_user_name: null,
  });

  useEffect(() => {
    if (!conflictData) {
      navigate(`/${nameUrl}/setup-account`);
    } else {
      setConflictInfo(conflictData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conflictData]);

  const { lead_user_name, full_address, setup_users, invited_users } =
    conflictInfo;
  return (
    <>
      <Header />
      <>
        <div className='onboarding-bg  screen-without-header flex items-center justify-center bg-neutral-50 bg-cover px-4 py-6 lg:px-10 lg:py-10'>
          <div className='flex h-full w-full items-center'>
            <div className='mx-auto w-full max-w-4xl'>
              <div className='mt-6 flex w-full flex-col  bg-[hsl(var(--success-foreground))]/50 p-8 backdrop-blur '>
                <div className='w-full rounded-lg  bg-white shadow-md'>
                  <div className='mx-auto flex w-full max-w-xl flex-col items-center space-y-4 p-8'>
                    <h1 className='text-h4 text-center font-semibold '>
                      {lead_user_name ?? 'Your housemate'} has generated a new
                      bills package quote
                    </h1>
                    <p className='text-typography-secondary text-center '>
                      {lead_user_name ?? 'Your housemate'} has invited the
                      following tenants to sign up for a bills package at{' '}
                      {full_address}
                    </p>

                    {setup_users.length > 0 && (
                      <div className='w-full max-w-sm'>
                        <h3 className=' text-typography-primary font-semibold'>
                          Setup Housemates
                        </h3>
                        <div className='mx-auto mt-4 flex max-w-xs flex-col space-y-2'>
                          {setup_users.map(user => (
                            <div
                              key={user.id}
                              className='border-border flex justify-between border-b py-4'
                            >
                              <span>
                                {user?.full_name ||
                                  `${user?.first_name} ${user?.last_name}`}
                              </span>

                              <span> {user?.phone_number?.['raw']} </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {invited_users.length > 0 && (
                      <div className='mt-6 w-full max-w-sm'>
                        <h3 className='text-typography-primary font-semibold'>
                          Pending Housemates
                        </h3>
                        <div className='mx-auto mt-2 flex max-w-xs flex-col space-y-2'>
                          {invited_users.map(user => (
                            <div
                              key={user.id}
                              className='border-border flex justify-between border-b py-4'
                            >
                              <span>
                                {user?.full_name ||
                                  `${user?.first_name} ${user?.last_name}`}
                              </span>

                              <span> {user?.phone_number?.['raw']} </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <p className='text-typography-secondary text-center'>
                      If your name is on the above list, please check your SMS
                      messages to complete the onboarding flow.
                    </p>
                    <p className='text-typography-secondary'>
                      You can also get a new quote
                      <Link
                        to='/setup-account'
                        className='text-typography-interactive underline'
                      >
                        {' '}
                        here.{' '}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
