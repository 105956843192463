import { Divider, Skeleton, Tabs, TabsList, TabsTrigger } from 'homebox-ui';
import BillSummaryCard from './BillSummaryCard';
import { useFormikContext } from 'formik';
import { calculatePrice } from '../../utils/functions';
import CouponInput from '../CouponInput';
import Subtract from '../../assets/subtract.svg';
import { useCalculatePricePerHousemate } from '../../hooks/useCalculatePricePerHousemate';
import ManageHouseHoldModal from '../ManageHouseholdModal';

export const QuotePanelContent = ({
  rate,
  setRate,
  step,
  serviceBill,
  coupon,
  setCoupon,
  couponApplied,
  setCouponApplied,
  fetchingQuote,
  setFetchQuote,
}) => {
  const { values } = useFormikContext();
  // const formatted_address = values?.address?.formatted_address;
  const perksSelected = values?.perks ?? [];
  const quoteData = values?.billSelected ?? [];
  const noOfBillPayers = values?.bill_payers;
  const noOfHousemates = values?.total_occupants;
  const isMonthly = rate === 'monthly';

  const { pricePerHousemate } = useCalculatePricePerHousemate(
    quoteData,
    serviceBill,
    noOfHousemates,
    coupon,
    noOfBillPayers
  );

  return (
    <>
      <Tabs
        defaultValue={rate}
        onValueChange={value => setRate(value)}
        value={rate}
        className='l@g:px-16 flex w-full flex-col px-2 py-2'
      >
        <TabsList>
          <TabsTrigger className='w-full' value='monthly'>
            Monthly
          </TabsTrigger>
          <TabsTrigger className='w-full' value='weekly'>
            Weekly
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <div className='@lg:px-4 mt-2 px-1'>
        <div className='flex w-full flex-col items-center justify-center'>
          <div className='w-full'>
            {fetchingQuote ? (
              <Skeleton className='mb-4 min-h-[138px] w-full max-w-2xl rounded-xl' />
            ) : (
              <>
                {quoteData?.length > 0 &&
                  quoteData.map((bill, idx) => (
                    <BillSummaryCard
                      key={idx}
                      bill={bill}
                      group_name={bill?.group_name}
                      isMonthly={isMonthly}
                      noOfHousemates={noOfHousemates}
                      noOfBillPayers={noOfBillPayers}
                      groupIcon={Subtract}
                      couponApplied={couponApplied}
                    />
                  ))}
                {perksSelected.length > 0 && (
                  <BillSummaryCard
                    freeBill={true}
                    group_name={'Free Perks'}
                    billDescription={'Homebox Life Perks'}
                    isMonthly={isMonthly}
                    noOfHousemates={noOfHousemates}
                    noOfBillPayers={noOfBillPayers}
                    price={0}
                    groupIcon={Subtract}
                    couponApplied={couponApplied}
                    coupon={coupon}
                  />
                )}
                {serviceBill.prices && (
                  <BillSummaryCard
                    bill={serviceBill}
                    group_name={'Homebox Service'}
                    isMonthly={isMonthly}
                    noOfHousemates={noOfHousemates}
                    noOfBillPayers={noOfBillPayers}
                    groupIcon={Subtract}
                    couponApplied={couponApplied}
                    coupon={coupon}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* TODO: Update first payment date */}
      {/* <div className='my-6 flex items-center space-x-2 px-4 '>
        <MdCalendarMonth className='h-4 w-4' />
        <p className='text-typography-primary text-sm'>
          <span className='font-medium'>First payment: </span> 1st April, 2023.
        </p>
      </div> */}
      {!fetchingQuote && (
        <div className='@lg:px-4 my-4 px-0 pb-8'>
          <div className='min-h-[4rem] rounded-lg bg-neutral-100 px-6 py-4 text-neutral-600'>
            <div className='flex justify-between'>
              <div className='flex flex-col justify-end space-y-2 text-base'>
                <p className='text-left font-semibold'>Total</p>
                <div className='w-full'>
                  <CouponInput
                    coupon={coupon}
                    setCoupon={setCoupon}
                    couponApplied={couponApplied}
                    setCouponApplied={setCouponApplied}
                  />
                </div>
              </div>
              <div className='flex flex-col space-y-2 '>
                <p className='text-base'>
                  <span className='text-typography font-medium'>
                    £
                    {calculatePrice(
                      Number(pricePerHousemate),
                      noOfHousemates,
                      noOfBillPayers,
                      isMonthly
                    )}
                  </span>
                  {isMonthly ? '/month' : '/week'}
                </p>
                {noOfBillPayers > 1 ? (
                  <p className='text-sm font-normal'>per bill payer</p>
                ) : (
                  <ManageHouseHoldModal
                    setFetchQuote={setFetchQuote}
                    fetchingQuote={fetchingQuote}
                  />
                )}
              </div>
            </div>
            {noOfBillPayers > 1 && (
              <>
                <Divider className='my-2' />
                <div className='flex justify-end text-right'>
                  <div className='flex flex-col space-y-2'>
                    <p className='text-base'>
                      <span className='text-typography font-medium'>
                        {/* Total cost calculation = (value of service charge x no of billpayers) + (pricePerhousemate x no of billpayers) */}
                        £
                        {calculatePrice(
                          Number(pricePerHousemate * noOfBillPayers),
                          noOfHousemates,
                          noOfBillPayers,
                          isMonthly
                        )}
                      </span>
                      {isMonthly ? '/month' : '/week'}
                    </p>
                    <div className='flex text-sm font-normal'>
                      <span>Total cost for</span>
                      <ManageHouseHoldModal
                        setFetchQuote={setFetchQuote}
                        fetchingQuote={fetchingQuote}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
