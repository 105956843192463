import React from 'react';
import { Skeleton } from 'homebox-ui';

export default function BillsLoadingSkeleton() {
  return (
    <>
      {Array(3)
        .fill('')
        .map((n, idx) => (
          <Skeleton
            key={idx}
            className='min-h-[138px] w-full max-w-2xl rounded-xl'
          />
        ))}
    </>
  );
}
