import React, { useEffect, useState } from 'react';
import { calculatePrice } from '../../utils';
import InternetOptionsModal from './InternetOptionsModal';
import KeyInformationSkeleton from '../Skeletons/KeyInformationSkeleton';
import { useGetInternetAvailable } from '../../hooks/useGetInternetAvailable';

const KeyInformation = ({
  selectedBills,
  providers,
  pricePerHousemate,
  housemates,
  property,
  internetAvailable,
  is_student,
}) => {
  const [householdPrice, setHouseholdPrice] = useState(null);
  const [messages, setMessages] = useState([]);
  const [openInternetModal, setOpenInternetModal] = useState(false);

  const { internetOptions, fastestInternet } =
    useGetInternetAvailable(internetAvailable);

  useEffect(() => {
    if (providers && selectedBills.length > 0) {
      loadMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers, selectedBills]);

  useEffect(() => {
    if (pricePerHousemate) {
      setHouseholdPrice(
        Number(
          calculatePrice(pricePerHousemate, housemates, housemates, true) *
            Number(housemates)
        ).toFixed(2)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricePerHousemate]);

  const loadMessages = () => {
    const { council, water } = providers;

    const messages = [];

    messages.push(getEnergyProvidersMessage());
    water && messages.push(`💧 Your water provider is ${water.name}`);
    council &&
      !is_student &&
      messages.push(`🏛️ Your local council is ${council.name}`); // TODO: include price

    setMessages(messages);
  };

  const getEnergyProvidersMessage = () => {
    const energyBill = selectedBills.find(
      bill => bill.group_name === 'Renewable Energy'
    );

    return !!providers?.gas
      ? `🔥 Your electricity and gas provider is ${energyBill.provider}`
      : `🔥 Your electricity provider is ${energyBill.provider}`;
  };

  if (!(messages.length > 0)) {
    return (
      <div className='my-5'>
        <KeyInformationSkeleton />
      </div>
    );
  }

  return (
    <div className='my-5'>
      <h4 className='mb-3 text-lg font-bold'> Key Information: </h4>
      <ul>
        {householdPrice && (
          <li className='mb-3 text-base'>
            💰 Your total expected utility costs will be{' '}
            <span className='font-semibold'>£{householdPrice}/month</span>
          </li>
        )}
        {messages.length > 0 &&
          messages.filter(Boolean).map(info => (
            <li className='mb-3 text-base' key={info}>
              {info}
            </li>
          ))}
        {fastestInternet && (
          <li className='mb-3 text-base'>
            <p>
              ⚡️ Your fastest available internet speed is{' '}
              {fastestInternet.speed} provided by{' '}
              {fastestInternet.providers.join(', ')}.{' '}
              <span
                className='cursor-pointer underline'
                onClick={() => setOpenInternetModal(true)}
              >
                View internet options
              </span>
            </p>
          </li>
        )}
        {openInternetModal && (
          <InternetOptionsModal
            open={openInternetModal}
            setOpen={setOpenInternetModal}
            property={property}
            internetAvailable={internetAvailable}
            internetOptions={internetOptions}
            fastestInternet={fastestInternet}
          />
        )}
      </ul>
    </div>
  );
};

export default KeyInformation;
