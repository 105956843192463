import { Button, Label, RadioGroup, RadioGroupItem } from 'homebox-ui';

export default function LeadLoadingSkeleton() {
  return (
    <div className='w-full rounded-lg border-[#dbdcdd] bg-[#f4f5f6] shadow'>
      <div className='flex animate-pulse flex-col p-3'>
        <div className='mb-[20px] h-[25px] w-1/2 rounded bg-gray-100 sm:w-[150px]' />

        <div className='mb-1 h-[15px] w-1/3 rounded bg-gray-100 sm:w-[60px]' />
        <div className='h-[40px] w-full rounded bg-gray-100 sm:w-[320px]' />

        <div className='my-10 w-full sm:max-w-lg'>
          <div className='h-[20px] w-full rounded bg-gray-100 text-base text-gray-100 sm:w-[330px]' />
          <RadioGroup className='mt-4 flex flex-wrap '>
            {new Array(12).fill('').map((_, idx) => (
              <Label
                key={idx}
                className='mr-[10px] flex h-10 w-10 flex-col items-center justify-center rounded-full border border-gray-100 bg-gray-100 p-2 font-sans text-sm font-medium text-gray-100'
              >
                <RadioGroupItem className='sr-only' />
                {`${idx + 1}`}
              </Label>
            ))}
          </RadioGroup>
        </div>

        <div className='text-center sm:text-left'>
          <Button className='h-[40px] w-1/2 rounded-[32px] bg-gray-100 text-lg text-gray-100 sm:w-[250px]' />
        </div>
      </div>
    </div>
  );
}
