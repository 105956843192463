export const invalidDomains = [
  '0x01.gq',
  '0x01.tk',
  '10mail.org',
  '10mail.tk',
  '33m.co',
  '33mail.com',
  '3dxtras.com',
  '3utilities.com',
  '567map.xyz',
  '8191.at',
  'aa.am',
  'accountsite.me',
  'acmetoy.com',
  'acusupply.com',
  'adultvidlite.com',
  'aji.kr',
  'anonaddy.com',
  'anonaddy.me',
  'anonbox.net',
  'anyalias.com',
  'asanatest1.us',
  'azzawajalla.store',
  'bajetesik.store',
  'band-freier.de',
  'bandband1.com',
  'bangmadid.store',
  'batikbantul.com',
  'bccto.me',
  'bebekpenyet.buzz',
  'bei.kr',
  'bel.kr',
  'beo.kr',
  'bfo.kr',
  'bgsaddrmwn.me',
  'bho.kr',
  'biasaelho.space',
  'biz.st',
  'biz.tm',
  'bko.kr',
  'blacksong.pw',
  'blueauramassage.com',
  'bounceme.net',
  'bum.net',
  'buwosok.tech',
  'buzzndaraiangop2wae.buzz',
  'byui.me',
  'caboodle.buzz',
  'cad.edu.gr',
  'cempue.online',
  'chickenkiller.com',
  'choirul.host',
  'cid.kr',
  'ciran.xyz',
  'cko.kr',
  'cloudns.asia',
  'cloudns.cc',
  'cloudns.cx',
  'cloudns.nz',
  'com.com',
  'coms.hk',
  'comx.cf',
  'craigslist.org',
  'creo.site',
  'creo.tips',
  'creou.dev',
  'crowdpress.it',
  'cu.cc',
  'cua77.xyz',
  'd3vs.net',
  'dadosa.xyz',
  'danuarte.online',
  'darrels.site',
  'daseus.online',
  'dayatan.host',
  'dbo.kr',
  'ddns.net',
  'ddnsfree.com',
  'deail.com',
  'dedyn.io',
  'defaultdomain.ml',
  'discard-email.cf',
  'dko.kr',
  'dlink.cf',
  'dlink.gq',
  'dlyemail.com',
  'dmtc.dev',
  'dmtc.edu.pl',
  'dmtc.press',
  'dns-cloud.net',
  'dns.navy',
  'dnsabr.com',
  'dnses.ro',
  'doy.kr',
  'drope.ml',
  'dropmail.me',
  'dynu.net',
  'dzalaev-advokat.ru',
  'e4ward.com',
  'ediantenan.site',
  'edu.auction',
  'efo.kr',
  'eho.kr',
  'ely.kr',
  'email-temp.com',
  'emailfake.com',
  'emailfake.ml',
  'emailfreedom.ml',
  'emlhub.com',
  'emlpro.com',
  'emltmp.com',
  'emy.kr',
  'enu.kr',
  'eny.kr',
  'epizy.com',
  'escritossad.net',
  'ese.kr',
  'esy.es',
  'ewa.kr',
  'exi.kr',
  'ezyro.com',
  'fackme.gq',
  'fassagforpresident.ga',
  'firste.ml',
  'flu.cc',
  'foy.kr',
  'fr.nf',
  'freeml.net',
  'gadzooks.buzz',
  'gettrials.com',
  'giize.com',
  'gmail.gr.com',
  'gmeil.me',
  'gok.kr',
  'gotdns.ch',
  'gpa.lu',
  'grigio.cf',
  'guardmail.cf',
  'haddo.eu',
  'heliohost.org',
  'higogoya.com',
  'historial.store',
  'hitechinfo.com',
  'hix.kr',
  'hiz.kr',
  'hmail.us',
  'hopto.org',
  'hostingarif.me',
  'idn.vn',
  'iesco.info',
  'igg.biz',
  'ignorelist.com',
  'iki.kr',
  'ilovemyniggers.club',
  'imouto.pro',
  'info.tm',
  'infos.st',
  'irr.kr',
  'isgre.at',
  'it2-mail.tk',
  'jil.kr',
  'jindmail.club',
  'jto.kr',
  'junnuok.com',
  'justemail.ml',
  'kadokawa.top',
  'kantal.buzz',
  'keitin.site',
  'kentel.buzz',
  'kerl.cf',
  'kerl.gq',
  'kikwet.com',
  'kondomeus.site',
  'kozow.com',
  'kranjingan.store',
  'kranjingan.tech',
  'kranjingans.tech',
  'kro.kr',
  'lal.kr',
  'laste.ml',
  'lbe.kr',
  'legundi.site',
  'lei.kr',
  'likevip.net',
  'liopers.link',
  'lko.co.kr',
  'lko.kr',
  'll47.net',
  'lofteone.ru',
  'lom.kr',
  'longdz.site',
  'longmusic.com',
  'lostandalone.com',
  'loudcannabisapp.com',
  'loy.kr',
  'loyalherceghalom.ml',
  'luk2.com',
  'luksarcenter.ru',
  'luo.kr',
  'lyrics-lagu.me',
  'mail-temp.com',
  'mail0.ga',
  'mailinator.com',
  'mailr.eu',
  'marrone.cf',
  'mbe.kr',
  'mblimbingan.space',
  'mebelnovation.ru',
  'mefound.com',
  'mintemail.com',
  'mishmash.buzz',
  'mko.kr',
  'mlo.kr',
  'mooo.com',
  'motifasidiri.website',
  'mp-j.cf',
  'mp-j.ga',
  'mp-j.gq',
  'mp-j.ml',
  'mp-j.tk',
  'mr-meshkat.com',
  'mrossi.cf',
  'mrossi.gq',
  'mrossi.ml',
  'ms1.email',
  'msdc.co',
  'muabanwin.net',
  'museumplanet.com',
  'my.id',
  'my3mail.cf',
  'my3mail.ga',
  'my3mail.gq',
  'my3mail.ml',
  'my3mail.tk',
  'myddns.me',
  'myeslbookclub.com',
  'mymy.cf',
  'mysafe.ml',
  'mzon.store',
  'n-e.kr',
  'nafko.cf',
  'nctu.me',
  'netmail.tk',
  'netricity.nl',
  'new-mgmt.ga',
  'ngalasmoen.xyz',
  'ngguwokulon.online',
  'njambon.space',
  'nko.kr',
  'now.im',
  'npv.kr',
  'nuo.co.kr',
  'nuo.kr',
  'nut.cc',
  'o-r.kr',
  'oazis.site',
  'obo.kr',
  'ocry.com',
  'office.gy',
  'okezone.bid',
  'one.pl',
  'onlysext.com',
  'oovy.org',
  'oppoesrt.online',
  'orangotango.ml',
  'otherinbox.com',
  'ourhobby.com',
  'owa.kr',
  'owh.ooo',
  'oyu.kr',
  'p-e.kr',
  'pafnuty.com',
  'pandies.space',
  'paqeh.online',
  'pe.hu',
  'petinggiean.tech',
  'peyekkolipi.buzz',
  'poderosamulher.com',
  'poistaa.com',
  'porco.cf',
  'poy.kr',
  'prapto.host',
  'probatelawarizona.com',
  'ptcu.dev',
  'pubgm.website',
  'qbi.kr',
  'qc.to',
  'r-e.kr',
  'ragel.me',
  'rao.kr',
  'reilis.site',
  'rf.gd',
  'ringen.host',
  'rko.kr',
  'rosso.ml',
  'row.kr',
  'rr.nu',
  'rshagor.xyz',
  's-ly.me',
  'safe-mail.gq',
  'sagun.info',
  'samsueng.site',
  'saucent.online',
  'sborra.tk',
  'schwarzmail.ga',
  'seluang.com',
  'sempak.link',
  'sendaljepit.site',
  'sendangagung.online',
  'servegame.com',
  'shp7.cn',
  'siambretta.com',
  'skodaauto.cf',
  'soju.buzz',
  'solidplai.us',
  'somee.com',
  'spamtrap.ro',
  'spymail.one',
  'ssanphone.me',
  'standeight.com',
  'statuspage.ga',
  'steakbeef.site',
  'stonedogdigital.com',
  'stop-my-spam.pp.ua',
  'storeyee.com',
  'sumanan.site',
  'supere.ml',
  'svblog.com',
  'sytes.net',
  'tandy.co',
  'tangtingtung.tech',
  'teml.net',
  'tempembus.buzz',
  'tempremail.cf',
  'tempremail.tk',
  'tgwrzqr.top',
  'thepieter.com',
  'theworkpc.com',
  'thinktimessolve.info',
  'thumoi.com',
  'tko.co.kr',
  'tko.kr',
  'tmo.kr',
  'tmpeml.com',
  'toh.info',
  'toi.kr',
  'tomcrusenono.host',
  'topikurrohman.xyz',
  'tourbalitravel.com',
  'traveldesk.com',
  'tricakesi.store',
  'trillianpro.com',
  'twilightparadox.com',
  'tyrex.cf',
  'uha.kr',
  'uk.to',
  'uko.kr',
  'umy.kr',
  'unaux.com',
  'undo.it',
  'uny.kr',
  'uola.org',
  'upy.kr',
  'urbanban.com',
  'us.to',
  'usa.cc',
  'uu.gl',
  'uvy.kr',
  'uyu.kr',
  'vay.kr',
  'vba.kr',
  'veo.kr',
  'viola.gq',
  'vivoheroes.xyz',
  'vkbags.in',
  'vo.uk',
  'volvo-xc.tk',
  'vuforia.us',
  'wakultimbo.buzz',
  'web.id',
  'weprof.it',
  'werkuldino.buzz',
  'wil.kr',
  'wingkobabat.buzz',
  'x24hr.com',
  'xiaomie.store',
  'xo.uk',
  'xxi2.com',
  'yarien.eu',
  'yawahid.host',
  'ye.vc',
  'yertxenor.tk',
  'yomail.info',
  'yopmail.com',
  'yoqoyyum.space',
  'youdontcare.com',
  'zalvisual.us',
  'zapto.org',
  'ze.cx',
  'zeroe.ml',
];
