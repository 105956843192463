import { useEffect, useState } from 'react';

export const useExtractQuoteBills = customPackage => {
  const [essentials, setEssentials] = useState([]);
  const [extras, setExtras] = useState([]);
  const [perks, setPerks] = useState([]);
  const [serviceBill, setServiceBill] = useState({});

  const [customBills, setCustomBills] = useState([]);

  const [isExtractBillSuccess, setIsExtractBillSuccess] = useState(false);

  useEffect(() => {
    setIsExtractBillSuccess(false);
    if (customPackage?.addons?.length > 0) {
      setCustomBills(processGroupBills(customPackage?.addons));
    }

    if (customPackage?.perks?.length > 0) {
      setPerks(processPerkBills(customPackage?.perks));
    }

    if (customPackage?.bills?.length > 0) {
      setServiceBill(
        customPackage?.bills?.find(bill => bill?.name === 'Service Charge')
      );
    }
  }, [customPackage]);

  useEffect(() => {
    if (customBills?.length > 0) {
      setEssentials(customBills.filter(q => q.is_essential));
      setExtras(customBills?.filter(q => !q.is_essential));
      setIsExtractBillSuccess(true);
    }
  }, [customBills]);

  return {
    essentials,
    extras,
    perks,
    serviceBill,
    isExtractBillSuccess,
  };
};

const processGroupBills = addons => {
  if (addons?.length < 1) {
    return [];
  }

  let result = [];

  do {
    let bills = [];
    let currentGroupId = addons?.[0]?.group_id;
    let currentGroupName = addons?.[0]?.title;
    let isEssential = addons?.[0]?.is_essential;

    addons?.length > 0 &&
      addons?.forEach(bill => {
        if (currentGroupId === bill?.group_id) {
          bills.push({ ...bill, group_name: currentGroupName });
        }
      });

    result?.push({
      bills,
      id: currentGroupId,
      name: currentGroupName,
      is_essential: isEssential,
    });

    addons = addons?.filter(bill => bill.group_id !== currentGroupId); // shorten array as loop through
  } while (addons?.length);

  return result.sort((a, b) => a?.id - b?.id); // sort group_id in order
};

const processPerkBills = perks => {
  if (perks?.length < 1) {
    return [];
  }

  let result = [];

  do {
    let currentPerk = perks?.[0];

    result.push({
      bills: [
        {
          ...currentPerk,
          group_name: currentPerk?.title,
          description: currentPerk?.bill_description,
          short_description: currentPerk?.bill_short_description,
        },
      ],
      id: currentPerk?.group_id,
      name: currentPerk?.name,
      is_essential: true,
    });

    perks = perks?.filter(bill => bill.id !== currentPerk?.id); // shorten array as loop through
  } while (perks?.length);

  return result;
};
