import React from 'react';
import { billsIncludedInPackage, howItWorks, reviews } from '../../utils';
import { Button } from 'homebox-ui';
import Logo from '../Logo';
import { TrustPilotRatingCard, TrustPilotReviewCard } from '../TrustPilot';
import { YourHomeMoveFAQs } from '../FAQs';
import YourHomeMoveCTA from './YourHomeMoveCTA';

export default function YourHomeMoveInfoSection({ ctaInfo }) {
  const { redirectToSetupBills } = ctaInfo;
  return (
    <div className='mx-auto flex max-w-full flex-col space-y-32 lg:max-w-6xl'>
      <YourHomeMoveCTA ctaInfo={ctaInfo} showTrustPilot={true} />
      {/* How it Works Section */}
      <div className='flex w-full flex-col space-y-16'>
        <h1 className='text-center text-2xl font-semibold'>How it works:</h1>

        <div className='grid grid-cols-1 gap-12  lg:grid-cols-3'>
          {howItWorks.map(({ headerTitle, headerDescription }, idx) => (
            <div
              key={idx}
              className='flex flex-col items-center space-y-3 px-4 text-center'
            >
              <div className='flex h-[49px] w-[49px] items-center justify-center rounded-full bg-[#90ADF0] text-2xl font-bold text-white'>
                {idx + 1}
              </div>
              <h4 className='text-lg font-semibold'>{headerTitle}</h4>
              <p className='max-w-sm text-base '>{headerDescription}</p>
            </div>
          ))}
        </div>
      </div>
      {/* How it Works Section */}

      {/* Trust Pilot Section */}
      <div className='flex w-full flex-col space-y-16 '>
        <div className='text-center lg:text-start '>
          <h2 className='text-2xl font-semibold'>
            What customers say about us
          </h2>
          <p className='text-base'>
            We do our best to provide you the best experience ever
          </p>
        </div>

        <div className='grid grid-cols-1  gap-8 sm:grid-cols-2 lg:grid-cols-4'>
          {reviews.map((review, idx) => (
            <TrustPilotReviewCard key={`item-${idx}`} {...review} />
          ))}
        </div>
      </div>
      {/* Trust Pilot Section */}

      {/* Features Section */}
      <div className='flex flex-col space-y-16 lg:flex-row lg:space-x-20 lg:space-y-0'>
        <div className='flex w-full flex-col items-center space-y-5 lg:w-1/3 lg:items-start'>
          <h5 className='text-center text-2xl font-semibold lg:text-left'>
            What’s included in the bills package?
          </h5>
          <p className='text-center text-base lg:text-left'>
            You can pick and choose what you want. Here are all the possible
            options.
          </p>
          <div>
            <Button
              variant={null}
              className='mx-auto w-full bg-[#FFD049] !text-base text-[#252C35] sm:w-auto'
              onClick={redirectToSetupBills}
            >
              Get instant bills package quote
            </Button>
          </div>
        </div>
        <div className='flex w-full  flex-col items-center justify-center gap-0 space-y-16 sm:grid sm:grid-cols-2 lg:w-2/3 lg:grid-cols-2 lg:gap-16 lg:space-y-0'>
          {billsIncludedInPackage.map(({ title, description, emoji }, idx) => (
            <div
              key={idx}
              className='flex max-w-sm flex-col justify-between space-y-4 text-center lg:text-left'
            >
              <h2 className='text-5xl font-bold'>{emoji}</h2>
              <h4 className='text-lg font-semibold'>{title}</h4>
              <p className='text-sm'>{description}</p>
            </div>
          ))}
        </div>
      </div>
      {/* Features Section */}

      {/* What is Homebox */}
      <div className='flex flex-col'>
        <Logo className='h-24 object-contain' />

        <div className='mx-auto mt-10 flex max-w-2xl flex-col items-center justify-center space-y-6 text-center lg:text-start'>
          <div className='w-full'>
            <h2 className='text-2xl font-semibold'>Who are Homebox?</h2>
          </div>

          <div className='flex flex-col items-center justify-center space-y-4 text-center lg:items-start lg:text-start'>
            <p>
              Founded in 2016, our journey began with a straightforward mission:
              to simplify the process of splitting payments within a single
              household.
            </p>

            <p>
              The success of this endeavour propelled us forward, and today, we
              proudly oversee the bill management of thousands of customers
              across the UK.
            </p>
          </div>

          <div className='flex justify-center'>
            <TrustPilotRatingCard />
          </div>
        </div>
      </div>
      {/* What is Homebox */}

      {/* FAQs */}
      <div className='flex w-full flex-col items-center space-y-5'>
        <h1 className='text-center text-2xl font-semibold lg:text-start'>
          Frequently Asked Questions{' '}
        </h1>
        <p className='text-center text-base lg:text-start'>
          We do our best to provide you the best experience ever
        </p>
        <YourHomeMoveFAQs />
      </div>
      {/* FAQs */}

      {/* CTA */}
      <YourHomeMoveCTA ctaInfo={ctaInfo} />
    </div>
  );
}
