import React from 'react';

export default function FormErrorMessage(props) {
  const { errors, name, prefix } = props;
  return (
    <>
      {errors?.[name] && (
        <span className='text-error text-xs' {...props}>
          {prefix} {errors?.[name]}
        </span>
      )}
    </>
  );
}
