import React, { useContext, createContext } from 'react';

export const GlobalConfigContext = createContext();

export const useGlobalConfig = () => {
  return useContext(GlobalConfigContext);
};

export const GlobalConfigProvider = ({ config, children }) => {
  const [globalConfig, setGlobalConfig] = React.useState(config || {});

  const updateConfig = values => {
    setGlobalConfig(prevConfig => ({ ...prevConfig, ...values }));
  };

  return (
    <GlobalConfigContext.Provider
      value={{ config: globalConfig, updateConfig }}
    >
      {children}
    </GlobalConfigContext.Provider>
  );
};
