import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { useScrollReset } from './hooks/ScrollReset';
import { Toaster } from 'react-hot-toast';
import { useGlobalConfig } from './context';

function App({ basePath = 'setup-account' }) {
  useScrollReset();
  const { config } = useGlobalConfig();
  // decide base path from global config
  const content = useRoutes(routes(basePath, config?.partnerId || 'homebox'));
  return (
    <div className='@container' id='hb-container'>
      <div className='@xs:w-full'>
        <Toaster />
        {content}
      </div>
    </div>
  );
}

export default App;
