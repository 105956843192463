import React from 'react';
import TrustPilotStar from '../../assets/trustpilot-star.svg';
import TrustPilotStars from '../../assets/trustpilot-stars.svg';

export const TrustPilotRatingCard = () => {
  return (
    <div className='flex max-h-10 flex-col items-center space-x-3 space-y-2 lg:flex-row lg:space-y-0'>
      <div className='flex space-x-3'>
        <span className='font-semibold'>Excellent</span>

        <img src={TrustPilotStars} alt='Trust Pilot Stars' />
      </div>

      <div className='flex space-x-3'>
        <a
          href='https://www.trustpilot.com/review/homebox.co.uk?replies=true&stars=5'
          className='font-semibold no-underline hover:underline'
        >
          120+ Reviews on{' '}
        </a>
        <div className='flex space-x-1'>
          <img src={TrustPilotStar} alt='Trust Pilot Star' />
          <span className=' font-semibold'>Trustpilot</span>
        </div>
      </div>
    </div>
  );
};
