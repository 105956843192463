import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from 'src/utils/helpers';
import {
  HomeboxDefault,
  HomeboxPay,
  HomeboxPro,
  HomeboxListings,
} from './logos';

type LogoSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type LogoVariant = 'default' | 'pay' | 'pro' | 'listings';

const LogoVariantComponent: Record<LogoVariant, React.ElementType> = {
  default: HomeboxDefault,
  pay: HomeboxPay,
  pro: HomeboxPro,
  listings: HomeboxListings,
};

export interface LogoProps {
  dark?: boolean;
  size?: LogoSize;
  variant: LogoVariant;
  className?: string;
  children?: string | React.ReactNode;
}

const Logo = React.forwardRef<React.ElementType, LogoProps>(
  ({ variant = 'default', ...props }, ref) => {
    let Comp = LogoVariantComponent[variant];
    return <Comp ref={ref} {...props} />;
  }
);

Logo.displayName = 'Logo';

export { Logo };
