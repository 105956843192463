import React from 'react';
import { QuotePanelContent } from './QuotePanelContent';

export default function QuotePanel({
  rate = 'monthly',
  setRate,
  step,
  serviceBill,
  coupon,
  setCoupon,
  couponApplied,
  setCouponApplied,
  fetchingQuote,
  setFetchQuote,
}) {
  return (
    <div className='border-background-secondary  w-full border-t-4 bg-white'>
      <div className='pt-3'>
        {/* <p className='text-typography text-lg font-semibold'>Bill Summary</p> */}
        <QuotePanelContent
          rate={rate}
          setRate={setRate}
          step={step}
          serviceBill={serviceBill}
          coupon={coupon}
          setCoupon={setCoupon}
          couponApplied={couponApplied}
          setCouponApplied={setCouponApplied}
          fetchingQuote={fetchingQuote}
          setFetchQuote={setFetchQuote}
        />
      </div>
    </div>
  );
}
