import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from 'src/utils/helpers';

const textareaVariants = cva(
  'border-none bg-neutral-100 ring-white placeholder:text-neutral-400 focus-visible:ring-primary-lighter flex min-h-[80px] w-full rounded-md px-3 py-2 text-sm focus-visible:outline-none focus-visible:ring-4 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      error: {
        true: 'text-error-800 focus-visible:text-neutral-900 focus-visible:ring-error-light bg-error-light placeholder:text-error-800 disabled:opacity-60',
      },
    },
    defaultVariants: {
      error: false,
    },
  }
);
export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaVariants> {
  className?: string;
  error?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, error, ...props }, ref) => {
    return (
      <textarea
        className={cn(textareaVariants({ className, error }))}
        ref={ref}
        {...props}
      />
    );
  }
);

Textarea.displayName = 'Textarea';

export { Textarea };
