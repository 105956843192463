import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobalConfig } from '../context';
import config from '../app/config';

export const useScrollReset = () => {
  const location = useLocation();

  const {
    config: { HTMLTarget },
  } = useGlobalConfig();

  useEffect(() => {
    const targetDiv = document.getElementById(HTMLTarget);
    const path = location.pathname.split('/').pop();

    if (targetDiv && !(path === 'welcome' && config.BUILD_MODE === 'widget')) {
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.pathname, HTMLTarget]);

  return null;
};
