import { useEffect, useState } from 'react';
import { internetOptions, providerMap } from '../utils';

export const useGetInternetAvailable = (internetAvailable = {}) => {
  const [fastestInternet, setFastestInternet] = useState(null);

  useEffect(() => {
    if (!internetAvailable) return;

    setFastestInternetBill();
    setAvailableInternet();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internetAvailable]);

  const setFastestInternetBill = () => {
    let availableSpeed = null;

    for (let i = internetOptions.length - 1; i >= 0; i--) {
      const option = internetOptions[i];
      if (checkIfInternetAvailable(option.values)) {
        availableSpeed = option;
        break;
      }
    }

    setFastestInternet(availableSpeed);
  };

  const setAvailableInternet = () => {
    internetOptions.forEach(option => {
      option.isAvailable = checkIfInternetAvailable(option.values);
      option.providers = getProviders(option.values);
    });
  };

  const checkIfInternetAvailable = values => {
    return Object.values(internetAvailable)?.length > 0
      ? Object.values(internetAvailable).some(value =>
          values.some(mainValue => mainValue === value)
        )
      : false;
  };

  const getProviders = values => {
    let providers = [];

    values.length > 0 &&
      values.forEach(value => {
        if (
          Object.values(internetAvailable)?.length > 0 &&
          Object.values(internetAvailable).includes(value)
        ) {
          providers.push(getProviderName(value));
        }
      });

    return providers;
  };

  const getProviderName = value => {
    for (const key in providerMap) {
      if (value.includes(key)) {
        return providerMap[key];
      }
    }
  };

  return { internetOptions, fastestInternet };
};
