import * as React from 'react';
import { calculateBillPrice, calculatePrice } from '../../utils/functions';

const BillSummaryCard = ({
  divider = true,
  isMonthly,
  bill,
  group_name,
  billDescription = '',
  noOfBillPayers,
  price,
  groupIcon = null,
  couponApplied = false,
  coupon = null,
  noOfHousemates,
}) => {
  return (
    <>
      <div className='min-h-16 mt-4 flex justify-between py-2 text-sm text-neutral-600'>
        <div className='@lg:max-w-[200px] flex max-w-[160px] flex-col space-y-2 text-left'>
          <p className='text-typography-primary font-medium'>{group_name}</p>
          <p className='text-typography-secondary'>
            <>
              {bill?.group_id && (
                <>
                  {bill.group_id !== 4 && bill.group_id !== 8 && bill.provider}
                  {bill.group_id === 5 && ` • ${bill.name}`}
                  {bill.group_id === 4 && (
                    <>
                      By including TV License, your <strong>first</strong>{' '}
                      monthly payment will be higher
                    </>
                  )}
                </>
              )}
              {billDescription}
            </>
          </p>
        </div>
        {couponApplied &&
        groupIcon &&
        group_name === 'Homebox Service' &&
        coupon?.promotion_type === 'management-fee-discount' ? (
          <img className='ml-auto mr-2 h-5 w-5' alt='' src={groupIcon} />
        ) : (
          ''
        )}
        <div className='flex w-28 flex-col space-y-2 '>
          <>
            <span className='text-typography-primary font-medium'>
              £
              {price !== undefined
                ? calculatePrice(
                    price,
                    noOfHousemates,
                    noOfBillPayers,
                    isMonthly
                  )
                : calculateBillPrice(
                    bill,
                    noOfHousemates,
                    noOfBillPayers,
                    isMonthly,
                    coupon
                  )}
            </span>
            {isMonthly ? '/month' : '/week'}
          </>
          {Number(noOfBillPayers) > 1 && <p>per bill payer</p>}
        </div>
      </div>
    </>
  );
};

export default BillSummaryCard;
