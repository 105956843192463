import { useState, useEffect } from 'react';
import { useGlobalConfig } from '../context';

export const useContainerSize = () => {
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const { config } = useGlobalConfig();

  useEffect(() => {
    const handleResize = () => {
      const rootElement = document.getElementById(
        config?.HTMLTarget || 'hb-container'
      );
      setContainerSize({
        width: rootElement?.clientWidth,
        height: rootElement?.clientHeight,
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [config]);

  return containerSize;
};
