import React from 'react';
import {
  Button,
  cn,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from 'homebox-ui';
import { calculateBillPrice } from '../../utils/functions';

export function EnergyInfoCard({ bill }) {
  return (
    <>
      <div className='flex flex-col items-center space-y-4 lg:flex-row lg:items-start lg:space-x-4 lg:space-y-0'>
        <div className='flex flex-col space-y-2'>
          <div className='h-16 w-16'>
            <img
              src={bill?.logo_path}
              alt={`${bill?.provider} logo`}
              className='h-full w-full cursor-pointer rounded-2xl object-contain shadow-lg'
            />
          </div>
        </div>

        <div className='flex flex-col space-y-6'>
          <div className='flex w-full space-x-4'>
            <div className='flex w-full flex-col space-y-1'>
              <p className='text-typography text-center text-base font-semibold lg:text-left'>
                {bill?.label ? bill.label : bill?.name}
              </p>
              <p className='text-typography-secondary text-p-tiny text-center sm:text-sm lg:text-left'>
                {bill?.short_description
                  ? bill?.short_description
                  : `Provided by ${bill?.provider}`}
              </p>
            </div>
          </div>

          <div className='flex flex-col space-y-6'>
            <p
              className='text-typography-secondary text-p-tiny text-left sm:text-sm [&>p]:pb-2'
              dangerouslySetInnerHTML={{ __html: bill?.description_html }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default function EnergyPlansCard({
  choosePlan,
  bill,
  isBillSelected,
  isPerk,
  isMonthly,
  noOfHousemates,
  noOfBillPayers,
}) {
  const isSelected = isBillSelected(bill?.id, isPerk);

  return (
    <div
      className={cn(
        'border-border bg-background-secondary w-full rounded-lg border px-4 py-6',
        !isSelected && `bg-background-foundation border-border-light`
      )}
    >
      <div className='flex flex-col space-y-6'>
        {/*  */}
        <div className='flex flex-col items-center space-y-4 lg:flex-row lg:items-start lg:space-x-4 lg:space-y-0'>
          <div className='flex flex-col space-y-2'>
            <div className='h-16 w-16'>
              <img
                src={bill?.logo_path}
                alt={`${bill?.provider} logo`}
                className='h-full w-full cursor-pointer rounded-2xl object-contain shadow-lg'
              />
            </div>
          </div>
          <div className='flex flex-col space-y-6'>
            <div className='flex w-full space-x-4'>
              <div className='flex w-full flex-col space-y-1'>
                <p className='text-typography text-center text-base font-semibold lg:text-left'>
                  {bill?.label ? bill.label : bill?.name}
                </p>
                <p className='text-typography-secondary text-p-tiny text-center sm:text-sm lg:text-left'>
                  {bill?.short_description
                    ? bill?.short_description
                    : `Provided by ${bill?.provider}`}
                </p>
              </div>
            </div>
            <div className='flex w-full flex-col space-y-4'>
              <div>
                <Dialog>
                  <DialogTrigger asChild>
                    <div className='flex justify-center lg:items-start lg:justify-normal'>
                      <button
                        // variant='link'
                        className='text-typography-interactive text-p-sm cursor-pointer p-0 font-semibold underline-offset-2 hover:underline sm:text-base'
                      >
                        Learn more
                      </button>
                    </div>
                  </DialogTrigger>
                  <DialogContent className='overflow-y-scroll sm:max-w-2xl'>
                    <DialogHeader>
                      <DialogTitle className='text-center'>
                        {bill?.name}
                      </DialogTitle>
                    </DialogHeader>
                    <DialogDescription className='max-h-[75vh] p-2'>
                      <EnergyInfoCard bill={bill} />
                      <DialogFooter className='py-4'>
                        <DialogTrigger asChild>
                          <Button variant={'secondary'} type='button'>
                            Close
                          </Button>
                        </DialogTrigger>
                      </DialogFooter>
                    </DialogDescription>
                  </DialogContent>
                </Dialog>
              </div>
              <div className='flex w-full flex-col items-center justify-between space-y-6 lg:flex-row lg:space-y-0'>
                <div>
                  <p className='text-sm font-medium'>
                    <span className='text-typography font-semibold'>
                      £
                      {calculateBillPrice(
                        bill,
                        noOfHousemates,
                        noOfBillPayers,
                        isMonthly
                      )}
                    </span>
                    <span className='text-typography-secondary'>
                      {isMonthly ? '/month' : '/week'}
                    </span>
                    {Number(noOfBillPayers) > 1 && (
                      <p className='text-p-tiny text-typography-secondary font-normal sm:text-sm'>
                        per bill payer
                      </p>
                    )}
                  </p>
                </div>
                <Button
                  variant={isSelected ? 'tertiary' : 'primary'}
                  onClick={() => choosePlan(bill)}
                  className='w-full sm:w-auto lg:ml-4'
                >
                  {isSelected ? 'Your Plan' : 'Choose This Plan'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
