import React, { useEffect, useState } from 'react';
import { AddressInput } from '@sunrise/components';
import { Button, Label, RadioGroup, RadioGroupItem } from 'homebox-ui';
import {
  useCreatePropertyMutation,
  useGetLeadQuery,
  useUpdateLeadMutation,
} from '../../app/services';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorMessage, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import useLoadPartner from '../../hooks/useLoadPartner';
import Logo from '../../components/Logo';
import LeadLoadingSkeleton from '../../components/Skeletons/LeadLoadingSkeleton';
import { usefulInformation } from '../../utils';
import { useGlobalConfig } from '../../context';
import toast from 'react-hot-toast';

export default function LeadPage() {
  const [urlParams] = useSearchParams();
  const leadId = urlParams.get('lead_id');
  const userId = urlParams.get('user_id');

  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { config } = useGlobalConfig();
  const [loadPartner] = useState(!config?.widgetMode);

  const {
    partner,
    nameUrl,
    isLoading: loadingLogo,
    partnerError,
  } = useLoadPartner(true);
  const baseUrl = `/${nameUrl}`;

  const { data: lead } = useGetLeadQuery(
    {
      leadId,
    },
    { skip: !leadId || !partner }
  );
  const [loading, setLoading] = useState(leadId);

  const [updateLead] = useUpdateLeadMutation();
  const [createProperty, { isLoading, error: createPropertyError }] =
    useCreatePropertyMutation();

  useEffect(() => {
    if (createPropertyError) {
      toast.error(
        createPropertyError?.data?.message ??
          'Something went wrong. Please try again.'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPropertyError]);

  useEffect(() => {
    if (lead) {
      if (userId) {
        setUser(lead.tenants.find(tenant => tenant.id === Number(userId)));
      }
      setLoading(false);
    }
  }, [lead, userId]);

  useEffect(() => {
    if (partnerError && nameUrl !== 'homebox') {
      navigate(`/${config?.partnerId || 'homebox'}/welcome`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerError, nameUrl, config?.partnerId]);

  const validationSchema = Yup.object({
    address: Yup.object({
      property_id: Yup.string(),
      external_id: Yup.string(),
      line1: Yup.string().test(
        'line1-conditional-required',
        'Address is required.',
        function (value) {
          const { external_id, property_id } = this.parent;

          if ((!external_id || !external_id?.trim()) && !property_id) {
            return !!value;
          }

          return true;
        }
      ),
      line2: Yup.string(),
      city: Yup.string().test(
        'city-conditional-required',
        'City is required.',
        function (value) {
          const { external_id, line1, property_id } = this.parent;

          if (
            (!external_id || !external_id?.trim()) &&
            line1?.trim() &&
            !property_id
          ) {
            return !!value;
          }

          return true;
        }
      ),
      postcode: Yup.string().test(
        'postcode-conditional-required',
        'Postcode is required.',
        function (value) {
          const { external_id, line1, property_id } = this.parent;

          if (
            (!external_id || !external_id?.trim()) &&
            line1?.trim() &&
            !property_id
          ) {
            return !!value;
          }

          return true;
        }
      ),
    }),
    total_occupants: Yup.string().required('Number of tenants is required.'),
    household_type: Yup.string().required('Household type is required.'),
  });

  const initialValues = {
    address: {
      property_id: lead?.property?.is_manually_verified ? lead.property.id : '',
      external_id: '',
    },
    total_occupants: '',
    household_type: 'professional-tenant',
  };

  const handleSubmit = async ({ address, total_occupants, household_type }) => {
    let url = `${baseUrl}/your-home-move`;

    let property_id = address.property_id;

    if (!property_id) {
      try {
        const payload = {
          address: address.external_id
            ? { address_id: address.external_id }
            : {
                line1: address.line1,
                line2: address.line2,
                city: address.city,
                postcode: address.postcode,
              },
        };

        const {
          data: { property },
        } = await createProperty(payload);

        property_id = property.id;
      } catch (error) {
        return;
      }
    }

    if (lead && !lead.property) {
      updateLead({
        leadId,
        property_id,
      });
    }

    let params = {
      no_of_tenants: total_occupants,
      household_type: household_type,
      property_id,
    };

    const encodedParams = Object.keys(params)
      .map(
        key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      )
      .join('&');

    url += '?' + encodedParams;

    navigate(url, { state: { user, lead } });
  };
  return (
    <div className='flex flex-col items-center p-4'>
      {/* {config.widgetMode && !loadPartner  && (
        <GetStartedWidgetPopup setLoadPartner={setLoadPartner} />
      )} */}
      <div>
        <div className='flex justify-center'>
          {loadingLogo ? (
            <div className='animate-pulse'>
              <div className='h-14 w-[130px] rounded-lg border-[#dbdcdd] bg-[#f4f5f6] shadow'></div>
            </div>
          ) : (
            <Logo loadPartner={loadPartner} />
          )}
        </div>
        <h4 className='mt-5 text-center text-[28px] font-semibold sm:text-left'>
          Welcome to your new home{user ? `, ${user?.first_name}` : ''} 👋
        </h4>

        <p className='mt-5 text-center text-lg font-normal sm:text-left'>
          We’ve put together some helpful information for your home move:
        </p>

        <ul className='mt-5 px-3 text-base sm:p-0'>
          {usefulInformation.map((info, idx) => (
            <li key={idx} className='mb-2'>
              {info}
            </li>
          ))}
        </ul>

        {loading ? (
          <div className='mt-10'>
            <LeadLoadingSkeleton />
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <LeadForm property={lead?.property} isLoading={isLoading} />
              </form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}

const LeadForm = ({ property, isLoading }) => {
  const { values, setFieldValue } = useFormikContext();

  const fullAddress = property
    ? property?.address_line1 +
      ', ' +
      (property?.address_line2 ? property?.address_line2 + ', ' : '') +
      property?.address_city +
      ', ' +
      (property?.address_county ? property?.address_county + ', ' : '') +
      property?.address_postcode
    : '';

  return (
    <>
      <div className='my-10 flex w-full flex-col sm:max-w-xs'>
        <Label className='text-bold mb-3 text-base'>
          Confirm your address:
        </Label>
        <AddressInput
          defaultAddress={fullAddress}
          preselectDefaultAddress={!!property?.is_manually_verified}
          address={values.address}
          onAddressSelectChange={val => {
            setFieldValue('address', val);
          }}
        />
        {['address.line1', 'address.city', 'address.postcode'].map(
          (field, idx) => {
            return (
              <ErrorMessage
                component='span'
                className='text-error text-xs'
                name={field}
                key={idx}
              />
            );
          }
        )}
      </div>

      <div className='my-10 w-full sm:max-w-lg'>
        <Label htmlFor='total_occupants' className='text-base'>
          How many people will be living in your home?
        </Label>
        <RadioGroup
          id='total_occupants'
          name='total_occupants'
          value={values?.total_occupants}
          onValueChange={val => setFieldValue('total_occupants', val)}
          className='mt-4 flex flex-wrap'
        >
          {new Array(12).fill('').map((_, idx) => (
            <Label
              htmlFor={`${idx + 1}-housemates`}
              key={`${idx + 1}-housemates`}
              className='hover:bg-button-primary-hover ring-primary-lighter border-neutral bg-background-secondary [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark [&:has([data-state=checked])]:hover:bg-primary-dark  mr-[10px] flex h-10 w-10 cursor-pointer flex-col items-center justify-center rounded-full border p-2 font-sans text-sm font-medium text-neutral-600 hover:bg-neutral-200 focus:ring-4 focus-visible:outline-none focus-visible:ring-offset-0 peer-disabled:bg-neutral-100'
            >
              <RadioGroupItem
                value={`${idx + 1}`}
                id={`${idx + 1}-housemates`}
                className='sr-only'
              />
              {`${idx + 1}`}
              {idx + 1 === 12 && '+'}
            </Label>
          ))}
        </RadioGroup>
        <ErrorMessage
          component='span'
          className='text-error text-xs'
          name='total_occupants'
        />
      </div>

      <div className='mb-10 w-full sm:max-w-lg'>
        <Label className='font-sans'>What type of resident are you?</Label>
        <RadioGroup
          className='mt-2 flex w-full space-x-2 sm:max-w-md'
          defaultValue={values.household_type}
          name='household_type'
          onValueChange={value => {
            setFieldValue('household_type', value);
          }}
        >
          <Label
            htmlFor='professional-tenant'
            className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
          >
            <RadioGroupItem
              value='professional-tenant'
              id='professional-tenant'
              className='sr-only'
            />
            Professional
          </Label>
          <Label
            htmlFor='student'
            className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
          >
            <RadioGroupItem value='student' id='student' className='sr-only' />
            Student
          </Label>
        </RadioGroup>
        <ErrorMessage
          component='span'
          className='text-error text-xs'
          name='household_type'
        />
      </div>

      <div className='text-center sm:text-left'>
        <Button
          id='welcome-submit'
          type='submit'
          variant={null}
          className='w-1/2 bg-[#FFD049] text-lg'
          loadingText='Submitting...'
          isLoading={isLoading}
        >
          Let's go
        </Button>
      </div>
    </>
  );
};
