import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

const analytics = Analytics({
  debug: process.env.NODE_ENV !== 'production',
  app: process.env.REACT_APP_ANALYTICS_NAME || 'homebox-staging',
  plugins: [
    googleTagManager({
      containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
      preview: process.env.REACT_APP_GTM_PREVIEW,
      auth: process.env.REACT_APP_GTM_AUTH,
    }),
  ],
});

/* Track a page view */
analytics.page();
