import React from 'react';
import { Skeleton } from 'homebox-ui';

export default function AccountSetupSkeleton() {
  return (
    <div>
      <div className='flex flex-col space-y-6 pt-6'>
        {Array.from({ length: 4 }).map((_, i) => (
          <div key={i} className='w-full sm:max-w-xs'>
            <Skeleton className='mt-2 min-h-[2.5rem] w-full rounded-md' />
          </div>
        ))}

        <Skeleton className='mt-4 min-h-[1rem] w-[9/12] rounded-md sm:max-w-xs' />

        <div className='flex w-full flex-wrap sm:max-w-md'>
          {Array.from({ length: 12 }).map((_, i) => (
            <Skeleton
              key={i}
              className='mr-[10px] mt-2 h-10 w-10 rounded-full p-2'
            />
          ))}
        </div>

        <div className='mt-4 flex w-full flex-wrap sm:max-w-md'>
          {Array.from({ length: 12 }).map((_, i) => (
            <Skeleton
              key={i}
              className='mr-[10px] mt-2 h-10 w-10 rounded-full p-2'
            />
          ))}
        </div>

        <div className='mt-4 flex w-full space-x-2 sm:max-w-md'>
          {Array.from({ length: 2 }).map((_, i) => (
            <Skeleton
              key={i}
              className='flex h-10 w-full flex-col items-center justify-center rounded-[32px] px-2 py-[10px]'
            />
          ))}
        </div>
        <div className='w-full sm:max-w-xs'>
          <Skeleton className='mt-4 min-h-[2.5rem] w-full rounded-md' />
        </div>
      </div>
    </div>
  );
}
