import { ErrorMessage, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import useLoadPartner from '../../hooks/useLoadPartner';
import { Button, Input, Label, RadioGroup, RadioGroupItem } from 'homebox-ui';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import { AGENT_TYPES } from '../../app/config/enums';
import { AlertSuccess } from '../AlertCard';

export const HouseholdForm = () => {
  const businessTypes = ['letting-agent', 'build-to-rent'];

  const { values, setFieldValue, handleChange, handleBlur, isSubmitting } =
    useFormikContext();

  const {
    letting_agent: { type, name, email, phone_number },
    university_name,
    university_team,
  } = values;

  const { partner } = useLoadPartner(true);

  const { updatedHousehold } = useOutletContext();

  useEffect(() => {
    if (businessTypes.includes(partner?.business_type)) {
      setFieldValue('letting_agent', {
        type: 'agent',
        name: partner.name ?? '',
        email: partner.email ?? '',
        phone_number: partner.phone_number ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  if (updatedHousehold) {
    return (
      <AlertSuccess
        title='Thank you for submitting these details!'
        isClosable={false}
      />
    );
  }

  return (
    <div>
      <div className='mt-2 w-full sm:max-w-sm'>
        {values.household_type === 'student' && (
          <>
            <div className='w-full sm:max-w-sm'>
              <Label htmlFor='university_name'>Name of your university</Label>
              <Input
                name='university_name'
                id='university_name'
                onChange={handleChange}
                onBlur={handleBlur}
                value={university_name}
                placeholder='Enter the name of your university'
              />
              <ErrorMessage
                component={'span'}
                className='text-error text-xs'
                name='university_name'
              />
            </div>
            <div className='w-full sm:max-w-sm'>
              <Label htmlFor='university_team'>
                Are you part of a university team/society?
              </Label>
              <Input
                name='university_team'
                id='university_team'
                onChange={handleChange}
                value={university_team}
                placeholder='Enter the name of your team'
              />
              <ErrorMessage
                component={'span'}
                className='text-error text-xs'
                name='university_team'
              />
            </div>
          </>
        )}
        <div className='mt-2 w-full'>
          <Label htmlFor='letting_agent.type'>
            What type of resident are you:
          </Label>
          <RadioGroup
            className='mt-2 flex w-full flex-col space-x-2 sm:max-w-md'
            name='letting_agent.type'
            id='letting_agent.type'
            value={type}
            onValueChange={value => {
              setFieldValue('letting_agent.type', value);
            }}
          >
            {Object.keys(AGENT_TYPES).map(key => (
              <Label
                key={key}
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 cursor-pointer flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem value={key} className='sr-only' />
                {AGENT_TYPES[key]}
              </Label>
            ))}
          </RadioGroup>
          <ErrorMessage
            component={'span'}
            className='text-error text-xs'
            name='letting_agent.type'
          />
        </div>
        {type !== 'other' && (
          <>
            <div className='mt-2 w-full'>
              <Label htmlFor='letting_agent_name'>
                What is the name of the letting agent/landlord:
              </Label>
              <Input
                name='letting_agent.name'
                id='letting_agent.name'
                value={name}
                onChange={handleChange}
                placeholder="Company's Name"
              />
              <ErrorMessage
                component={'span'}
                className='text-error text-xs'
                name='letting_agent.name'
              />
            </div>
            <div className='mt-2 w-full'>
              <Label htmlFor='letting_agent.email'>
                Please provide their contact email address
              </Label>
              <Input
                name='letting_agent.email'
                id='letting_agent.email'
                onChange={handleChange}
                value={email}
                placeholder="Company's email"
              />
              <ErrorMessage
                component={'span'}
                className='text-error text-xs'
                name='letting_agent.email'
              />
            </div>
            <div className='mt-2 w-full'>
              <Label htmlFor='phone_number'>
                Please provide their contact phone number:
              </Label>
              <PhoneInput
                defaultCountry='GB'
                placeholder='e.g. 7700 900123'
                value={phone_number}
                onChange={val => {
                  setFieldValue('letting_agent.phone_number', val);
                }}
                id='phone_number'
              />
              {phone_number && (
                <span className='text-error text-xs'>
                  {phone_number
                    ? isValidPhoneNumber(phone_number)
                      ? undefined
                      : 'Invalid phone number'
                    : 'Phone number required'}
                </span>
              )}
              <ErrorMessage
                component={'span'}
                className='text-error text-xs'
                name='letting_agent.phone_number'
              />
            </div>
          </>
        )}
      </div>
      <Button
        variant='primary'
        size={'cta'}
        type='submit'
        id='submit-billpayers'
        className='mt-4 w-full font-semibold'
        isLoading={isSubmitting}
        disabled={isSubmitting}
      >
        Save
      </Button>
    </div>
  );
};
