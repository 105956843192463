import React from 'react';
import { Navigate } from 'react-router-dom';
import CustomerOnboarding from './pages';
// import GetStarted from './pages/GetStarted';
import AccountSetup from './pages/AccountSetup';
import SetupBill from './pages/SetupBill';
// import EnergyTariff from './pages/EnergyTariff';
import SetupPayment from './pages/SetupPayment';
import BillPayers from './pages/BillPayers';
// import SetupRent from './pages/SetupRent';
import Success from './pages/Success';
import AccountConfirmation from './pages/AccountConfirmation';
import Conflict from './pages/Conflict';
import LeadPage from './pages/lead';
import YourHomeMove from './pages/lead/YourHomeMove';

const routes = (basePath, defaultPartnerNameurl = 'homebox') => {
  return [
    {
      path: '/',
      element: (
        <>
          <Navigate to={`${defaultPartnerNameurl}/${basePath}`} />
        </>
      ),
    },

    {
      path: ':nameUrl',
      element: <CustomerOnboarding />,
      children: [
        {
          path: '',
          // element: <GetStarted />,
          element: (
            <>
              <Navigate to='setup-account' />
            </>
          ),
        },

        {
          path: 'setup-account',
          element: <AccountSetup />,
        },

        {
          path: 'setup-bill',
          element: <SetupBill />,
        },
        // {
        //   path: 'energy-setup',
        //   element: <EnergyTariff />,
        // },
        {
          path: 'account-setup',
          element: <AccountConfirmation />,
        },
        {
          path: 'setup-payment',
          element: <SetupPayment />,
        },
        {
          path: 'bill-payers',
          element: <BillPayers />,
        },
        // {
        //   path: 'setup-rent',
        //   element: <SetupRent />,
        // },
        {
          path: 'success',
          element: <Success />,
        },
      ],
    },

    {
      path: ':nameUrl',
      children: [
        {
          path: 'conflict',
          element: <Conflict />,
        },
        {
          path: 'welcome',
          element: <LeadPage />,
        },
        {
          path: 'your-home-move',
          element: <YourHomeMove />,
        },
      ],
    },
  ];
};

export default routes;
