import { sendAmplitudeData } from './amplitude';
import * as amplitude from '@amplitude/analytics-browser';

export const logger = {};

// https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/migration/#groupidentify
// Returns whether we should log to analytics apps or not.
const loggingActive = () => {
  const Impersonate = localStorage.getItem('Impersonate');
  return process.env.NODE_ENV === 'production' && !Impersonate;
};

// Wait for analytics apps to load asynchronously.
let amplitudeLoadedCounter = 1;
logger.amplitudeLoaded = fn => {
  if (loggingActive()) {
    if (typeof amplitude === 'object') {
      fn();
    } else {
      // Repeat every 200ms for ~1m.
      amplitudeLoadedCounter++;
      if (amplitudeLoadedCounter < 300) {
        setTimeout(() => {
          logger.amplitudeLoaded(fn);
        }, 200);
      }
    }
  } else {
    return false;
  }
};

// Global track
logger.track = (eventType, properties, destinations, project, cb) => {
  if (loggingActive()) {
    // TODO: Implment Segment and conditions to use specific analytic tools.
    // logger.segmentTrack(eventType, properties, destinations);
    if (eventType) {
      // Amplitude directly.
      logger.amplitudeTrack(eventType, properties, project, cb);
    }
  } else {
    const properties2 = properties ? JSON.stringify(properties) : '';
    console.log(`logger.track: ${eventType}, ${properties2}`);
  }
};

// Global identify
logger.identify = (userId, traits) => {
  if (loggingActive()) {
    logger.amplitudeIdentify(traits);
  } else {
    const userId2 = userId ? JSON.stringify(userId) : '';
    console.log(`logger.identify: ${userId2}, ${JSON.stringify(traits)} `);
  }
};

// Tracking for Amplitude only.
logger.amplitudeTrack = (eventType, properties, project = null, cb = false) => {
  if (loggingActive()) {
    logger.amplitudeLoaded(() => {
      sendAmplitudeData(eventType, properties);
    });
  } else {
    const properties2 = properties ? JSON.stringify(properties) : '';
    console.log(`logger.amplitudeTrack: ${eventType}, ${properties2}`);
  }
};

// Note; we don't pass userId through here, instead we let Amplitude
// use it's own internal DeviceID.
// Note: this needs to be refactored!
// We currently rely on the signature trails = [{ name: 'something', value: 'somethingElse'}
// However when calling via segment the signature is [{ something: value}]
logger.amplitudeIdentify = traits => {
  if (loggingActive()) {
    logger.amplitudeLoaded(() => {
      for (const i in traits) {
        let identify;
        if (traits[i].name) {
          identify = new amplitude.Identify().set(
            traits[i].name,
            traits[i].value
          );
        } else {
          identify = new amplitude.Identify().set(i, traits[i]);
        }
        amplitude.identify(identify);
      }
    });
  } else {
    console.log(`logger.amplitudeIdentify: ${JSON.stringify(traits)}`);
  }
};
