import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from 'homebox-ui';
import { cn } from '../../utils';
import GroupPlansCard from '../GroupPlansCard';
import {
  MdTv,
  MdWifi,
  MdCleaningServices,
  MdElectricBolt,
  MdWaterDrop,
} from 'react-icons/md';
import { AlertSuccess } from '../AlertCard';
import { toast } from 'react-hot-toast';
import EnergyPlansCard from '../EnergyPlansCard';
import { useFormikContext } from 'formik';
import { calculateBillPrice } from '../../utils/functions';
import BillCardDescription from '../BillCard/BillCardDescription';

export default function GroupBillCard(props) {
  const {
    data,
    isBillSelected,
    toggleBillSelection,
    getSelectedBillFromGroup,
    icon,
    isMonthly = true,
    isPerk,
    noOfBillPayers,
    noOfHousemates,
  } = props;
  const { values } = useFormikContext();

  // eslint-disable-next-line no-unused-vars
  const { bills, type, description, name } = data;
  const groupId = data?.id;
  const selectedBill = getSelectedBillFromGroup(groupId);

  let [openPlans, setOpenPlans] = useState(false);

  const [billPlanSelected, setBillPlanSelected] = useState(selectedBill);
  const {
    provider,
    logo_path,
    id,
    description: billDescription,
    description_html,
    group_name,
    label: billLabel,
    preferred_bill,
  } = selectedBill;

  const isSelected = isBillSelected(id, isPerk);

  useEffect(() => {
    // check for the auto selected bill for this group and update as billPlanSelected to sync component state to parent
    const autoSelectedBill = getSelectedBillFromGroup(data?.id);
    setBillPlanSelected(autoSelectedBill);
  }, [data?.id, values?.billSelected, getSelectedBillFromGroup]);

  const choosePlan = payload => {
    if (payload?.id === billPlanSelected?.id) {
      if (!isEnergy) {
        toggleBillSelection(billPlanSelected?.id, billPlanSelected);
        setBillPlanSelected({});
      }
    } else {
      // add remove existing selected bill
      toggleBillSelection(billPlanSelected?.id, billPlanSelected);
      // add selected bill
      setBillPlanSelected(payload);
      toggleBillSelection(payload?.id, payload);
      toast.custom(t => (
        <AlertSuccess title={'New Plan added! '} currentToast={t} />
      ));
    }
    setOpenPlans(false);
  };

  const logo = {
    Internet: { icon: true, logo: <MdWifi className='h-7 w-7' /> },
    'TV Package': { icon: true, logo: <MdTv className='h-7 w-7' /> },
    'Renewable Energy': {
      icon: true,
      logo: <MdElectricBolt className='h-7 w-7' />,
    },
    Water: { icon: true, logo: <MdWaterDrop className='h-7 w-7' /> },
    Cleaner: { icon: true, logo: <MdCleaningServices className='h-7 w-7' /> },
  };

  const isWater = name === 'Water';
  const isEnergyProvider = provider === 'Rebel Energy';
  const isCouncilTax = name === 'Council Tax';
  const isTVLicense = name === 'TV Licence';
  const isRenewableEnergy = group_name === 'Renewable Energy';
  const isEnergy = name === 'Renewable Energy';

  const BillCardAction = () => {
    return (
      <>
        <Dialog>
          <DialogTrigger asChild>
            <button
              // variant='link'
              className='text-typography-interactive text-p-sm cursor-pointer py-2 font-semibold  underline-offset-2 hover:underline sm:p-0 sm:text-base'
            >
              Learn more
            </button>
          </DialogTrigger>
          <DialogContent className=''>
            <DialogHeader>
              <DialogTitle className='text-center'>{name}</DialogTitle>
            </DialogHeader>
            <DialogDescription className='max-h-[75vh] overflow-y-scroll py-3'>
              <BillCardDescription
                bill={selectedBill}
                types={{
                  isEnergyProvider,
                  isWater,
                  isCouncilTax,
                  isTVLicense,
                  isEnergy,
                }}
                description={billDescription}
                description_html={description_html}
              />
            </DialogDescription>
            <DialogFooter>
              <DialogTrigger asChild>
                <Button variant={'secondary'} type='button'>
                  Close
                </Button>
              </DialogTrigger>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        {!isPerk && (
          <div className='flex'>
            {isSelected ? (
              <div className='flex space-x-3 transition-all'>
                {!isEnergy && (
                  <>
                    <Button
                      onClick={() => {
                        toggleBillSelection(id, billPlanSelected);
                        setBillPlanSelected({});
                      }}
                      variant='secondary'
                    >
                      Remove
                    </Button>
                  </>
                )}
                <Button onClick={() => setOpenPlans(true)} variant='primary'>
                  Switch Plan
                </Button>
              </div>
            ) : (
              <DialogTrigger asChild>
                <Button
                  onClick={() => {
                    setOpenPlans(true);
                  }}
                  // startIcon={<MdAdd />}
                  variant='primary'
                >
                  Add Bill
                </Button>
              </DialogTrigger>
            )}
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <Dialog open={openPlans} onOpenChange={setOpenPlans}>
        <DialogContent className='h-full max-w-xl overflow-y-scroll bg-transparent p-3 shadow-transparent sm:bottom-auto sm:h-auto sm:bg-white'>
          <div className='rounded-xl bg-white px-2 py-6 sm:px-0 sm:py-0 '>
            <DialogHeader>
              <DialogTitle className='h-10 text-center'>
                {isEnergy ? 'Choose your energy plan' : 'Choose Plan'}
              </DialogTitle>
            </DialogHeader>
            <DialogDescription className='flex h-full flex-col space-y-2 overflow-y-scroll p-2 lg:max-h-[75vh] lg:p-6'>
              {isEnergy ? (
                <>
                  {bills?.length > 0 &&
                    bills?.map(bill => (
                      <EnergyPlansCard
                        key={bill?.id}
                        bill={bill}
                        choosePlan={choosePlan}
                        isBillSelected={isBillSelected}
                        isPerk={isPerk}
                        isMonthly={isMonthly}
                        noOfHousemates={noOfHousemates}
                        noOfBillPayers={noOfBillPayers}
                      />
                    ))}
                </>
              ) : (
                <>
                  {bills?.length > 0 &&
                    bills?.map(bill => (
                      <GroupPlansCard
                        key={bill?.id}
                        bill={bill}
                        choosePlan={choosePlan}
                        isBillSelected={isBillSelected}
                        isPerk={isPerk}
                        isMonthly={isMonthly}
                        noOfBillPayers={noOfBillPayers}
                        noOfHousemates={noOfHousemates}
                      />
                    ))}
                </>
              )}
            </DialogDescription>
          </div>
        </DialogContent>
        <div className='w-full'>
          {isSelected ? (
            <div
              className={cn(
                'border-border bg-background-secondary relative isolate  overflow-hidden rounded-xl border p-4',
                !isSelected && `bg-background-foundation border-border-light`
              )}
            >
              <div className='flex h-full w-full space-x-4'>
                <div className='h-16 w-16'>
                  {icon ? (
                    <div className='border-border-light text-typography-secondary rounded-3xl border p-3 shadow-lg'>
                      {icon}
                    </div>
                  ) : (
                    <img
                      src={logo_path}
                      alt={`${provider} logo`}
                      className='h-full max-h-[64px] w-full cursor-pointer rounded-2xl object-contain shadow-lg'
                    />
                  )}
                </div>

                <div
                  className={cn(
                    'flex w-full flex-col justify-between space-y-4',
                    isPerk && 'space-y-1'
                  )}
                >
                  <div className='flex w-full justify-between'>
                    <div className='flex flex-col'>
                      <h4 className='text-p-strong text-typography'>
                        {billLabel ? billLabel : name}
                      </h4>
                      <p className='text-typography-secondary text-p-tiny sm:text-sm'>
                        {/* Check if group bill is a limited tariff energy bill to show different bill description */}
                        {provider
                          ? `${
                              isRenewableEnergy && preferred_bill
                                ? `${billDescription} provided`
                                : 'Provided'
                            } by ${provider}`
                          : 'Not added'}
                      </p>
                    </div>

                    {!isPerk && (
                      <div className='flex flex-col'>
                        <p className='text-p-sm text-typography font-medium sm:text-sm'>
                          £
                          {calculateBillPrice(
                            selectedBill,
                            noOfHousemates,
                            noOfBillPayers,
                            isMonthly
                          )}
                          <span className='text-typography-secondary text-sm font-normal'>
                            {isMonthly ? '/month' : '/week'}
                          </span>
                        </p>
                        {Number(noOfBillPayers) > 1 && (
                          <p className='text-p-tiny text-typography-secondary font-normal sm:text-sm'>
                            per bill payer
                          </p>
                        )}
                      </div>
                    )}
                  </div>

                  <div
                    className={cn(
                      'hidden items-end justify-between sm:flex',
                      isPerk && 'hidden sm:flex'
                    )}
                  >
                    <BillCardAction />
                  </div>
                </div>
              </div>
              <div
                className={cn('mt-4 flex items-end justify-between sm:hidden')}
              >
                <BillCardAction />
              </div>
            </div>
          ) : (
            <>
              <div
                className={cn(
                  'border-border bg-background-secondary relative isolate  overflow-hidden rounded-xl border p-4',
                  !isSelected && `bg-background-foundation border-border-light`
                )}
              >
                <div className='flex h-full w-full space-x-4'>
                  <div className='h-14 w-14'>
                    {logo?.[name]?.icon ? (
                      <div className='border-border-light text-typography-secondary rounded-[28px] border p-3 shadow-lg'>
                        {logo?.[name]?.logo}
                      </div>
                    ) : (
                      <img
                        src={`${
                          data?.logo_path
                            ? `https://sandbox.billingbetter.co.uk/${data?.logo_path}`
                            : logo?.[name]
                        }`}
                        alt={`${name} logo`}
                        className='h-full  w-full cursor-pointer rounded-2xl object-contain shadow-lg'
                      />
                    )}
                  </div>

                  <div
                    className={cn(
                      'flex w-full flex-col justify-between space-y-4'
                    )}
                  >
                    <div className='flex w-full justify-between'>
                      <div className='flex flex-col'>
                        <h4 className='text-p-strong text-typography'>
                          {name}
                        </h4>
                        <p className='text-typography-secondary text-p-tiny sm:text-sm'>
                          {provider ? `Provided by ${provider}` : 'Not added'}
                        </p>
                      </div>
                    </div>

                    <div
                      className={cn('hidden items-end justify-between sm:flex')}
                    >
                      <BillCardAction />
                    </div>
                  </div>
                </div>
                <div
                  className={cn(
                    'mt-4 flex items-end justify-between sm:hidden'
                  )}
                >
                  <BillCardAction />
                </div>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
}
