import React from 'react';
import { EnergyInfoCard } from '../EnergyPlansCard';

export default function BillCardDescription({
  bill,
  types: { isEnergyProvider, isWater, isCouncilTax, isTVLicense, isEnergy },
  description,
  description_html,
}) {
  return (
    <>
      {isEnergy ? (
        <EnergyInfoCard bill={bill} />
      ) : (
        <>
          {isWater || isEnergyProvider || isCouncilTax || isTVLicense ? (
            <>
              {isEnergyProvider && (
                <div className='text-typography-secondary text-base'>
                  <p>
                    Rebel Energy are Homebox's fixed energy tariff partner. This
                    energy tariff will be fixed for the next 12 months and will
                    only change if your usage is higher or lower than we expect
                    it to be.
                    <span
                      type='button'
                      className='text-typography-interactive ml-2 cursor-pointer underline'
                      onClick={() => {
                        window.open(
                          'https://sandbox.billingbetter.co.uk/popout/rebel',
                          '_blank',
                          'width=500, height=300'
                        );
                      }}
                    >
                      View tariff rates &#38; more info
                    </span>
                  </p>
                </div>
              )}
              {isWater && (
                <div className='text-typography-secondary text-base'>
                  Your water provider is determined based on the location of
                  your new home and there is no way of getting this bill any
                  cheaper for you unfortunately. The price we display for your
                  water bill here is determined based on an estimate using data
                  from the supplier.
                  <br /> <br />
                  Please note that although we try to be as accurate as possible
                  the actual water price may be slightly less or slightly more
                  once you sign up to Homebox.
                </div>
              )}

              {isCouncilTax && (
                <div className='text-typography-secondary text-base'>
                  Unless you’re a student, you’ll need to be paying for your
                  council tax. Once you submit your order, we’ll ensure your
                  council tax bill has been set up.
                  <br /> <br />
                  Please note that the price may change once we set up your
                  council tax bill as the price we have displayed is the Band D
                  price for the local authority your home is in.
                </div>
              )}
              {isTVLicense && (
                <span className='text-typography-secondary text-base'>
                  You need to be covered by a TV Licence to watch or record live
                  television on any channel, or to download or watch any BBC
                  programmes on iPlayer – live, catch up or on demand.
                </span>
              )}
            </>
          ) : (
            <span className='text-typography-secondary text-base'>
              {description}
            </span>
          )}
          <div className='text-typography-secondary mt-2 overflow-x-scroll text-base'>
            <div
              dangerouslySetInnerHTML={{
                __html: description_html,
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
