import { useEffect, useState } from 'react';
var useDebounce = function useDebounce(value, delay) {
  var _useState = useState(value),
    debounceValue = _useState[0],
    setDebounceValue = _useState[1];
  useEffect(function () {
    var handler = setTimeout(function () {
      setDebounceValue(value);
    }, delay);
    return function () {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debounceValue;
};
export default useDebounce;