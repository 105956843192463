import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const dateFromStartDate = (startDate, months) => {
  return new Date(startDate.setMonth(startDate.getMonth() + months));
};

export const isValidDate = dateString =>
  new Date(dateString).toString() !== 'Invalid Date';

export const snakeCaseToReadable = text => {
  if (!text) return '';
  // Replace underscores with spaces
  const convertedText = text.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  const words = convertedText.split(' ');
  const capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words back together
  const readableText = capitalizedWords.join(' ');

  return readableText;
};

async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function minDelay(promise, ms) {
  let [p] = await Promise.all([promise, sleep(ms)]);
  return p;
}

export function selectDefaultBill(bills, noOfHousemates, billVariant) {
  if (bills.length === 0) {
    return null;
  }

  let defaultBill = null;

  if (bills[0].group_name === 'Renewable Energy') {
    defaultBill = bills.find(bill => {
      return bill?.preferred_bill;
    });

    if (!defaultBill) {
      defaultBill = bills?.[0];
    }
  }

  if (bills[0].group_name === 'Internet') {
    defaultBill = bills.find(bill => bill?.preferred_bill);

    if (!defaultBill) {
      defaultBill = bills.reduce((acc, curr) => {
        let price = curr.prices.find(
          price => price.housemates === noOfHousemates
        )?.price_per_housemate;

        if (
          price &&
          price <
            acc.prices.find(price => price.housemates === noOfHousemates)
              .price_per_housemate
        ) {
          acc = curr;
        }

        return acc;
      }, bills[0]);
    }
  }

  return defaultBill ?? bills[0];
}

export function calculateBillPrice(
  bill,
  housemates,
  billPayers,
  isMonthly,
  coupon
) {
  let pricePerHousemate = Number(
    bill.prices?.[Number(housemates) - 1]?.price_per_housemate
  );

  let feePerHousemate = Number(
    bill.prices?.[Number(housemates) - 1]?.fee_per_housemate
  );

  pricePerHousemate = pricePerHousemate
    ? pricePerHousemate
    : Number(bill.prices?.[0]?.price_per_housemate);

  feePerHousemate = feePerHousemate
    ? feePerHousemate
    : Number(bill.prices?.[0]?.fee_per_housemate);
  if (
    bill.name === 'Service Charge' &&
    coupon?.promotion_type === 'management-fee-discount'
  ) {
    // The management fee shouldn't be multiplied by the number of housemates
    const pricePerBillPayer = (pricePerHousemate * housemates) / billPayers;
    const price = calcPrice(pricePerBillPayer, isMonthly);

    const totalFeesPerHousemate = feePerHousemate * housemates;
    const totalFees = calcPrice(totalFeesPerHousemate, isMonthly);
    const totalFeesPerBillPayer = totalFees / billPayers;

    return calculateDiscountedPriceForServiceCharge(
      coupon,
      price,
      totalFeesPerBillPayer
    );
  }

  return calculatePrice(pricePerHousemate, housemates, billPayers, isMonthly);
}

function calcPrice(price, isMonthly) {
  return isMonthly ? price : (price * 12) / 52;
}

export function calculatePrice(price = 0, housemates, billPayers, isMonthly) {
  let billPrice = calcPrice(price, isMonthly);

  return billPrice > 0
    ? ((billPrice * housemates) / billPayers).toFixed(2)
    : billPrice;
}

export const calculateDiscountedPriceForServiceCharge = (
  coupon,
  durationPrice,
  totalFeesPerHousemate = 0
) => {
  // Get homebox service free from the management fee
  const homeboxServiceFee = durationPrice - totalFeesPerHousemate;

  // if there is no homebox service fee, return the duration price
  if (!coupon || homeboxServiceFee <= 0) return Number(durationPrice);

  // find disount on the homebox service fee
  const discountedAmount = Number(
    (Number(homeboxServiceFee) * coupon?.promotion_value) / 100
  );

  // Readd the additional fees after fidning disocunt
  return Number(
    (
      Number(homeboxServiceFee) -
      Number(discountedAmount) +
      totalFeesPerHousemate
    ).toFixed(2)
  );
};
