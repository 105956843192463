import * as React from 'react';
import { cn } from 'src/utils/helpers';
import { Slot } from '@radix-ui/react-slot';
import { VariantProps, cva } from 'class-variance-authority';
import { LuLoader2 } from 'react-icons/lu';

const buttonVariants = cva(
  'inline-flex items-center justify-center text-sm sm:text-base rounded-[32px] font-semibold transition-colors focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 disabled:pointer-events-none disabled:text-typography-inactive',
  {
    variants: {
      variant: {
        primary:
          'bg-button-primary text-white hover:bg-button-primary-hover ring-primary-lighter disabled:bg-button-disabled',
        secondary:
          'bg-button-secondary text-typography-primary ring-primary-lighter hover:bg-button-secondary-hover',
        tertiary:
          'bg-transparent text-primary hover:bg-button-secondary focus-visible:ring-0 focus-visible:ring-offset-0',
        link: 'underline-offset-4 hover:underline text-typography-interactive focus-visible:ring-0 focus-visible:ring-offset-0',
        linkGrey:
          'text-typography-primary underline-offset-4 hover:underline focus-visible:ring-0 focus-visible:ring-offset-0',
      },
      size: {
        default: 'h-10 py-3 px-4 sm:px-6',
        cta: 'h-12 px-6',
        icon: 'h-10 w-10 p-2',
      },
      destructive: {
        true: '',
      },
      isLoading: {
        true: 'disabled:cursor-wait',
      },
    },
    // compound styles are apply when two or more variant conditions are met
    compoundVariants: [
      {
        variant: 'primary',
        destructive: true,
        className:
          'bg-error text-white hover:bg-error-dark ring-error-light disabled:bg-button-disabled',
      },
      {
        variant: 'secondary',
        destructive: true,
        className:
          'bg-white text-error-dark hover:bg-error-light ring-error-light disabled:bg-button-disabled',
      },
      {
        variant: 'tertiary',
        destructive: true,
        className: 'bg-white text-error-dark disabled:bg-white',
      },
      {
        variant: 'link',
        destructive: true,
        className:
          'bg-white text-error-dark disabled:bg-transparent hover:no-underline',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  children?: string | React.ReactElement;
  asChild?: boolean;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  isLoading?: boolean;
  loadingText?: string;
  loadingIcon?: React.ReactElement;
  loadingPlacement?: string | 'start' | 'end';
  disabled?: boolean;
  destructive?: boolean;
  className?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      destructive = false,
      asChild = false,
      children,
      startIcon,
      endIcon,
      loadingIcon = <LuLoader2 />,
      loadingPlacement = 'start',
      isLoading,
      loadingText,
      disabled = false,
      type = 'button',
      ...props
    },
    ref
  ) => {
    const Comp:
      | React.ForwardRefExoticComponent<React.RefAttributes<HTMLElement>>
      | 'button' = asChild ? Slot : 'button';

    if (asChild) {
      <Comp
        className={cn(
          buttonVariants({ variant, size, className, destructive })
        )}
        ref={ref}
        type={type}
        {...props}
      />;
    }
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className, destructive })
        )}
        ref={ref}
        type={type}
        disabled={disabled || isLoading}
        {...props}
      >
        {/* replace startIcon with loading icon */}
        {isLoading && loadingIcon && loadingPlacement === 'start' ? (
          <loadingIcon.type
            className='mr-2 h-5 w-5 animate-spin'
            {...loadingIcon.props}
          />
        ) : (
          <>
            {!asChild && !isLoading && startIcon ? (
              <startIcon.type className='mr-2 h-5 w-5' {...startIcon.props} />
            ) : null}
          </>
        )}
        {/* render button content if no loading text */}
        {isLoading && loadingText ? loadingText : children}

        {isLoading && loadingIcon && loadingPlacement === 'end' ? (
          <loadingIcon.type
            className='ml-2 h-5 w-5 animate-spin'
            {...loadingIcon.props}
          />
        ) : (
          <>
            {!asChild && !isLoading && endIcon ? (
              <endIcon.type className='ml-2 h-5 w-5' {...endIcon.props} />
            ) : null}
          </>
        )}
      </Comp>
    );
  }
);

Button.displayName = 'Button';

export { Button, buttonVariants };
