import * as React from 'react';
import { cn } from 'src/utils/helpers';
import { VariantProps, cva } from 'class-variance-authority';

const inputVariants = cva(
  'flex h-10 w-full rounded-lg border-none bg-neutral-100 px-3 py-2 text-base text-neutral-900 ring-offset-primary-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-400 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-primary-lighter focus-visible:bg-white focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:text-neutral-600',
  {
    variants: {
      error: {
        true: 'text-error-800 focus-visible:text-neutral-900 focus-visible:ring-error-light bg-error-light placeholder:text-error-800 disabled:opacity-60',
      },
      prefixIcon: {
        true: 'pl-9 text-typography-secondary',
      },
      suffixIcon: {
        true: 'pr-9 text-typography-secondary',
      },
    },
    defaultVariants: {
      error: false,
    },
  }
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  className?: string;
  error?: boolean;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, error, type, startIcon, endIcon, ...props }, ref) => {
    let prefixIcon = false;
    let suffixIcon = false;

    if (startIcon) {
      prefixIcon = true;
    }

    if (endIcon) {
      suffixIcon = true;
    }

    return (
      <div className='relative rounded-lg'>
        {startIcon ? (
          <span
            className={`pointer-events-none absolute inset-y-0 left-0 flex h-10 items-center px-2 pl-2`}
          >
            <startIcon.type
              className={cn(
                `fill-typography-secondary h-5 w-5 ${
                  error && 'fill-error-dark'
                }`
              )}
              {...startIcon.props}
            />
          </span>
        ) : null}
        <input
          type={type}
          className={cn(
            inputVariants({ className, error, prefixIcon, suffixIcon })
          )}
          ref={ref}
          {...props}
        />
        {endIcon ? (
          <span
            className={`pointer-events-none absolute inset-y-0 right-0 flex h-10 items-center px-2 py-0 ${
              error && 'fill-error-dark'
            }`}
          >
            <endIcon.type
              className={cn(
                `fill-typography-secondary h-4 w-4 ${
                  error && 'fill-error-dark'
                }`
              )}
              {...endIcon.props}
            />
          </span>
        ) : null}
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
